import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@material-ui/core";

import Theme from "./themes";
import * as serviceWorker from "./serviceWorker";

import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { LocationProvider } from "./context/LocationContext";
import { LanguageProvider } from "./context/LanguageContext";
import { DataProvider } from "./context/DataContext";

import App from "./components/App";

ReactDOM.render(
  <>
    <LanguageProvider>
      <LayoutProvider>
        <UserProvider>
          <LocationProvider>
            <DataProvider>
              <Theme>
                <CssBaseline />
                <App />
              </Theme>
            </DataProvider>
          </LocationProvider>
        </UserProvider>
      </LayoutProvider>
    </LanguageProvider>
  </>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
