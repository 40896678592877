import React, {useEffect, useState} from "react";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";
import * as moment from "moment";

// styles
import useStyles from "./styles";

// components

// customized hook

import strings from "../../strings";
import {
  minSoftBoundAutofix,
  maxSoftBoundAutofix,
  forceCellDecimal,
  addTimeInfo,
} from "../../globalFunctions";
import {useDataState} from "../../context/DataContext";
import {useLocationState} from "../../context/LocationContext";
import {ME238_DEVICES, EVU_DEVICES, DISPLAY_SENSOR_DEVICES} from "../../globals";




export function processRoomGraphData(graphDataByHost, timezone) {
  const dataPerTime = {};
  Object.keys(graphDataByHost).forEach(function (mac) {
    if (graphDataByHost[mac]) {
      addTimeInfo(graphDataByHost[mac], timezone);
      graphDataByHost[mac].forEach(function (dataPoint) {
        let time = moment(dataPoint.time).format('HH:mm:ss');
        if (dataPerTime[time] === undefined) {
          dataPerTime[time] = {time: time, t: dataPoint.t};
        }
        dataPerTime[time]['pressure' + mac] = dataPoint.pressure_diff;
      })
    }
  });

  return Object.keys(dataPerTime).sort().map(time => dataPerTime[time]);
}


export default function RoomPressureGraph({ roomId, smallDots }) {
  // global
  let classes = useStyles();
  let theme = useTheme();

  const dataState = useDataState();
  const locationState = useLocationState();

  const [graphData, setGraphData] = useState([]);
  const [roomHosts, setRoomHosts] = useState([]);

  const validDevices = [...ME238_DEVICES, EVU_DEVICES, ...DISPLAY_SENSOR_DEVICES];

  let referenceLines = [
    [dataState.thresholds.pressureDiffWarn, "yellow"],
    [ dataState.thresholds.pressureDiffDanger, "red"]
  ];

  useEffect(function () {
    let roomHosts_ = Object.values(dataState.hosts)
      .filter((x) => x.site_location_id === roomId)
      .filter((x) => validDevices.indexOf(x.host_type) > -1 && !x.is_ref)
      .filter((x) => !x.is_pos);
    setRoomHosts(roomHosts_)
  }, [roomId]);  // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(function () {
    let macs = roomHosts
      .filter((x) => validDevices.indexOf(x.host_type) > -1 && !x.is_ref)
      .map((x) => x.mac_id);
    let graphDataByHost = {};
    macs.forEach((mac) => {
      graphDataByHost[mac] = dataState.meGraphByHost[mac] || [];
    })
    graphDataByHost = processRoomGraphData(graphDataByHost, locationState.timezone);
    setGraphData(graphDataByHost);
  }, [roomHosts, dataState.meGraphByHost]);  // eslint-disable-line react-hooks/exhaustive-deps

  // graph threshold line colors
  const thresholdColors = theme.graphs;

  // Custom toolip on Graph
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0]) {
      return (
        <div className={classes.customToolTip}>
          <p className="label">{`${label}`}</p>
          {
            payload.map(e => (
              <p
                key={`tooltip-${e.name}`}
                className="label"
                style={{ color: e.stroke}}>
                {`${e.name} : ${forceCellDecimal(e.value)}`}
              </p>
            ))
          }
        </div>
      );
    }
    return null;
  };


  // Individual Widgets
  return (
    <ResponsiveContainer
      width="100%"
      height={650}
    >
      <LineChart
        width={500}
        height={650}
        data={graphData}
      >
        <CartesianGrid strokeDasharray="3 3 3 3" />
        <XAxis dataKey="time" />
        <YAxis
          yAxisId="left"
          unit=" Pa"
          domain={[
            minVal => minSoftBoundAutofix(minVal, -10, 10),
            maxVal => maxSoftBoundAutofix(maxVal, 2, 2),
          ]}
          tickFormatter={(tick) => tick}
        />
        <Tooltip content={<CustomTooltip />} key="roomGraphCustomTooltip"/> :
        <Legend />
        {referenceLines.map((referenceLine, idx) => (
          <ReferenceLine
            key={"pressure-ddiff-ref-" + idx}
            yAxisId={"left"}
            y={referenceLine[0]}
            stroke={referenceLine[1]}
            strokeWidth={2}
            label={{
              position: 'insideBottomRight',
              value: referenceLine[0],
              fill: referenceLine[1],
              fontSize: 14
            }}
          />
        ))
        }
        {roomHosts ?
          roomHosts.map((roomHost, hostIndex) => (
            <Line
              key={"pressure" + roomHost.mac_id}
              yAxisId="left"
              type="monotone"
              dataKey={"pressure" + roomHost.mac_id}
              name={strings.pressureDiff + " - " + roomHost.display_name}
              connectNulls={true}
              stroke={thresholdColors.pressureList[hostIndex % thresholdColors.pressureList.length]}
              activeDot={{ r: 8 }}
              dot={{r: smallDots ? 1 : 3}}
            />
          ))
          :
          undefined
        }
      </LineChart>
    </ResponsiveContainer>
  );
}
