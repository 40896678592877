import defaultTheme from "./default";
import darkTheme from "./dark";
import { ThemeProvider } from "@material-ui/styles";

import { createMuiTheme } from "@material-ui/core";
import {useLocationState} from "../context/LocationContext";
import React from "react";


const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
};

function getDarkTheme() {
  return createMuiTheme({...darkTheme, ...overrides})
}

function getDefaultTheme() {
  return createMuiTheme({...defaultTheme, ...overrides})
}


export default function Theme(props) {

  const locationState = useLocationState();

  return (
      <ThemeProvider theme={locationState.theme ? getDarkTheme() : getDefaultTheme()}>
        {props.children}
      </ThemeProvider>
  )
}
