import React, {useEffect, useState} from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";

// context
import strings from "../../strings";
import {useUserDispatch, loginUser, autoSignIn, useUserState} from "../../context/UserContext";
import LanguageSelect from "../../components/LanguageSelect";
import {useLanguageState} from "../../context/LanguageContext";
import AppSelect from "./components/AppSelect";
import {useInterval} from "../../globalFunctions";

function Login(props) {
  let classes = useStyles();

  // global
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const languageState = useLanguageState();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  useInterval(() => {
    if (userState.serviceAccount) {
      let {username, password} = userState.serviceAccount;
      loginUser(userDispatch, username, password, props.history, setIsLoading, setError);
    }
  }, 5000);

  useEffect(() => {
    if (!userState.serviceAccount) {
      autoSignIn(userDispatch, props.history, setIsLoading, setError).then(undefined);
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div key={languageState}>
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <img src={logo} alt="logo" className={classes.logotypeImage} />
          <Typography className={classes.logotypeText}>
            {strings.cirrusDashboard}
          </Typography>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <div className={classes.formDividerContainer}>
              <div style={{width: '50%'}}>
                <LanguageSelect/>
              </div>
            </div>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
            </div>
            <Typography variant="h1" className={classes.greeting}>
              {strings.login}
            </Typography>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
            </div>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {strings.loginFailedMsg}
              </Typography>
            </Fade>
            <AppSelect/>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={e => setLoginValue(e.target.value)}
              margin="normal"
              placeholder={strings.username}
              type="text"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder={strings.password}
              type="password"
              fullWidth

            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  // disabled={
                  //   loginValue.length === 0 || passwordValue.length === 0
                  // }
                  onClick={() =>
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError,
                    )
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
            </div>
          </div>
          <Typography color="primary" className={classes.copyright}>
            © 2019 Cirrus Medical
          </Typography>
        </div>
      </Grid>
    </div>
  );
}

export default withRouter(Login);
