import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";

// styles
import useStyles from "../styles";

// components
import Widget from "../../../components/Widget";
import Table from "../../../components/Table";
import { intervalFilterDict } from "../../../components/Table";

import strings from "../../../strings";
import {
  addTimeInfo,
  forceCellDecimal,
  convertTime,
  hasPressure,
  getLastSeenColor,
  getWorseColor,
  getPressureDiffColor,
  getCo2Color,
  getDeviceTimeout,
} from "../../../globalFunctions";

import { useLocationState } from "../../../context/LocationContext";
import { useDataState} from "../../../context/DataContext";
import {DANGER_COLOR, EVU, GOOD_COLOR, ME238E} from "../../../globals";


// Graph Windows tabs


function LatestDataTable({setModalInfo, setRequestPending, fetchGraphData}) {

  // global
  let classes = useStyles();

  const dataState = useDataState();
  const locationState = useLocationState();

  // static values
  const roomSensorDataKeys = [
    {name: 'site_location_name', label: strings.room,
      options: {display: false}},
    {name: 'display_name', label: strings.location},
    {name: 'time', label: strings.time},
    {name: 'temperature', label: strings.temperatureUnit,
      options: {...intervalFilterDict(strings.temperatureUnit), customBodyRender: forceCellDecimal}},
    {name: 'humidity', label: strings.humidityUnit,
      options: {display: false, customBodyRender: forceCellDecimal}},
    {name: 'pressure_diff', label: strings.pressureDiffUnit,
      options: {customBodyRender: forceCellDecimal}},
    {name: 'pressure', label: strings.pressureUnit,
      options: {display: false, customBodyRender: forceCellDecimal}},
    {name: 'ref_pressure', label: strings.refPressureUnit,
      options: {display: false, customBodyRender: forceCellDecimal}},
    {name: 'co2', label: strings.co2,
      options: {customBodyRender: forceCellDecimal}},
  ];

  const openModal = function (measure, mac) {
    setModalInfo({
      shown: true,
      mac: mac,
      measure: measure,
    });
    fetchGraphData(mac).then(setRequestPending(false));
  };

  // state hooks
  const [roomSensorData, setRoomSensorData] = useState([]);

  // Some switch settings

  const addDataStatus = function (data) {

    data.forEach(function(datum) {
      let hostData = dataState.hosts[datum.mac_id] || {};

      let pressureFlag = hasPressure(dataState.hosts, datum.mac_id);
      let hostType = hostData.host_type;

      // General check
      let lastSeenColor = getLastSeenColor(datum.t, getDeviceTimeout(dataState, datum.mac_id));

      let pressureColor = GOOD_COLOR;
      let posPressureFlag = (dataState.hosts[datum.mac_id] || {}).is_pos;
      let colorFactor = posPressureFlag ? -1 : 1;
      if (pressureFlag) {
        // Pressure check
        if (!hostData.is_ref) {
          if (datum.pressure_diff !== undefined) {
            pressureColor = getPressureDiffColor(colorFactor * datum.pressure_diff, dataState.thresholds);
          } else {
            pressureColor = DANGER_COLOR;
          }
        }
      }

      // DANGER already set, no need to look further
      // Check CO2
      let co2Color = GOOD_COLOR
      if ([ME238E, EVU].indexOf(hostType) > -1) {
        if (datum.co2 !== undefined) {
          co2Color = getCo2Color(datum.co2, dataState.thresholds);
        } else {
          co2Color = DANGER_COLOR;
        }
      }

      datum.color = getWorseColor([lastSeenColor, pressureColor, co2Color]);

    });
  }

  const addMetadata = (latestData) => {
    latestData.forEach((latestDatum) => {
      latestDatum.time = convertTime(latestDatum.t, locationState.timezone)
      let mac = latestDatum.mac_id
      let siteId = latestDatum.site_id;
      let locationId = latestDatum.site_location_id;
      latestDatum.display_name = (dataState.hosts[mac] || {}).display_name;
      latestDatum.site_name = (dataState.sites[siteId] || {}).site_name;
      latestDatum.site_location_name = (dataState.siteLocations[locationId] || {}).location_name;
    })
    return latestData;
  };


  const sortLatestData = (data) => {
    data.sort(function (a, b) {
      if (a.order !== undefined && b.order !== undefined) {
        return a.order - b.order;
      }
      if (a.order !== undefined) {
        return 1;
      }
      if (b.order !== undefined) {
        return -1;
      }

      if (a.site_location_id !== b.site_location_id) {
        return a.site_location_id > b.site_location_id;
      }
      if (a.display_name > b.display_name) {
        return 1;
      }
      if (a.display_name < b.display_name) {
        return -1;
      }
      return 0;
    })
    return data;
  };

  useEffect(() => {
    let latestByHost = {...dataState.meLatestByHost};
    let hosts = dataState.hosts;
    let macs = Object
      .keys(latestByHost)
      .filter((mac) => !mac.startsWith('__'))
      .filter((mac) => (hosts[mac] || {}).site_location_id !== undefined || (hosts[mac] || {}).site_location_id !== null)
      .filter((mac) => !(hosts[mac] || {}).is_ref);
    let latestData = macs.map((key) => latestByHost[key]);

    addMetadata(latestData);
    addTimeInfo(latestData, locationState.timezone);
    addDataStatus(latestData);
    sortLatestData(latestData);
    setRoomSensorData(latestData);
  }, [dataState.hosts, dataState.sites, dataState.siteLocations, dataState.meLatestByHost]);  // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Grid item md={12} xs={12}>
      <Widget
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
      >
        <Table
          data={roomSensorData}
          handler={(rowData) => openModal('pressureDiff', rowData.mac_id)}
          keys={roomSensorDataKeys}
          key="roomSensorData"
        />
      </Widget>
    </Grid>
  );
}

export default LatestDataTable;