import React, {useState} from "react";
import {
  FormGroup,
  TextField,
  InputLabel, Button,
} from "@material-ui/core";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";

// styles

// components
import SiteSettingsMenu from "./SiteSettingsMenu";
import SecurityLevelMenu from "./securityLevelMenu";
import strings from "../../../strings";
import useStyles from "../../users/styles";
import {mgmtUrl} from "../../../meta";
import {useLocationState} from "../../../context/LocationContext";


export default function AddUserModal({ onAddUser, siteId, availableSites }) {

  // global
  let classes = useStyles();

  const locationState = useLocationState();

  const [addDisabled, setAddDisabled] = useState(false);

  const [newUserUsername, setNewUserUsername] = useState("");
  const [newSecurityLevel, setNewSecurityLevel] = useState(50);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPermissions, setNewUserPermissions] = useState(availableSites.map(availableSite => {
      return {
        site_id: availableSite.site_id,
        site_name: availableSite.site_name,
        permission: (siteId === availableSite.site_id ? 50 : 0),
        alert_flag: false,
      };
  }));

  const sendAddUser = async() => {
    const permissions = {};
    newUserPermissions.forEach(newUserPermission => {
      permissions[newUserPermission.site_id] = [
        newUserPermission.permission,
        newUserPermission.alert_flag
      ]
    });
    let postData = {
      site_id: siteId,
      username: newUserUsername,
      email: newUserEmail,
      name: newUserName,
      security_level: newSecurityLevel,
      permissions: permissions,
    };
    setAddDisabled(true);
    axios.post(mgmtUrl + "add_site_user", postData)
      .then((response) => {
        if (onAddUser) {
          onAddUser(response);
        }
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        }
      })
      .finally(() => setAddDisabled(false));
  };

  return (
    <>
      <Modal.Header
        className={classes.modalHeader}
        closeButton
      >
        <Modal.Title>
          {strings.newUserSettings}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={classes.modalBody}
      >
        <form onSubmit={undefined}>
          <FormGroup>
            <InputLabel>
              {strings.username}
            </InputLabel>
            <TextField
              style={{ margin: 8, marginTop: 0 }}
              placeholder={"johnSMITH123"}
              helperText="Must be between 3-16 alphanumerical characters"
              fullWidth
              margin="normal"
              value={newUserUsername}
              onChange={(event) => {
                setNewUserUsername(event.target.value)
              }}
            />
          </FormGroup>
          <div className={classes.formDividerContainer} />
          <FormGroup>
            <InputLabel>
              {strings.email}
            </InputLabel>
            <TextField
              style={{ margin: 8, marginTop: 0 }}
              placeholder={"test@example.com"}
              fullWidth
              margin="normal"
              value={newUserEmail}
              onChange={(event) => {
                setNewUserEmail(event.target.value)
              }}
            />
          </FormGroup>
          <div className={classes.formDividerContainer} />
          <FormGroup>
            <InputLabel>
              {strings.name}
            </InputLabel>
            <TextField
              style={{ margin: 8, marginTop: 0 }}
              placeholder={"John Smith"}
              fullWidth
              margin="normal"
              value={newUserName}
              onChange={(event) => {
                setNewUserName(event.target.value)
              }}
            />
          </FormGroup>
          <div className={classes.formDividerContainer} />
          <SecurityLevelMenu
            adminSecurityLevel={locationState.userInfo.securityLevel}
            modSecurityLevel={newSecurityLevel}
            setModSecurityLevel={setNewSecurityLevel}
          />
          <div className={classes.formDividerContainer} />
          <SiteSettingsMenu
            userPermissions={newUserPermissions}
            setUserPermissions={setNewUserPermissions}
          />
        </form>
      </Modal.Body>
      <Modal.Header
        className={classes.modalHeader}
      >
        <Modal.Title>
        </Modal.Title>
        <div
          style={{float: 'right'}}
        >
          <Button
            color="primary"
            size="small"
            className="px-2 mr-2"
            variant="contained"
            onClick={sendAddUser}
            disabled={addDisabled}
          >
            {strings.add}
          </Button>
        </div>
      </Modal.Header>
    </>
  );
}
