import React from "react";
import {Select} from "@material-ui/core";


export default function SelectDropdown({ children,  ...rest}) {

  return (
    <>
      <Select
        {...rest}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
      >
        { children }
      </Select>
    </>
  );
}
