import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";

// styles

// components
import DataStateRefresh, {fetchMeGraphData} from "../../components/DataStateRefresh";
import PressureDiffGraph from "../../components/PressureDiffGraph/PressureDiffGraph";
import PageTitle from "../../components/PageTitle";
import ScreenRotation from "../../components/ScreenRotation";

// customized hook

import {deviceSort, useInterval} from "../../globalFunctions";
import { useDataDispatch, useDataState
} from "../../context/DataContext";
import {useLocationState} from "../../context/LocationContext";
import strings from "../../strings";
import {
  DISPLAY_DEVICES,
  EVU_DEVICES,
  ME238_DEVICES,
  TENT_DEVICES,
  AMS_DEVICES
} from "../../globals";


function PressureDataHistory() {
  // global

  const refreshInterval = 10000;
  const validDevices = [
    ...ME238_DEVICES,
    ...EVU_DEVICES,
    ...TENT_DEVICES,
    ...DISPLAY_DEVICES
  ];
  const [meRequestPending, setMeRequestPending] = useState(false);
  const [shownMacs, setShownMacs] = useState([]);

  // static values
  const locationState = useLocationState();

  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  // state hooks

  // state hooks
  // Interval hook

  const meFetch = function () {
    if (meRequestPending) {
      return;
    }
    setMeRequestPending(true);
    fetchMeGraphData(dataState, dataDispatch, locationState)
      .finally(() => setMeRequestPending(false));
  };

  const filterShownMacs = function () {
    let hosts = dataState.hosts;
    let macs = Object.keys(hosts)
      .filter((mac) => !mac.startsWith('__'))
      .filter((mac) => validDevices.indexOf(hosts[mac].host_type) > -1);

    let locatedMacs = macs
      .filter((mac) => hosts[mac].site_location_id !== undefined || hosts[mac].site_location_id !== null);

    let locationWithRef = locatedMacs
      .filter((mac) => hosts[mac].is_ref)
      .map((mac) => hosts[mac].site_location_id);

    let amsDevicesMacs = locatedMacs
      .filter((mac) => AMS_DEVICES.indexOf(hosts[mac].host_type) > -1);

    if (locationWithRef.length === 0 && amsDevicesMacs.length === 0) {
      setShownMacs([]);
      return
    }

    let relevantMacs = locatedMacs
      .filter((mac) => !hosts[mac].is_ref)
      .filter((mac) => locationWithRef.indexOf(hosts[mac].site_location_id) > -1 || amsDevicesMacs.indexOf(mac) > -1);

    relevantMacs = deviceSort(relevantMacs, dataState);
    setShownMacs(relevantMacs);
  }

  useInterval(meFetch, refreshInterval);

  useEffect(meFetch, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(filterShownMacs, [dataState.hosts])  // eslint-disable-line react-hooks/exhaustive-deps


  const dataPanel = function(mac) {
    let mdSize = shownMacs.length === 1 ? 12 : 6;
    let hostInfo = (dataState.hosts[mac] || {});
    return (
      <Grid item md={mdSize} xs={12}>
        <Grid justify={"center"} container spacing={1}>
          <Typography variant={"h5"}>
            {hostInfo.display_name}
          </Typography>
        </Grid>
        <PressureDiffGraph
          mac={mac}
          is_positive={hostInfo.is_pos}
          height={350}
          withLegend={false}
          withTemperature={false}
        />
      </Grid>
      );
  }


  return (
    <>
      <ScreenRotation/>
      <DataStateRefresh
        hostRefresh={true}
        siteRefresh={true}
        siteLocationRefresh={true}
        thresholdsRefresh={true}
      />

      <PageTitle title={strings.pressureDataHistory} />

      <Grid container spacing={1}>
        {
          shownMacs.map(dataPanel)
        }
      </Grid>

    </>
  );
}

export default PressureDataHistory;