import React, {useEffect, useState} from "react";
import {
  FormGroup,
  TextField,
  InputLabel,
  Button,
} from "@material-ui/core";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";

// styles

// components
import SiteSettingsMenu from "./SiteSettingsMenu";
import SecurityLevelMenu from "./securityLevelMenu";
import strings from "../../../strings";
import useStyles from "../../users/styles";
import {mgmtUrl} from "../../../meta";
import {useLocationState} from "../../../context/LocationContext";


export default function ModifyUserModal({ userRow, onModifyUser, onPasswordReset, siteId,
                                          availableSites }) {

  // global
  let classes = useStyles();

  const locationState = useLocationState();

  const [modifyDisabled, setModifyDisabled] = useState(false);

  const [modUserName, setModUserName] = useState(userRow.name);
  const [modSecurityLevel, setModSecurityLevel] = useState(userRow.security_level);
  const [modUserEmail, setModUserEmail] = useState(userRow.email);
  const [modUserPermissions, setModUserPermissions] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async() => {
      const {data} = await axios(`${mgmtUrl}users/permissions?user=${userRow.username}`);
      setModUserPermissions(availableSites.map(availableSite => {
        return {
          site_id: availableSite.site_id,
          site_name: availableSite.site_name,
          permission: data[availableSite.site_id] ? data[availableSite.site_id][0] : 0,
          alert_flag: data[availableSite.site_id] ? data[availableSite.site_id][1] : false,
        };
      }));
    };
    fetchUserPermissions().then(undefined);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps


  const sendModifyUser = async() => {
    const permissions = {};
    modUserPermissions.forEach(modUserPermission => {
      permissions[modUserPermission.site_id] = [
        modUserPermission.permission,
        modUserPermission.alert_flag
      ]
    });
    let postData = {
      site_id: siteId,
      username: userRow.username,
      security_level: modSecurityLevel,
      email: modUserEmail,
      name: modUserName,
      permissions: permissions,
    };
    setModifyDisabled(true);
    axios.post(mgmtUrl + "modify_site_user", postData)
      .then((response) => {
        if (onModifyUser) {
          onModifyUser(response);
        }
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        }
      })
      .finally(() => setModifyDisabled(false));
  };

  const sendPasswordReset = async() => {
    let postData = {
      username: userRow.username,
    };
    setModifyDisabled(true);
    axios.post(mgmtUrl + "reset_password", postData)
      .then((response) => {
        if (onModifyUser) {
          onPasswordReset(response);
        }
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        }
      })
      .finally(() => setModifyDisabled(false));
  };

  return (
    <>
      <Modal.Header
        className={classes.modalHeader}
        closeButton
      >
        <Modal.Title>
          {strings.modUserSettings}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={classes.modalBody}
      >
        <form onSubmit={undefined}>
          <FormGroup>
            <InputLabel>
              {strings.username}
            </InputLabel>
            <TextField
              style={{ margin: 8, marginTop: 0 }}
              placeholder={"johnSMITH123"}
              helperText="Must be between 3-16 alphanumerical characters"
              fullWidth
              margin="normal"
              value={userRow.username}
              disabled={true}
            />
          </FormGroup>
          <div className={classes.formDividerContainer} />
          <FormGroup>
            <InputLabel>
              {strings.email}
            </InputLabel>
            <TextField
              style={{ margin: 8, marginTop: 0 }}
              placeholder={"test@example.com"}
              fullWidth
              margin="normal"
              value={modUserEmail}
              onChange={(event) => {
                setModUserEmail(event.target.value)
              }}
            />
          </FormGroup>
          <div className={classes.formDividerContainer} />
          <FormGroup>
            <InputLabel>
              {strings.name}
            </InputLabel>
            <TextField
              style={{ margin: 8, marginTop: 0 }}
              placeholder={"John Smith"}
              fullWidth
              margin="normal"
              value={modUserName}
              onChange={(event) => {
                setModUserName(event.target.value)
              }}
            />
          </FormGroup>
          <div className={classes.formDividerContainer} />
          <SecurityLevelMenu
            adminSecurityLevel={locationState.userInfo.securityLevel}
            modSecurityLevel={modSecurityLevel}
            setModSecurityLevel={setModSecurityLevel}
            disabled={locationState.userInfo.username === userRow.username}
          />
          <div className={classes.formDividerContainer} />
          <SiteSettingsMenu
            userPermissions={modUserPermissions}
            setUserPermissions={setModUserPermissions}
          />
        </form>
      </Modal.Body>
      <Modal.Header
        className={classes.modalHeader}
      >
        <Modal.Title>
        </Modal.Title>
        <div
          style={{float: 'right'}}
        >
          <Button
            color="primary"
            size="small"
            className="px-2 mr-2"
            variant="contained"
            onClick={sendPasswordReset}
            disabled={modifyDisabled}
          >
            {strings.passwordReset}
          </Button>
          <Button
            color="primary"
            size="small"
            className="px-2 mr-2"
            variant="contained"
            onClick={sendModifyUser}
            disabled={modifyDisabled}
          >
            {strings.confirm}
          </Button>
        </div>
      </Modal.Header>
    </>
  );
}
