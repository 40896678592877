import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  LocationOn as LocationOnIcon,
} from "@material-ui/icons";

import classNames from "classnames";
import axios from "axios";

// styles
import useStyles from "./styles";


// components
import { Typography } from "../Wrappers";
import {airUrl, iotUrl, authUrl} from "../../meta";
import {appVersion} from "../../version";


// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import {
  useUserDispatch,
  signOut,
  useUserState} from "../../context/UserContext";
import {
  useLocationState,
  useLocationDispatch,
  updateCurrentLocation,
  updateUserInfo,
} from "../../context/LocationContext";
import {
  useDataDispatch,
  resetContext,
} from "../../context/DataContext";

import strings from "../../strings";
import {routes} from "../../routes";


function Header(props) {

  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  const userState = useUserState();
  const userDispatch = useUserDispatch();

  const locationState = useLocationState();
  const locationDispatch = useLocationDispatch();

  const dataDispatch = useDataDispatch();

  // local
  const [profileMenu, setProfileMenu] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [availableLocations, setAvailableLocations] = React.useState([]);

  const handleLocationSelect = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleLocationDropdownClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchAvailableLocations = async() => {
      axios.get(userState.cirrusApp === 'air' ?
        `${airUrl}sites/info` :
        `${iotUrl}sites/info`
      ).then((response) => {
        setAvailableLocations([{
          id: null,
          site_name: 'All',
        }, ...response.data]);
      });
    };

    const fetchUserInfo = async() => {
      axios.get(`${authUrl}user/info`
      ).then((response) => {
        updateUserInfo(locationDispatch, response.data);
      });
    };

    fetchAvailableLocations().then(undefined);
    fetchUserInfo().then(undefined);
  }, [userDispatch, userState.cirrusApp, props.history]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h5" weight="medium" className={classes.logotype}>
          { userState.cirrusApp === 'air' ? "Cirrus Air" : "Cirrus Medical IoT" }
        </Typography>
        <Typography className={classes.typo} variant="subtitle2" size="sm" >
          v{appVersion}
        </Typography>
        <div className={classes.grow} />
        <Button
          aria-controls="location-menu"
          aria-haspopup="true"
          onClick={handleLocationSelect}
          className={classes.headerMenuText}
        >
          <LocationOnIcon />
          {locationState.currentLocation.name}
        </Button>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="location-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleLocationDropdownClose}
        >
          {
            availableLocations.map((location, idx) => (
              <MenuItem
                key={'header-location-select-' + idx}
                onClick={() => {
                  updateCurrentLocation(locationDispatch, location);
                  setAnchorEl(null);
                }}
              >
                { location.site_name }
              </MenuItem>
            ))
          }
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {locationState.userInfo.name}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() => props.history.push(routes.profile)}
          >
            <AccountIcon className={classes.profileMenuIcon} /> {strings.profile}
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(
                userDispatch,
                props.history,
                () => resetContext(dataDispatch))
              }
            >
              {strings.signOut}
            </Typography>
          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
              href="https://www.cirrusmedical.net"
            >
              cirrusmedical.net
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Header);
