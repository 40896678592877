import React from "react";

const LATEST_DATA_LAYOUT_PANEL = "PANEL";
const LATEST_DATA_LAYOUT_TABLE = "TABLE";


const LocationStateContext = React.createContext();
const LocationDispatchContext = React.createContext();

function locationReducer(state, action) {
  switch (action.type) {
    case "UPDATE_CURRENT_LOCATION":
      return { ...state, currentLocation: action.location };
    case "UPDATE_REFRESHER":
      return { ...state, refresher: action.limit };
    case "TOGGLE_CALIBRATION_SHOWN":
      return { ...state, calibrationShown: action.calibrationShown};
    case "TOGGLE_VENDOR_SETTING":
      return { ...state, isVendor: !state.isVendor};
    case "TIME_ZONE_SETTING":
      return { ...state, timezone: action.timezone};
    case "THEME_SETTING":
      return { ...state, theme: action.theme};
    case "UPDATE_USER_INFO":
      return { ...state, userInfo: action.userInfo};
    case "UPDATE_LATEST_DATA_LAYOUT":
      return { ...state, latestDataLayout: action.latestDataLayout};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LocationProvider({ children }) {
  const savedLocation = localStorage.getItem("location");
  const savedRefresher = localStorage.getItem("refresher");
  const savedTimezone = localStorage.getItem("timezone");
  const savedCalibrationShown = localStorage.getItem("calibrationShown");
  const savedTheme = (localStorage.getItem("theme") === "true");
  const savedLatestDataLayout = localStorage.getItem("latestDataLayout");
  // const savedUserInfo = localStorage.getItem("userInfo");

  const [state, dispatch] = React.useReducer(locationReducer, {
    currentLocation: (savedLocation ? JSON.parse(savedLocation) : {
      id: null,
      name: 'All',
    }),
    //
    refresher: (savedRefresher ? parseInt(savedRefresher) : 5),
    //
    isVendor: false,
    //
    timezone: (savedTimezone ? savedTimezone : 'Asia/Tokyo'),
    //
    theme: (savedTheme ? savedTheme : true),
    //
    calibrationShown: !!savedCalibrationShown,
    //
    userInfo: {
      username: undefined,
      email: undefined,
      name: undefined,
      securityLevel: 50,
    },
    //
    latestDataLayout: savedLatestDataLayout ? savedLatestDataLayout : LATEST_DATA_LAYOUT_PANEL,
  });

  return (
    <LocationStateContext.Provider value={state}>
      <LocationDispatchContext.Provider value={dispatch}>
        {children}
      </LocationDispatchContext.Provider>
    </LocationStateContext.Provider>
  );
}

function useLocationState() {
  const context = React.useContext(LocationStateContext);
  if (context === undefined) {
    throw new Error("useLocationState must be used within a LocationProvider");
  }
  return context;
}

function useLocationDispatch() {
  const context = React.useContext(LocationDispatchContext);
  if (context === undefined) {
    throw new Error("useLocationDispatch must be used within a LocationProvider");
  }
  return context;
}

export {
  LocationProvider,
  useLocationState,
  useLocationDispatch,
  updateCurrentLocation,
  updateRefresher,
  toggleCalibrationShown,
  toggleVendorSetting,
  updateTimezone,
  updateTheme,
  updateUserInfo,
  updateLatestDataLayout,
  LATEST_DATA_LAYOUT_PANEL,
  LATEST_DATA_LAYOUT_TABLE
};

// ###########################################################
function updateCurrentLocation(dispatch, location) {
  localStorage.setItem("location", JSON.stringify(location));

  dispatch({
    type: "UPDATE_CURRENT_LOCATION",
    location
  });
}

function updateRefresher(dispatch, limit) {
  localStorage.setItem("refresher", limit);

  dispatch({
    type: "UPDATE_REFRESHER",
    limit
  });
}

function toggleCalibrationShown(dispatch, calibrationShown) {
  if (calibrationShown) {
    localStorage.setItem("calibrationShown", "1");
  } else {
    localStorage.removeItem("calibrationShown");
  }
  dispatch({
    type: "TOGGLE_CALIBRATION_SHOWN",
    calibrationShown
  });
}
function toggleVendorSetting(dispatch) {
  dispatch({
    type: "TOGGLE_VENDOR_SETTING"
  });
}

function updateTimezone(dispatch, timezone) {
  localStorage.setItem("timezone", timezone);

  dispatch({
    type: "TIME_ZONE_SETTING",
    timezone
  });
}

function updateTheme(dispatch, theme) {
  localStorage.setItem("theme", theme);

  dispatch({
    type: "THEME_SETTING",
    theme
  });
}

function updateUserInfo(dispatch, userInfo) {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));

  dispatch({
    type: "UPDATE_USER_INFO",
    userInfo
  });
}

function updateLatestDataLayout(dispatch, latestDataLayout) {
  localStorage.setItem("latestDataLayout", latestDataLayout);

  dispatch({
    type: "UPDATE_LATEST_DATA_LAYOUT",
    latestDataLayout
  });
}

