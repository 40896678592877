import React, {useEffect, useState} from "react";
import axios from "axios";

// styles
import ScreenRotation from "../../components/ScreenRotation";
import DataStateRefresh from "../../components/DataStateRefresh";

import {useDataState, updateUserFiles, useDataDispatch} from "../../context/DataContext";

import strings from "../../strings";
import {filesUrl} from "../../meta";


export default function CustomFileRotate() {

  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  // static values
  const [shownFileId, setShownFileId] = useState('');
  const [tmpUrl, setTmpUrl] = useState('');
  const [fileType, setFileType] = useState('image');

  const getFileData = async function () {
    if (shownFileId === '' || dataState.userFiles.blacklist.indexOf(shownFileId) > -1) {
      return
    }
    const url = filesUrl + "file?id=" + shownFileId;
    try {
      const result = await axios(url);
      let prefix = (dataState.userFiles.data.filter((x) => x.file_id===shownFileId)[0] || []).mime || "image/png";
      if (prefix.startsWith('image')) {
        setFileType('image');
        setTmpUrl('data:' + prefix + ';base64,' + (result.data));
      } else if (prefix.startsWith('video')) {
        setFileType('video');
        setTmpUrl('data:' + prefix + ';base64,' + (result.data));
      } else {
        setFileType('image');
        setTmpUrl('');
      }
    } catch (err) {
      if (err.response.status === 404) {
        let newUserFiles = {...dataState.userFiles};
        newUserFiles.blacklist.push(shownFileId);
        updateUserFiles(dataDispatch, newUserFiles);
      }
      onRotate();
    }
  }

  useEffect(() => {
    getFileData().then(undefined);
  }, [shownFileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataState.userFiles.data.length === 0) {
      return
    }
    let fileIds = dataState.userFiles.data.map((x) => x.file_id);
    if (fileIds.indexOf(shownFileId) > -1) {
      return;
    }
    setShownFileId(dataState.userFiles.data[0].file_id);
  }, [dataState.userFiles]);   // eslint-disable-line react-hooks/exhaustive-deps

  const onRotate = function () {
    let keys = dataState.userFiles.data.map((x) => x.file_id);
    let currentIndex = keys.indexOf(shownFileId);
    if (0 <= currentIndex && currentIndex < keys.length-1) {
      setShownFileId(keys[currentIndex+1]);
      return true;
    }
  }


  const fileScreen = function() {

    if (dataState.userFiles.data.length === 0) {
      return (
        <div>{strings.fileRotateNone}</div>
      );
    }
    if (fileType === 'video') {
      return (
        <foreignObject width="90%" height="90%">
          <video
            width="90%"
            height="90%"
            controls=""
            autoPlay
          >
            <source src={tmpUrl}/>
          </video>
        </foreignObject>
      );
    }

    return (
      <img
        alt={"Uploaded file"}
        src={tmpUrl}
        style={{
          marginLeft: "5%",
          height: "90%",
          width: "90%",
          objectFit: "cover",
        }}
      />
    );
  }


  return (
    <>
      <ScreenRotation overrideRotate={onRotate}/>
      <DataStateRefresh
        clientFileUploadRefresh={true}
      />
      {
        fileScreen()
      }
    </>
  );
}
