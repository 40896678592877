import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  lineGraph: {
    backgroundColor: theme.palette.background.extraLight,
  },
  customToolTip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.background.extraLight,
    whiteSpace: "nowrap",
    "& > *": {
      margin: "5px 0px",
    }
  }
}));
