import React, {useEffect, useState} from "react";
import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import axios from "axios";

// components
import {updateThresholds, useDataDispatch, useDataState} from "../../../context/DataContext";

import DataStateRefresh from "../../../components/DataStateRefresh";
import Table from "../../../components/Table";
import Widget from "../../../components/Widget";

// customized hook
import strings from "../../../strings";
import {iotUrl} from "../../../meta";


export default function ThresholdsSettings() {

  // global

  // static values
  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const [thresholds, setThresholds] = useState(dataState.thresholds);
  const [sendDisabled, setSendDisabled] = useState(false);

  const thresholdsList = [
    {
      measure: strings.co2,
      warn: 'co2Warn',
      danger: 'co2Danger',
      inputProps: {step: 5},
      parseValue: parseInt,
    },    {
      measure: strings.pressureDiffNegative,
      warn: 'pressureDiffWarn',
      danger: 'pressureDiffDanger',
      inputProps: {step: 0.1},
      parseValue: parseFloat,
    },
    {
      measure: strings.o2,
      warn: 'o2Warn',
      danger: 'o2Danger',
      inputProps: {step: 0.1},
      parseValue: parseFloat,
    },
  ]

  const thresholdsDataKeys = [
    {
      name: 'measure',
      label: strings.measure,
    },
    {
      name: 'warn',
      label: strings.warningYellow,
      options: {
        customBodyRender: function (value, tableMeta) {
          const rowInfo = thresholdsList[tableMeta.rowIndex];
          return (
            <TextField
              type={"number"}
              onChange={function (e) {
                const tmp = {};
                tmp[value] = rowInfo.parseValue(e.target.value);
                onChange(tmp);
              }}
              inputProps={rowInfo.inputProps}
              value={thresholds[value]}
            />
          );
        },
      },
    },
    {
      name: 'danger',
      label: strings.dangerRed,
      options: {
        customBodyRender: function (value, tableMeta) {
          const rowInfo = thresholdsList[tableMeta.rowIndex];
          return (
            <TextField
              type={"number"}
              onChange={function (e) {
                const tmp = {};
                tmp[value] = rowInfo.parseValue(e.target.value);
                onChange(tmp);
              }}
              inputProps={rowInfo.inputProps}
              value={thresholds[value]}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    setThresholds(dataState.thresholds);
  }, [dataState.thresholds]);

  const onChange = function(newMapping) {
    setThresholds({...thresholds, ...newMapping});
  }

  const confirmThresholds = async() => {
    let postData = {
      co2Warn: thresholds.co2Warn,
      co2Danger: thresholds.co2Danger,
      pressureDiffWarn: thresholds.pressureDiffWarn,
      pressureDiffDanger: thresholds.pressureDiffDanger,
      o2Warn: thresholds.o2Warn,
      o2Danger: thresholds.o2Danger,
    };
    setSendDisabled(true);
    axios.post(iotUrl + "thresholds/info", postData)
      .then(function () {
        updateThresholds(dataDispatch, postData);
        alert('Thresholds updated');
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        } else {
          alert('Failed to update thresholds');
        }
      })
      .finally(() => setSendDisabled(false));
  };

  return (
    <>
      <DataStateRefresh thresholdsRefresh={true}/>
      <Grid item md={12} xs={12}>
        <Widget
          upperTitle
          disableWidgetMenu
        >
          <Table
            data={thresholdsList}
            keys={thresholdsDataKeys}
            key="thresholdsData"
            options={{
              download: false,
              sort: false,
              search: false,
              filter: false,
              viewColumns: false,
            }}
            compact={true}
          />
        </Widget>
      </Grid>
      <Button
        color="primary"
        size="small"
        className="px-2"
        variant="contained"
        onClick={confirmThresholds}
        disabled={sendDisabled}
      >
        Ok
      </Button>

    </>
  );
}
