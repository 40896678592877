import React from "react";
import {
  FormGroup,
  InputLabel,
  Checkbox, Switch,
} from "@material-ui/core";

// styles
import useStyles from "../styles";

// components
import strings from "../../../strings";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";


export default function SiteSettingsMenu({ userPermissions, setUserPermissions }) {

  const classes = useStyles();

  // global
  return (
    <FormGroup>
      <InputLabel id="new-user-permissions-open-select-label">
        {strings.site}
      </InputLabel>
      {
        userPermissions.map(userPermission => (
          <div>
            <Typography style={{display: 'inline-block', width: '33%'}}>
              { userPermission.site_name }
            </Typography>

            <div
              style={{display: 'inline-block', width: '33%'}}
            >
              <FormControlLabel
                style={{marginBottom: 0, float: 'right'}}
                control={
                  <Switch
                    classes={{
                      track: classes.toggleSwitchTrack
                    }}
                    checked={userPermission.permission > 0}
                    onChange={(e) => {
                      const nextState = userPermissions.map(x =>
                        x.site_id === userPermission.site_id ?
                          {
                            ...x,
                            permission: e.target.checked ? 50 : 0,
                            alert_flag: e.target.checked ? x.alert_flag : false,
                          }
                          :
                          x
                      );
                      setUserPermissions(nextState);
                    }}
                    value="themeSetting"
                    color="primary"
                  />
                }
                label={strings.isMember}
              />
            </div>

            <div
              style={{display: 'inline-block', width: '33%'}}
            >
              <FormControlLabel
                style={{marginBottom: 0, float: 'right'}}
                control={
                  <Checkbox
                    size={'small'}
                    checked={userPermission.alert_flag}
                    disabled={userPermission.permission === 0}
                    onChange={(e) => {
                      const nextState = userPermissions.map(x =>
                        x.site_id === userPermission.site_id ?
                          {
                            ...x,
                            alert_flag: e.target.checked,
                          }
                          :
                          x
                      );
                      setUserPermissions(nextState);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={strings.alerts}
              />
            </div>
          </div>
        ))
      }
    </FormGroup>
  );
}
