export let baseUrl = '/';
//export let baseUrl = 'http://localhost:8000/';

export let airUrl = baseUrl + 'air/';
export let iotUrl = baseUrl + 'iot/';
export let authUrl = baseUrl + 'auth/'
export let mgmtUrl = baseUrl + 'mgmt/'
export let settingsUrl = baseUrl + 'settings/';  // Handheld only
export let filesUrl = baseUrl + 'files/';

// DEBUG FLAG
export let frontendDebug = false;
