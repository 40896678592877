import React, {useEffect, useState} from "react";
import {MenuItem, FormControl, InputLabel} from "@material-ui/core";

// styles
import '../../../themes/bootstrap.min.css';

import {useDataState} from "../../../context/DataContext";

import SelectDropdown from "../../../components/SelectDropdown";

import useStyles from "../styles";
import strings from "../../../strings";


export default function SelectionMenu({ setCurrentMapUrl,
                                        selectionInfo, setSelectionInfo,
                                        additionalButton }) {

  let classes = useStyles();

  let dataState = useDataState();

  const [siteDropdownOpen, setSiteDropdownOpen] = useState(false);
  const [siteLocationDropdownOpen, setSiteLocationDropdownOpen] = useState(false);


  useEffect(() => {
    let siteId = selectionInfo.siteId;
    if (dataState.sites[siteId] === undefined) {
      siteId = undefined;
    }

    let sites = Object.keys(dataState.sites)
      .filter((x) => !x.startsWith('__'))
      .sort();
    if (siteId === undefined && sites.length > 0) {
      siteId = sites[0];
    }
    if (siteId === undefined) {
      return
    }
    let siteName = dataState.sites[siteId].site_name

    // Location
    let locationId = selectionInfo.siteLocationId;
    let isBadLocationId = dataState.siteLocations[locationId] === undefined;
    isBadLocationId = isBadLocationId || dataState.siteLocations[locationId].site_id !== siteId;
    if (isBadLocationId) {
      locationId = undefined;
    }
    let locations = Object.keys(dataState.siteLocations)
      .filter((x) => !x.startsWith('__'))
      .sort()
      .filter((x) => dataState.siteLocations[x].site_id === siteId);
    if (locationId === undefined && locations.length > 0) {
      locationId = locations[0];
    }
    if (locationId === undefined) {
      return
    }
    let locationName = dataState.siteLocations[locationId].location_name

    setSelectionInfo({
      siteId: siteId,
      siteName: siteName,
      siteLocationId: locationId,
      siteLocationName: locationName
    })

    let maps = (dataState.siteLocationMaps[locationId] || []).map((x) => x.map_path);
    setCurrentMapUrl(maps[0] || '') ;
  }, [dataState.sites, dataState.siteLocations, dataState.siteLocationMaps]);  // eslint-disable-line react-hooks/exhaustive-deps


  function onSiteSelect(siteId_) {
    let siteId = siteId_ || selectionInfo.siteId;
    let siteName = dataState.sites[siteId].site_name
    let locations = Object.keys(dataState.siteLocations)
      .filter((x) => !x.startsWith('__'))
      .sort()
      .filter((x) => dataState.siteLocations[x].site_id === siteId);
    if (locations.length === 0) {
      return;
    }
    let locationId = locations[0];
    let locationName = dataState.siteLocations[locationId].location_name

    setSelectionInfo({
      siteId: siteId,
      siteName: siteName,
      siteLocationId: locationId,
      siteLocationName: locationName
    })

    let maps = (dataState.siteLocationMaps[locationId] || []).map((x) => x.map_path);
    setCurrentMapUrl(maps[0] || '') ;
  }

  function onSiteLocationUpdate(siteLocationId_) {
    let siteId = selectionInfo.siteId;
    let siteName = dataState.sites[siteId].site_name
    let locationId = siteLocationId_ || selectionInfo.siteLocations;
    let locationName = dataState.siteLocations[locationId].location_name

    setSelectionInfo({
      siteId: siteId,
      siteName: siteName,
      siteLocationId: locationId,
      siteLocationName: locationName
    })

    let maps = (dataState.siteLocationMaps[locationId] || []).map((x) => x.map_path);
    setCurrentMapUrl(maps[0] || '') ;
  }


  return (
    <>
      {/* Site/Site_Location/MapSelection Menu */}
      <>
        <FormControl className={classes.topBarFormControl}>
          <InputLabel id={"map-site-id-select-label"}>{strings.site}</InputLabel>
          <SelectDropdown
            labelId="map-site-id-select-label"
            id="map-site-id"
            open={siteDropdownOpen}
            onClose={() => {setSiteDropdownOpen(false)}}
            onOpen={() => {setSiteDropdownOpen(true)}}
            value={selectionInfo.siteId || ''}
            onChange={(e) => onSiteSelect(e.target.value)}
          >
            {
              Object.keys(dataState.sites)
                .filter((x) => !x.startsWith('__'))
                .sort()
                .map((site_id) => (
                  <MenuItem
                    key={site_id}
                    value={site_id}
                  >
                    { dataState.sites[site_id].site_name }
                  </MenuItem>
                ))
            }
          </SelectDropdown>
        </FormControl>
        <FormControl className={classes.topBarFormControl}>
          <InputLabel id={"map-site-location-id-select-label"}>{strings.location}</InputLabel>
          <SelectDropdown
            labelId="map-site-location-id-select-label"
            id="map-site-location-id-select"
            open={siteLocationDropdownOpen}
            onClose={() => {setSiteLocationDropdownOpen(false)}}
            onOpen={() => {setSiteLocationDropdownOpen(true)}}
            value={selectionInfo.siteLocationId || ''}
            onChange={(e) => onSiteLocationUpdate(e.target.value)}
          >
            {
              Object.keys(dataState.siteLocations)
                .filter((x) => !x.startsWith('__'))
                .sort()
                .filter((x) => dataState.siteLocations[x].site_id === selectionInfo.siteId)
                .map((site_location_id) => (
                  <MenuItem
                    key={ site_location_id }
                    value={site_location_id}
                  >
                    { dataState.siteLocations[site_location_id].location_name }
                  </MenuItem>
              ))
            }
          </SelectDropdown>
        </FormControl>
        <FormControl className={classes.topBarFormControl}>
          {additionalButton ? additionalButton : undefined }
        </FormControl>
      </>
    </>
  );
}
