import React from "react";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

import useStyles from "../../styles";
import { maxSoftBoundAutofix } from "../../../../globalFunctions";
import strings from "../../../../strings";


export default function ModalLineGraph({ thresholdData, clickedHostInfo, siteId, modalGraphData }) {

  let classes = useStyles();
  let theme = useTheme();

  const tooltipKeys = {
    download: ['download', 'Mbps', x => Math.round(10*x) / 10],
    upload: ['upload', 'Mbps', x => Math.round(10*x) / 10],
    ping: ['ping', 'ms', x => Math.round(10*x) / 10],
    tcp_received: ['tcpReceived', 'Mbps', x => Math.round(10*x) / 10],
    tcp_sent: ['tcpSent', 'Mbps', x => Math.round(10*x) / 10],
    udp_results: ['udpResults', 'Mbps', x => Math.round(10*x) / 10],
    udp_loss: ['udpLoss', '%', x => Math.round(10*x) / 10],
    ap_ssid: ['ssid', '', x => x],
    ap_channel: ['channel', '', x => x],
    ap_rssi: ['rssi', 'dB', x => x],
    ap_mac: ['mac', '', x => x],
    udp_num_streams: ['udpTotStreams', '', x => x]
  };

  // graph threshold line colors
  const thresholdColors = theme.graphs;

  // Custom toolip on Graph
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0]) {
      return (
        <div className={classes.customToolTip}>
          <p className="label">{`${label}`}</p>
          {
            Object.keys(payload[0].payload).filter(key => tooltipKeys[key] !== undefined)
              .map(key => (
                <p key={key} className="label" style={{color: thresholdColors[key]}}>
                  {`${strings[tooltipKeys[key][0]] || tooltipKeys[key][0]} : ${tooltipKeys[key][2](payload[0].payload[key])}${tooltipKeys[key][1]}`}
                </p>
              ))
          }
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {/* Graph for one device */}
      <ResponsiveContainer
        width="100%"
        height={650}
        key={modalGraphData}
      >
        {clickedHostInfo.host_type === 1 ?
          <LineChart
            isAnimationActive={false}
            width={500}
            height={650}
            className={classes.lineGraph}
            data={modalGraphData}
          >
            <CartesianGrid strokeDasharray="3 3 3 3" />
            <XAxis dataKey="time" />
            <YAxis
              yAxisId="left"
              orientation='left'
              unit="Mbps"
              domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]}
            />
            <YAxis
              yAxisId="right"
              orientation='right'
              unit="ms"
              domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]}
            />
            {
              thresholdData
                .filter(threshold => threshold.site_id === siteId && !threshold.hide_flag)
                .filter(threshold => ['download', 'upload', 'ping'].indexOf(threshold.name) >= 0)
                .map(threshold => (
                  <ReferenceLine
                    key={threshold.name}
                    y={threshold.value}
                    yAxisId={threshold.name === 'ping' ? "right" : "left"}
                    stroke={thresholdColors[threshold.name]}
                    strokeDasharray="3 3"
                    strokeWidth={3}/>
                ))
            }
            <Tooltip content={<CustomTooltip key="mapPageHeadunitCustomTooltip"/>}/>
            <Legend />
            <Line
              key={modalGraphData}
              isAnimationActive={false}
              yAxisId="left"
              type="monotone"
              dataKey="download"
              connectNulls={true}
              stroke={thresholdColors.download}
              activeDot={{ r: 8 }}
            />
            <Line
              key={modalGraphData}
              isAnimationActive={false}
              yAxisId="left"
              type="monotone"
              dataKey="upload"
              connectNulls={true}
              stroke={thresholdColors.upload}
              activeDot={{ r: 8 }}
            />
            <Line
              key={modalGraphData}
              isAnimationActive={false}
              yAxisId="right"
              type="monotone"
              dataKey="ping"
              connectNulls={true}
              stroke={thresholdColors.ping}
              activeDot={{ r: 8 }}
            />
          </LineChart>
          :
          <LineChart
            width={500}
            height={650}
            className={classes.lineGraph}
            data={modalGraphData}
          >
            <CartesianGrid strokeDasharray="3 3 3 3" />
            <XAxis dataKey="time" />
            <YAxis
              yAxisId="left"
              orientation='left'
              unit="Mbps"
              domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]}
            />
            <YAxis
              yAxisId="right"
              orientation='right'
              unit="%"
              domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]}
            />
            {
              thresholdData
                .filter(threshold => threshold.site_id === siteId && !threshold.hide_flag)
                .filter(threshold => ['tcp_received', 'tcp_sent', 'udp_results', 'udp_loss'].indexOf(threshold.name) >= 0)
                .map(threshold => (
                  <ReferenceLine
                    key={threshold.name}
                    y={threshold.value}
                    yAxisId={threshold.name === 'udp_loss' ? "right" : "left"}
                    stroke={thresholdColors[threshold.name]}
                    strokeDasharray="3 3"
                    strokeWidth={3}/>
                ))
            }
            <Tooltip content={<CustomTooltip key="mapPageAnalyzerCustomTooltip"/>}/>
            <Legend />
            <Line
              isAnimationActive={false}
              yAxisId="left"
              type="monotone"
              dataKey="tcp_sent"
              connectNulls={true}
              stroke={thresholdColors.tcp_sent}
              activeDot={{ r: 8 }}
            />
            <Line
              isAnimationActive={false}
              yAxisId="left"
              type="monotone"
              dataKey="tcp_received"
              connectNulls={true}
              stroke={thresholdColors.tcp_received}
              activeDot={{ r: 8 }}
            />
            <Line
              isAnimationActive={false}
              yAxisId="left"
              type="monotone"
              dataKey="udp_results"
              connectNulls={true}
              stroke={thresholdColors.udp_results}
              activeDot={{ r: 8 }}
            />
            <Line
              isAnimationActive={false}
              yAxisId="right"
              type="monotone"
              dataKey="udp_loss"
              connectNulls={true}
              stroke={thresholdColors.udp_loss}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        }
      </ResponsiveContainer>
    </>
  );
}
