import { useEffect, useRef } from "react";
import {reversedRoutes} from "./routes";
import {GOOD_COLOR, WARN_COLOR, DANGER_COLOR, ME_DEAD} from "./globals";


export let mimeTypes = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpg",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  zip: "application/zip",
}

let colorPriorityList = [
  [GOOD_COLOR, 0],
  [WARN_COLOR, 1],
  [DANGER_COLOR, 2]
];
let colorPriority = {};
colorPriorityList.forEach((x) => colorPriority[x[0]] = x[1]);


export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(function () {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(function () {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      // Start immediately
      tick();

      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export function maxSoftBoundAutofix(maxValue, softBound, autofixRounding) {
  return Math.max(softBound, autofixRounding*Math.ceil(maxValue / autofixRounding));
}


export function minSoftBoundAutofix(minValue, softBound, autofixRounding) {
  return Math.min(softBound, autofixRounding*Math.floor(minValue / autofixRounding));
}


export function formatDate(dateObject) {

  let year = dateObject.getFullYear();
  let month = '' + (dateObject.getMonth() + 1);
  let day = '' + dateObject.getDate();
  let hour = '' + dateObject.getHours();
  let minute = '' + dateObject.getMinutes();
  let second = '' + dateObject.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return year+ "/" + month + "/" + day + " " + hour + ":" + minute + ":" + second;
}


export function addTimeInfo(data, timezone) {
  data.forEach((datum) => {
    datum.time = (new Date(datum.t*1000)).toLocaleString("ja-JP", {timeZone: timezone});
  });
}


export function convertTime(data, timezone) {
  return (new Date(data*1000)).toLocaleString("ja-JP", {timeZone: timezone});
}


export function forceCellDecimal(value) {
  return (value || value === 0) ? value.toFixed(1) : value;
}


export function now() {
  return (new Date()).getTime() / 1000;
}


export function getLatestTime (array) {
  if (array === undefined || array.length === 0) {
    return undefined;
  }
  return array[array.length-1].t
}


export function mergeTimeSeries (oldData, newData, graphRange) {
  newData.sort((a, b) => (a.t > b.t) ? 1 : ((b.t > a.t) ? -1 : 0));
  newData = [...oldData, ...newData];
  const now_ = now();
  const tooOldIndex = newData.findIndex((x) => now_ - x.t < graphRange)
  if (tooOldIndex > -1) {
    newData = newData.slice(tooOldIndex);
  }
  return newData;
}


export function getNextUrl (urlList, currentUrl, history) {
  if (urlList.length === 0) {
    return
  }

  let nextUrl;
  let currentIdx = urlList.indexOf(currentUrl);
  if (currentIdx === -1) {
    nextUrl = urlList[0];
  } else {
    let delta = 1;
    while (delta < urlList.length) {
      nextUrl = urlList[(currentIdx+delta) % urlList.length];
      if (reversedRoutes[nextUrl] === undefined) {
        delta += 1
      } else {
        break
      }
    }
    if (delta === urlList.length) {
      return
    }
  }
  if (currentUrl === nextUrl) {
    return
  }
  history.push(nextUrl);
}


export function hasPressure(hostsData, currentMac) {
  let currentHostData = (hostsData[currentMac] || {});
  let siteLocationId = currentHostData.site_location_id;

  const refs = Object.values(hostsData)
    .filter((host) => typeof host !== 'number')
    .filter((host) => host.site_location_id === siteLocationId)
    .filter((host) => host.is_ref);

  return refs.length > 0;
}

export function getCo2Color(co2, thresholds) {
  if (co2 === undefined) {
    return undefined;
  }
  if (co2 < thresholds.co2Warn) {
    return GOOD_COLOR;
  }
  if (co2 < thresholds.co2Danger) {
    return WARN_COLOR;
  }
  return DANGER_COLOR;
}

export function getO2Color(o2, thresholds) {
  if (o2 === undefined) {
    return undefined;
  }
  if (o2 > thresholds.o2Warn) {
    return GOOD_COLOR;
  }
  if (o2 > thresholds.o2Danger) {
    return WARN_COLOR;
  }
  return DANGER_COLOR;
}

export function getPressureDiffColor(pressureDiff, thresholds) {
  if (pressureDiff === undefined) {
    return undefined;
  }
  if (pressureDiff > thresholds.pressureDiffDanger) {
    return DANGER_COLOR;
  }
  if (pressureDiff > thresholds.pressureDiffWarn) {
    return WARN_COLOR;
  }
  return GOOD_COLOR;
}

export function getLastSeenColor(t, deadTimeout) {
  if (t === undefined) {
    return undefined;
  }
  if (now() - t > deadTimeout) {
    return DANGER_COLOR;
  }
  return undefined;
}

export function getWorseColor(colorList) {
  let resultColor = undefined;
  let priority = -1;
  colorList.forEach(function (color) {
    if (color === undefined) return;
    if (colorPriority[color] > priority) {
      resultColor = color;
      priority = colorPriority[color];
    }
  });
  return resultColor;
}

export function convertHexToRgba(hex, opacity) {
  if (!hex) return;

  let tempHex = hex.replace('#', '')
  if (tempHex.length === 3) {
    tempHex += tempHex
  }
  const r = parseInt(tempHex.substring(0, 2), 16)
  const g = parseInt(tempHex.substring(2, 4), 16)
  const b = parseInt(tempHex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

export function deviceSort(macs, dataState) {
  return macs.sort(function (aMac, bMac) {
    const aOrder = (dataState.hosts[aMac] || {}).order;
    const bOrder = (dataState.hosts[bMac] || {}).order;
    return aOrder - bOrder
  });
}

export function getDeviceTimeout(dataState, mac) {
  return (dataState.hosts[mac] || {}).timeout || ME_DEAD;
}

export function keys(obj) {
  return Object.keys(obj).filter((x) => !x.startsWith('__'))
}