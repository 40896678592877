import React, {useState} from "react";
import {Grid, Typography} from "@material-ui/core";


// styles

// components
import DataStateRefresh from "../../components/DataStateRefresh";
import PageTitle from "../../components/PageTitle";
import GraphSettingsMenu from "./components/GraphSettingsMenu";
import Co2Graph from "../../components/Co2Graph";
import O2Graph from "../../components/O2Graph";
import PressureDiffGraph from "../../components/PressureDiffGraph";

// customized hook
import {useDataState} from "../../context/DataContext";


import strings from "../../strings";
import {deviceSort} from "../../globalFunctions";


function DataAnalysis() {
  // global

  const [data, setData] = useState({});

  // static values
  const dataState = useDataState();

  // state hooks

  // state hooks
  // Interval hook

  const measureGraph = function (mac) {
    switch (data.__measure) {
      case "co2":
        return (
          <Co2Graph
            mac={mac}
            height={350}
            overwriteData={data}
          />);

      case "o2":
        return (
          <O2Graph
            mac={mac}
            height={350}
            overwriteData={data}
          />);

      case "pressure_diff":
        return (
          <PressureDiffGraph
            mac={mac}
            height={350}
            withTemperature={false}
            smallDots={true}
            overwriteData={data}
          />);

      default:
        return undefined;
    }
  }

  const dataPanel = function (mac) {
    let mdSize = Object.keys(data).filter((mac) => !mac.startsWith('_')).length === 1 ? 12 : 6;
    return (
      <Grid item md={mdSize} xs={12}>
        <Grid justify={"center"} container spacing={1}>
          <Typography variant={"h5"}>
            {(dataState.hosts[mac] || {}).display_name}
          </Typography>
        </Grid>
        {measureGraph(mac)}
      </Grid>
    );
  }


  return (
    <>
      <DataStateRefresh
        hostRefresh={true}
        thresholdsRefresh={true}
      />

      <PageTitle title={strings.dataAnalysis} />

      <Grid container spacing={1} style={{
        border: "#888888 2px solid",
        borderRadius: "10px",
      }}>
        <Grid item md={12} xs={12}>
          <GraphSettingsMenu setData={setData}/>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {
          deviceSort(Object.keys(data).filter((mac) => !mac.startsWith('_')), dataState).map(dataPanel)
        }
      </Grid>

    </>
  );
}

export default DataAnalysis;