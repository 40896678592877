import React from "react";
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import {getDeviceTimeout, now} from "../../globalFunctions";

import evuOfflineIcon from "./../../icons/evuOffline.svg"
import evuOnlineIcon from "./../../icons/evuOnline.svg"
import evuGoodIcon from "./../../icons/evuGreen.svg"
import evuWarnIcon from "./../../icons/evuYellow.svg"
import evuDangerIcon from "./../../icons/evuRed.svg"

import meOfflineIcon from "./../../icons/meOffline.svg"
import meOnlineIcon from "./../../icons/meOnline.svg"
import meGoodIcon from "./../../icons/meGreen.svg"
import meWarnIcon from "./../../icons/meYellow.svg"
import meDangerIcon from "./../../icons/meRed.svg"

import displayOfflineIcon from "./../../icons/displayOffline.svg"
import displayOnlineIcon from "./../../icons/displayOnline.svg"
import displayGoodIcon from "./../../icons/displayGreen.svg"
import displayWarnIcon from "./../../icons/displayYellow.svg"
import displayDangerIcon from "./../../icons/displayRed.svg"

import {
  DISPLAY_AMS, DISPLAY_DEVICES,
  EVU_DEVICES, ME238_DEVICES,
} from "../../globals";

export default class MapDevice extends React.Component {

  clickDragSplitTime = 200;

  constructor(props) {
    super(props);
    this.state = {
      dragStartTime: new Date(),
    }
  }

  componentDidMount() {
    let component = this;
    let state = this.state;
    const handleDrag = d3.drag()
      .subject(function() {
        const me = d3.select(this);
        return { x: me.attr('x'), y: me.attr('y') }
      })
      .on('start', function() {
        state.dragStartTime = new Date();
      })
      .on('drag', function() {
        const me = d3.select(this);
        me.attr('x', d3.event.x);
        me.attr('y', d3.event.y);
        component.props.hostInfo.position[0] = parseInt(d3.event.x);
        component.props.hostInfo.position[1] = parseInt(d3.event.y);
      })
      .on('end', function() {
        if (new Date() - state.dragStartTime < component.clickDragSplitTime) {
          component.props.onClick(component.props.hostInfo.mac_id);
        } else {
          component.props.onDragEnd(component.props.hostInfo.mac_id);
        }
      });
    const node = ReactDOM.findDOMNode(this);
    handleDrag(d3.select(node));
  }


  getEvuDrawing = function () {
    let component = this;
    let mac = component.props.hostInfo.mac_id;

    let hosts = component.props.hosts;
    let thresholdsData = component.props.dataState.thresholds;
    let hasRef = hosts.filter((x) => x.is_ref).length > 0;

    let iconUrl = evuOnlineIcon;
    let hostData = this.props.hostData;
    if (hostData !== undefined) {

      if (hasRef) {
        // Pressure setup
        if (now() - hostData.t > getDeviceTimeout(this.props.dataState, mac)) {
          iconUrl = evuOfflineIcon;
        } else if (hostData.pressure_diff === undefined) {
          let is_ref = this.props.hostInfo.is_ref;
          if (!is_ref) {
            iconUrl = evuOfflineIcon;
          }
        } else if (hostData.pressure_diff < thresholdsData.pressureDiffWarn) {
          iconUrl = evuGoodIcon;
        } else if (hostData.pressure_diff < thresholdsData.pressureDiffDanger) {
          iconUrl = evuWarnIcon;
        } else {
          iconUrl = evuDangerIcon;
        }
      } else {
        // CO2 setup
        let co2 = hostData.co2;
        if (now() - hostData.t > getDeviceTimeout(this.props.dataState, mac)) {
          iconUrl = evuOfflineIcon;
        } else if (co2 === undefined) {
          iconUrl = evuOfflineIcon;
        } else if (co2 < thresholdsData.co2Warn) {
          iconUrl = evuGoodIcon;
        } else if (co2 < thresholdsData.co2Danger) {
          iconUrl = evuWarnIcon;
        } else {
          iconUrl = evuDangerIcon;
        }
      }
    }
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        data-tip={component.props.hostInfo.mac_id}
        data-for={component.props.hostInfo.mac_id}
        x={component.props.hostInfo.position[0] || 50+component.props.deviceIndex*20}
        y={component.props.hostInfo.position[1] || 50+component.props.deviceIndex*20}
      >
        <image
          xlinkHref={iconUrl}
          width="26.4pt"
          height="26.0pt"
        />
      </svg>
    )
  }


  getMeDrawing = function () {
    let component = this;
    let mac = component.props.hostInfo.mac_id;

    let hosts = component.props.hosts;
    let thresholdsData = component.props.dataState.thresholds;
    let hasRef = hosts.filter((x) => x.is_ref).length > 0;

    let iconUrl = meOnlineIcon;
    let hostData = this.props.hostData;
    if (hostData !== undefined) {

      if (hasRef) {
        // Pressure setup
        if (now() - hostData.t > getDeviceTimeout(this.props.dataState, mac)) {
          iconUrl = meOfflineIcon;
        } else if (hostData.pressure_diff === undefined) {
          let is_ref = this.props.hostInfo.is_ref;
          if (!is_ref) {
            iconUrl = meOfflineIcon;
          }
        } else if (hostData.pressure_diff < thresholdsData.pressureDiffWarn) {
          iconUrl = meGoodIcon;
        } else if (hostData.pressure_diff < thresholdsData.pressureDiffDanger) {
          iconUrl = meWarnIcon;
        } else {
          iconUrl = meDangerIcon;
        }
      } else {
        // CO2 setup
        if (now() - hostData.t > getDeviceTimeout(this.props.dataState, mac)) {
          iconUrl = meOfflineIcon;
        } else if (hostData.co2 === undefined) {
          iconUrl = meOfflineIcon;
        } else if (hostData.co2 < thresholdsData.co2Warn) {
          iconUrl = meGoodIcon;
        } else if (hostData.co2 < thresholdsData.co2Danger) {
          iconUrl = meWarnIcon;
        } else {
          iconUrl = meDangerIcon;
        }
      }
    }

    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        data-tip={component.props.hostInfo.mac_id}
        data-for={component.props.hostInfo.mac_id}
        x={component.props.hostInfo.position[0] || 50+component.props.deviceIndex*20}
        y={component.props.hostInfo.position[1] || 50+component.props.deviceIndex*20}
      >
        <image
          xlinkHref={iconUrl}
          width="18.9pt"
          height="23.0pt"
        />
      </svg>
    )
  }

    getAmsDisplayDrawing = function () {
      let component = this;
      let mac = component.props.hostInfo.mac_id;

      let thresholdsData = component.props.dataState.thresholds;

      let iconUrl = meOnlineIcon;
      let hostData = this.props.hostData;
      if (hostData !== undefined) {

        // Pressure setup
        if (now() - hostData.t > getDeviceTimeout(this.props.dataState, mac)) {
          iconUrl = displayOfflineIcon;
        } else if (hostData.pressure_diff === undefined) {
          let is_ref = this.props.hostInfo.is_ref;
          if (!is_ref) {
            iconUrl = displayOfflineIcon;
          }
        } else if (hostData.pressure_diff < thresholdsData.pressureDiffWarn) {
          iconUrl = displayGoodIcon;
        } else if (hostData.pressure_diff < thresholdsData.pressureDiffDanger) {
          iconUrl = displayWarnIcon;
        } else {
          iconUrl = displayDangerIcon;
        }
      }

      return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        data-tip={component.props.hostInfo.mac_id}
        data-for={component.props.hostInfo.mac_id}
        x={component.props.hostInfo.position[0] || 50+component.props.deviceIndex*20}
        y={component.props.hostInfo.position[1] || 50+component.props.deviceIndex*20}
      >
        <image
          xlinkHref={iconUrl}
          width="18.9pt"
          height="23.0pt"
        />
      </svg>
    )
  }

  getDisplayDrawing = function () {
    let component = this;
    let mac = component.props.hostInfo.mac_id;

    let iconUrl = meOnlineIcon;
    let hostData = this.props.hostData;
    if (hostData !== undefined) {
      if (now() - hostData.t > getDeviceTimeout(this.props.dataState, mac)) {
        iconUrl = displayOfflineIcon;
      }
    }

    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        data-tip={component.props.hostInfo.mac_id}
        data-for={component.props.hostInfo.mac_id}
        x={component.props.hostInfo.position[0] || 50+component.props.deviceIndex*20}
        y={component.props.hostInfo.position[1] || 50+component.props.deviceIndex*20}
      >
        <image
          xlinkHref={iconUrl}
          width="18.9pt"
          height="23.0pt"
        />
      </svg>
    )
  }

  getDefaultDrawing = function () {
    let component = this;
    return (
      <text
        data-tip={component.props.hostInfo.mac_id}
        data-for={component.props.hostInfo.mac_id}
        x={component.props.hostInfo.position[0] || 50+component.props.deviceIndex*20}
        y={component.props.hostInfo.position[1] || 50+component.props.deviceIndex*20}
        fill="red"
        width={128}
        height={76}
      >
        ??
      </text>
    )
  }


  getDrawing = function () {
    let component = this;
    let host_type = this.props.hostInfo.host_type;
    if (ME238_DEVICES.indexOf(host_type) > -1) {
      return component.getMeDrawing();
    } else if (EVU_DEVICES.indexOf(host_type) > -1) {
      return component.getEvuDrawing();
    } else if (host_type === DISPLAY_AMS) {
      return component.getAmsDisplayDrawing();
    } else if (DISPLAY_DEVICES.indexOf(host_type) > -1) {
      return component.getDisplayDrawing();
    } else {
      return component.getDefaultDrawing();
    }
  }


  render() {
      return this.getDrawing();
  }

}
