import React, {useEffect, useState} from "react";
import axios from "axios";
import {Grid, TextField} from "@material-ui/core";

import {Button} from "../../../components/Wrappers";

import {useDataState} from "../../../context/DataContext";
import {useLocationState} from "../../../context/LocationContext";

import {iotUrl} from "../../../meta";
import strings from "../../../strings";


export default function SensorCalibrationSettings({ mac }) {

  const dataState = useDataState();
  const locationState = useLocationState();

  const [pressureDiff, setPressureDiff] = useState(0.0);

  const [sendEnabled, setSendEnabled] = useState(false);
  const [refHost, setRefHost] = useState({});

  const sendPressureCalibration = async() => {
    await axios.post(`${iotUrl}hosts/config/pressure/reset`, {
      mac: mac,
      pressureDiff: pressureDiff
    });
    setSendEnabled(false);
    alert(strings.resetSuccessfull)
  };

  useEffect(function () {
    if (mac === undefined || mac === '' || dataState.hosts[mac] === undefined) {
      return
    }
    let host = dataState.hosts[mac];
    if (host.is_ref) {
      setRefHost({});
      setSendEnabled(false);
      return
    }
    let location_id = host.site_location_id;
    let hosts = Object
      .keys(dataState.hosts)
      .map((mac) => dataState.hosts[mac])
      .filter((hostInfo) => hostInfo.is_ref && hostInfo.site_location_id===location_id);
    if (hosts.length === 1) {
      setSendEnabled(true);
      setRefHost(hosts[0]);
      return
    }
    setSendEnabled(false);
    setRefHost({});
  }, [dataState.hosts, mac]);  // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      {
        locationState.userInfo.securityLevel >= 100 && locationState.calibrationShown ?
          <>
            <br/>
            <Grid container spacing={2} style={{
              border: "#888888 2px solid",
              borderRadius: "10px",
              marginTop: "25px"
            }} >
              <Grid item md={6} xs={6}>
                <TextField
                  type="number"
                  inputProps={{min: "-20", max: "20", step: ".1"}}
                  disabled={!sendEnabled}
                  value={pressureDiff}
                  onChange={(e) => setPressureDiff(e.target.value)}
                />
                <Button
                  color="success"
                  size="small"
                  className="px-2"
                  variant="contained"
                  disabled={!sendEnabled}
                  onClick={sendPressureCalibration}
                >
                  {strings.pressureDiffReset}
                </Button>
              </Grid>
              <Grid item md={6} xs={6}>
                <span>{strings.referencePressureDevice}</span>
                {
                  refHost.mac_id !== undefined ?
                    <span>{refHost.display_name}</span>
                    :
                    <span>{strings.none}</span>
                }
              </Grid>
            </Grid>
          </>
          :
          undefined
      }
    </>
  );
};
