import axios from "axios";
import React, {useEffect, useState} from "react";
import {CloudDownload} from "@material-ui/icons";

// styles


// components
import PageTitle from "../../components/PageTitle";
import DataStateRefresh from "../../components/DataStateRefresh";
import Table from "../../components/Table";

import {useDataState} from "../../context/DataContext";
import {useUserState} from "../../context/UserContext";

import {airUrl, iotUrl} from "../../meta";
import strings from "../../strings";
import {mimeTypes} from "../../globalFunctions";

export default function Report() {

  const dataState = useDataState();
  const userState = useUserState();

  const [reports, setReports] = useState([]);

  // static values
  const reportKeys = [
    {name: 'report_id', label: 'Id',
      options: {display: false, filter: false, search: false}},
    {name: 'site_name', label: strings.site},
    {name: 'start_date', label: strings.startDate},
    {name: 'end_date', label: strings.endDate},
    {name: 'id', label: strings.download, options: {
      filter: false, search: false,
      customBodyRender: (_, rowInfo) => (
          <CloudDownload
            onClick={function () {
              let reportId = reports[rowInfo.rowIndex].report_id;
              sendMakeReportRequest(reportId);
            }}
          />
        )
      }
    },
  ];

  const sendMakeReportRequest = function (reportId) {
    let targetBaseUrl = userState.cirrusApp === 'air' ? airUrl : iotUrl;
    axios({
      url: targetBaseUrl + `reports/get?report_id=${reportId}`,
      method: 'GET',
      responseType: 'blob',
    }).then(function (response) {
      let exportFilename = response.data.type === mimeTypes.docx ? 'report.docx': 'report.pdf';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', exportFilename); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  };

  useEffect(() => {
    let hasChange = false;
    let newReports = [...reports];
    newReports.forEach((reportDict) => {
      let siteId = reportDict.site_id;
      let currentName = reportDict.site_name;
      reportDict.site_name = siteId;
      if (dataState.sites[siteId]) {
        reportDict.site_name = dataState.sites[siteId].site_name;
      }
      if (currentName !== reportDict.site_name) {
        hasChange = true;
      }
    })
    if (hasChange) {
      setReports(newReports);
    }
  }, [dataState.sites, reports]);


  const fetchReportsList = async() => {
    const { data } = await axios(`${iotUrl}reports/list`);
    setReports(data);
  };

  useEffect(() => {
    fetchReportsList().then(undefined);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // local
  return (
    <>
      <DataStateRefresh siteRefresh={true}/>

      <PageTitle title={strings.reportTitle} />

      <Table
        data={reports}
        keys={reportKeys}
        staticKeys={true}
        disableToolbarSelect={true}
        key="reportData"
      />

    </>
  );
}
