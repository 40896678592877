import React, {useState, useEffect}  from "react";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";

import GeneralSettings from "./components/GeneralSettings";
import RotationSettings from "./components/RotationSettings";
import ServiceAccountSettings from "./components/ServiceAccountSettings";
import FileUploadSettings from "./components/FileUploadSettings";
import ThresholdsSettings from "./components/ThresholdsSettings";

// customized hook
import strings from "../../strings";
import {routes} from "../../routes";

// Graph Windows tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </Typography>
  );
}

function Settings({location, history}) {
  // global
  let classes = useStyles();

  let tabRoutes = [
    routes.settingsGeneral,
    routes.settingsRotation,
    routes.fileUpload,
    routes.settingsThresholds,
    routes.settingsServiceAccount,
  ];

  // Active tab order
  const value = Math.max(0, tabRoutes.indexOf(location.pathname));
  const [roomTabValue, setRoomTabValue] = useState(value);

  const [clickCounter, setClickCounter] = useState(0);

  // Tab selection handler
  function handleRoomTabChange (event, newValue) {
    history.push(tabRoutes[newValue]);
    setRoomTabValue(newValue)
  }

  // TabPanel option
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  useEffect(function () {
    setRoomTabValue(Math.max(0, tabRoutes.indexOf(location.pathname)));
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Grid container spacing={2}>
        {/* Room Sensor Widget */}
        <Grid item md={12} xs={12}>
          <Widget
            noBodyPadding
            bodyClass={classes.tableWidget}
            disableWidgetMenu
          >
            <Tabs
              value={roomTabValue}
              onChange={handleRoomTabChange}
              aria-label="simple tabs example"
            >
              <Tab label={strings.general} {...a11yProps(0)} />
              <Tab label={strings.dataScreen} {...a11yProps(1)} />
              <Tab label={strings.fileUpload} {...a11yProps(2)} />
              <Tab label={strings.thresholds} {...a11yProps(3)} onClick={() => setClickCounter(clickCounter+1)} />
              <Tab label="Service account" {...a11yProps(4)} hidden={clickCounter < 10} />
            </Tabs>
            <TabPanel className={classes.tabPanel} value={roomTabValue} index={0}>
              {roomTabValue===0 ? <GeneralSettings/> : undefined}
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={roomTabValue} index={1}>
              {roomTabValue===1 ? <RotationSettings history={history}/> : undefined}
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={roomTabValue} index={2}>
              {roomTabValue===2 ? <FileUploadSettings/> : undefined}
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={roomTabValue} index={3}>
              {roomTabValue===3 ? <ThresholdsSettings/> : undefined}
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={roomTabValue} index={4}>
              {roomTabValue===4 ? <ServiceAccountSettings/> : undefined}
            </TabPanel>
          </Widget>
        </Grid>

      </Grid>
    </>
  );
}

export default withRouter(Settings);
