import React from "react";
import {
  CO2_DANGER, CO2_WARN,
  O2_DANGER, O2_WARN,
  PRESSURE_DANGER, PRESSURE_WARN
} from "../globals";

const DataStateContext = React.createContext();
const DataDispatchContext = React.createContext();

function dataReducer(state, action) {
  switch (action.type) {
    case "HOSTS":
      return { ...state, hosts: action.hosts};
    case "SITES":
      return { ...state, sites: action.sites};
    case "SITE_LOCATIONS":
      return { ...state, siteLocations: action.siteLocations};
    case "SITE_LOCATION_MAPS":
      return { ...state, siteLocationMaps: action.siteLocationMaps};
    //
    case "ME_LATEST_BY_HOST":
      return { ...state, meLatestByHost: action.meLatestByHost};
    case "ME_GRAPH_BY_HOST":
      return { ...state, meGraphByHost: action.meGraphByHost};
    //
    case "THRESHOLDS":
      return { ...state, thresholds: action.thresholds};
    //
    case "USER_FILES":
      return { ...state, userFiles: action.userFiles};
    //
    case "RESET":
      return { ...JSON.parse(initStateBackup)};
    //
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}


const initState = {
  hosts: {},
  sites: {},
  siteLocations: {},
  siteLocationMaps: {},
  //
  meLatestByHost: {},
  meGraphByHost: {},
  //
  thresholds: {
    o2Warn: O2_WARN,
    o2Danger: O2_DANGER,
    co2Warn: CO2_WARN,
    co2Danger: CO2_DANGER,
    pressureDiffWarn: PRESSURE_WARN,
    pressureDiffDanger: PRESSURE_DANGER,
  },
  //
  userFiles: {data: [], blacklist: []},
};
const initStateBackup = JSON.stringify(initState);


function DataProvider({ children }) {

  const [state, dispatch] = React.useReducer(dataReducer, {...initState});

  return (
    <DataStateContext.Provider value={state}>
      <DataDispatchContext.Provider value={dispatch}>
        {children}
      </DataDispatchContext.Provider>
    </DataStateContext.Provider>
  );
}

function useDataState() {
  const context = React.useContext(DataStateContext);
  if (context === undefined) {
    throw new Error("useDataState must be used within a DataProvider");
  }
  return context;
}

function useDataDispatch() {
  const context = React.useContext(DataDispatchContext);
  if (context === undefined) {
    throw new Error("useDataDispatch must be used within a DataProvider");
  }
  return context;
}

export {
  DataProvider,
  useDataState,
  useDataDispatch,
  //
  updateHosts,
  updateSites,
  updateSiteLocations,
  updateSiteLocationMaps,
  //
  updateMeLatestByHost,
  updateMeGraphByHost,
  //
  updateThresholds,
  //
  updateUserFiles,
  //
  resetContext,
};


// ###########################################################


function updateHosts(dispatch, hosts) {
  dispatch({
    type: "HOSTS",
    hosts
  });
}


function updateSites(dispatch, sites) {
  dispatch({
    type: "SITES",
    sites
  });
}


function updateSiteLocations(dispatch, siteLocations) {
  dispatch({
    type: "SITE_LOCATIONS",
    siteLocations
  });
}


function updateSiteLocationMaps(dispatch, siteLocationMaps) {
  dispatch({
    type: "SITE_LOCATION_MAPS",
    siteLocationMaps
  });
}


// ###########################################################


function updateMeLatestByHost(dispatch, meLatestByHost) {
  dispatch({
    type: "ME_LATEST_BY_HOST",
    meLatestByHost
  });
}


function updateMeGraphByHost(dispatch, meGraphByHost) {
  dispatch({
    type: "ME_GRAPH_BY_HOST",
    meGraphByHost
  });
}


// ###########################################################


function updateThresholds(dispatch, thresholds) {
  dispatch({
    type: "THRESHOLDS",
    thresholds
  });
}


// ###########################################################


function updateUserFiles(dispatch, userFiles) {
  dispatch({
    type: "USER_FILES",
    userFiles
  });
}


// ###########################################################


function resetContext(dispatch) {
  dispatch({
    type: "RESET",
  });
}


