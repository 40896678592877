import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  startHidden: {
    display: "none"
  },
  fullpage: {
    display: "block",
    width: "100%",
    height: "100%"
  },
  graphSpan: {
    display: "inline-block",
    height: "300px",
    width: "99%",
  },
  smallCol: {
    backgroundColor: theme.palette.background.default,
    width: "30%"
  },
  modal: {
    backgroundColor: theme.palette.background.default,
    top: "10%",
    left: "15%",
  },
  modalXl: {
    marginTop: "5%",
    marginLeft: "17%",
    height: "90%",
    width: "75%",
    maxWidth: "1600px"
  },
  modalXlDialog: {
    height: "90%",
    width: "100%",
    maxWidth: "100%" // Overwrite the bootstrap default max size
  },
  modalHeader: {
    backgroundColor: theme.palette.background.default,
  },
  modalBody: {
    backgroundColor: theme.palette.background.default,
  },
  lineGraph: {
    backgroundColor: theme.palette.background.extraLight,
  },
  customToolTip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.background.extraLight,
    whiteSpace: "nowrap",
    "& > *" : {
      margin: "5px 0px",
    }
  },
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  tableHeading: {
    fontWeight: 700,
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  toggleSwitchTrack: {
    backgroundColor: theme.palette.background.extraLight,
  },
  inputRoot: {
    color: 'inherit',
    borderBottom: '1px solid grey',
    marginBottom: '10px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  tableScrollable: {
    "& thead": {
      display: "block",
      paddingBottom: "3px",
      borderBottom: "2px solid grey"
    },
    "& tbody": {
      display: "block",
      height: "650px",
      overflowY: "auto",
      overflowX: "hidden"
    },
    "& tr": {
      height: "50px",
      display: "flex"
    },
    "& td": {
      flex: "1",
      textAlign: "center"
    },
  },
}));
