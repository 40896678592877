import React, {useEffect, useState} from "react";
import {
  FormControlLabel,
  Switch,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";

// styles
import useStyles from "../styles";

// components

// customized hook
import {
  useUserState,
  useUserDispatch,
  updateServiceAccount,
} from "../../../context/UserContext";
import {authUrl} from "../../../meta";
import {
  toggleCalibrationShown,
  useLocationDispatch,
  useLocationState
} from "../../../context/LocationContext";

export default function RotationSettings() {
  // global
  let classes = useStyles();

  // static values
  const userState = useUserState();
  const userDispatch = useUserDispatch();

  const locationState = useLocationState();
  const locationDispatch = useLocationDispatch();

  const [shown, setShown] = useState(userState.serviceAccount !== undefined);
  const [credentials, setCredentials] = useState({username: undefined, password: undefined});

  const updateCredentials = async function () {
    if (!userState.serviceAccount) return;

    setCredentials({
      username: userState.serviceAccount.username,
      password: userState.serviceAccount.password
    })
  }

  function confirmLogin() {
    axios.post(authUrl + "login", {
      username: credentials.username,
      password: credentials.password
    })
    .then(function () {
      updateServiceAccount(userDispatch, credentials);
    })
  }

  useEffect(function () {
    updateCredentials().then(undefined);
  }, [shown]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container spacing={2} style={{
        border: "#888888 2px solid",
        borderRadius: "10px",
        margin: "1%",
        width: "98%"
      }} >
        <Grid item md={12} xs={12}>
          <div>Do not use this screen unless you know what you are doing.</div>
          <div>Reserved for service accounts which should always try to login.</div>
        </Grid>

        <Grid item md={4} xs={4}>
          <FormControlLabel
            className={clsx(classes.margin, classes.textField)}
            control={
              <Switch
                classes={{
                  track: classes.toggleSwitchTrack
                }}
                checked={shown}
                onChange={() => setShown(!shown)}
                value="themeSetting"
                color="primary"
              />
            }
            label="Show credentials"
          />
        </Grid>
        <Grid item md={8} xs={8}/>

        <Grid
          hidden={!shown && userState.serviceAccount === undefined}
          item md={6} xs={6}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="username">Username</InputLabel>
            <Input
              id="username"
              value={credentials.username}
              onChange={(e) => {
                setCredentials({...credentials, username: e.target.value})
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          hidden={!shown && userState.serviceAccount === undefined}
          item md={6} xs={6}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              value={credentials.password}
              onChange={(e) => {
                setCredentials({...credentials, password: e.target.value})
              }}
            />
          </FormControl>
        </Grid>

        <Grid item md={6} xs={6}>
          <Button
            hidden={!shown && userState.serviceAccount === undefined}
            onClick={confirmLogin}
            value="themeSetting"
            color="primary"
          >
            Force login retry
          </Button>
        </Grid>
        <Grid item md={6} xs={6}>
          <Button
            hidden={!shown && userState.serviceAccount === undefined}
            onClick={() => {
              updateServiceAccount(userDispatch, undefined);
            }}
            value="themeSetting"
            color="primary"
          >
            Cancel
          </Button>
        </Grid>

      </Grid>



      <Grid container spacing={2} style={{
        border: "#888888 2px solid",
        borderRadius: "10px",
        margin: "1%",
        width: "98%"
      }} >
        <Grid item md={12} xs={12}>
          <div>Device settings</div>
        </Grid>

        <Grid item md={4} xs={4}>
          <FormControlLabel
            className={clsx(classes.margin, classes.textField)}
            control={
              <Switch
                classes={{
                  track: classes.toggleSwitchTrack
                }}
                checked={locationState.calibrationShown}
                onChange={() => toggleCalibrationShown(locationDispatch, !locationState.calibrationShown)}
                value="themeSetting"
                color="primary"
              />
            }
            label="Allow sensor calibration"
          />
        </Grid>
        <Grid item md={8} xs={8}/>

      </Grid>
    </>
  );
}
