import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import {Button} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

// styles
import '../../../themes/bootstrap.min.css';

import {fetchClientFileUpload} from "../../../components/DataStateRefresh";

import {useDataDispatch, useDataState} from "../../../context/DataContext";

import useStyles from "../styles";
import strings from "../../../strings";
import {filesUrl} from "../../../meta";


export default function FileModal({ shownFile,
                                    modalShown, setModalShown }) {

  const [tmpUrl, setTmpUrl] = useState('');
  const [fileType, setFileType] = useState('image');
  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const getFileData = async function () {
    if (!shownFile.file_id) {
      return
    }
    const url = filesUrl + "file?id=" + shownFile.file_id;
    try {
      const result = await axios(url);
      if (result.headers['content-type'].startsWith('video')) {
        setFileType('video');
      } else {
        setFileType('image');
      }
      setTmpUrl('data:' + shownFile.mime + ';base64,' + (result.data));
    } catch (err) {
      console.log(err);
    }
  }

  const deleteFileData = async function () {
    if (!shownFile.file_id) {
      return
    }
    const url = filesUrl + "file?id=" + shownFile.file_id;
    await axios.delete(url);
    setModalShown(false);
    try {
      await fetchClientFileUpload(dataState, dataDispatch);
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getFileData().then(undefined);
  }, [shownFile]); // eslint-disable-line react-hooks/exhaustive-deps

  let classes = useStyles();

  return (
    <Modal
      className={classes.modalXl}
      dialogClassName={classes.modalXlDialog}
      show={modalShown}
      onHide={() => setModalShown(false)}
    >
      <Modal.Header
        className={classes.modalHeader}
        closeButton
      >
        <Modal.Title>
          {shownFile.user_filename || ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Header
        className={classes.modalHeader}
      >
        <Button
          color="secondary"
          size="small"
          className="px-2"
          variant="contained"
          onClick={deleteFileData}
          startIcon={<DeleteIcon />}
        >
          {strings.delete}
        </Button>
      </Modal.Header>
      <Modal.Body
        className={classes.modalBody}
      >
        {fileType === 'video' ?
            <video
              width="600"
              height="320"
              controls=""
              autoPlay
            >
              <source src={tmpUrl}/>
            </video>
            :
            <img
              width="600"
              height="320"
              alt={"Uploaded file"}
              src={tmpUrl}
            />
        }
      </Modal.Body>
    </Modal>
  );
}
