import React, {useCallback, useEffect, useState} from "react";
import {
  Button, Checkbox,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from 'react-bootstrap/Modal';

import axios from "axios";

// styles
import useStyles from "../users/styles";

// components
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import AddUserModal from "./components/AddUserModal";
import ModifyUserModal from "./components/ModifyUserModal";
import SelectDropdown from "../../components/SelectDropdown";
import DataStateRefresh from "../../components/DataStateRefresh";

import {useDataState} from "../../context/DataContext";

import {mgmtUrl} from "../../meta";
import strings from "../../strings";


export default function Users() {

  // global
  let classes = useStyles();

  let dataState = useDataState();

  const [siteDropdownOpen, setSiteDropdownOpen] = useState(false);

  const [siteId, setSiteId] = useState("all");
  const [availableSites, setAvailableSites] = useState([]);

  const [systemUsers, setSystemUsers] = useState([]);
  const [siteUsers, setSiteUsers] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(0);  // 0: add, 1: modify

  const [clickedRow, setClickedRow] = useState(undefined);

  const permissionsMapping = {
    50: strings.regularUser,
    100: strings.adminUser,
    200: strings.superadminUser,
  };

  const systemUsersKeys = [
    {name: 'username', label: strings.username},
    {name: 'email', label: strings.email},
    {name: 'name', label: strings.name},
    {name: 'permissionsStr', label: strings.securityLevel},
  ];


  const siteUsersKeys = [
    {name: 'username', label: strings.username},
    {name: 'email', label: strings.email},
    {name: 'name', label: strings.name},
    {name: 'permissionsStr', label: strings.securityLevel},
    {name: 'alert_flag', label: strings.alerts, options: {
      customBodyRender: (value) => (
          <Checkbox checked={value}/>
        )
      }
    },
  ];


  const convertUserPermission = useCallback((data) => {
    data.forEach((dataRow) => dataRow.permissionsStr = permissionsMapping[dataRow.security_level]);
    return data;
  }, [permissionsMapping]);

  const fetchSiteUsers = useCallback(async (site_id_) => {
    const requestSiteId = site_id_ || siteId;
    const { data } = await axios(`${mgmtUrl}users?site_id=${requestSiteId}`);
    setSiteUsers(convertUserPermission(data));
  }, [siteId]);  // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserList = async() => {
    const { data } = await axios(`${mgmtUrl}users`);
    setSystemUsers(convertUserPermission(data));
  };

  useEffect(() => {
    fetchUserList().then(undefined);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAvailableSites(
      Object.keys(dataState.sites)
        .filter((x) => !x.startsWith('__'))
        .sort()
        .map((site_id) => dataState.sites[site_id])
    );
  }, [dataState.sites]);  // eslint-disable-line react-hooks/exhaustive-deps

  const onUserSelect = (_, selectedUsers_) => {
    setSelectedUsers(selectedUsers_);
  };

  const sendDeleteUsers = async() => {
    let usersTable = siteId === "all" ? systemUsers : siteUsers;
    let postData = {
      site_id: siteId,
      del_users: selectedUsers.map((userIndex) => usersTable[userIndex].username)
    };
    axios.post(mgmtUrl + "delete_users", postData)
      .then((response) => {
        setSelectedUsers([]);
        if (siteId === "all") {
          setSystemUsers(convertUserPermission(response.data));
        } else {
          setSiteUsers(convertUserPermission(response.data));
        }
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        }
      });
  };

  const sendRemoveFromSites = async() => {
    let usersTable = siteId === "all" ? systemUsers : siteUsers;
    let postData = {
      site_id: siteId,
      del_users: selectedUsers.map((userIndex) => usersTable[userIndex].username)
    };
    axios.post(mgmtUrl + "remove_site_users", postData)
      .then((response) => {
        setSelectedUsers([]);
        if (siteId === "all") {
          setSystemUsers(convertUserPermission(response.data));
        } else {
          setSiteUsers(convertUserPermission(response.data));
        }
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        }
      });
  };

  const onAddUser = (response) => {
    setModalShow(false);
    setSiteUsers(convertUserPermission(response.data));
  };

  const onPasswordReset = () => {
    setModalShow(false);
  };

  const onClickUserRow = (userRow) => {
    setClickedRow(userRow);
    setModalType(1);
    setModalShow(true);
  };

  return (
    <>
      <DataStateRefresh
        siteRefresh={true}
      />

      <PageTitle title={strings.users} />

      <Typography>{strings.userSettingsText}</Typography>
      <SelectDropdown
        labelId="user-site-open-select-label"
        id="user-site-open-select"
        open={siteDropdownOpen}
        onClose={() => setSiteDropdownOpen(false)}
        onOpen={() => setSiteDropdownOpen(true)}
        value={siteId}
        onChange={(e) => {
          const _siteId = e.target.value;
          setSiteId(_siteId);
          setSelectedUsers([]);
          if (_siteId === "all") {
            fetchUserList().then(undefined);
          } else {
            fetchSiteUsers(_siteId).then(undefined);
          }
          setSiteDropdownOpen(false);
        }}
      >
        <MenuItem value="all">{ strings.wholeSystem }</MenuItem>
        {
          availableSites.map((site) => (
            <MenuItem key={site.site_id} value={site.site_id}>{ site.site_name }</MenuItem>
          ))
        }
      </SelectDropdown>

      <div
        style={{float: 'right'}}
      >
        <Button
          color="primary"
          size="small"
          className="px-2 mr-2"
          variant="contained"
          onClick={() => {
            setModalType(0);
            setModalShow(true);
          }}
        >
          {strings.add}
        </Button>
        <Button
          color="secondary"
          disabled={selectedUsers.length === 0 || siteId === "all"}
          size="small"
          className="px-2 mr-2"
          variant="contained"
          onClick={sendRemoveFromSites}
          startIcon={<DeleteIcon />}
        >
          {strings.removeFromSite}
        </Button>
        <Button
          color="secondary"
          disabled={selectedUsers.length === 0}
          size="small"
          className="px-2"
          variant="contained"
          onClick={sendDeleteUsers}
          startIcon={<DeleteIcon />}
        >
          {strings.delete}
        </Button>
      </div>
      {
        siteId === "all" ?
          <Table
            data={systemUsers}
            keys={systemUsersKeys}
            staticKeys={true}
            rowsSelected={selectedUsers}
            selectableRows={"multiple"}
            onRowsSelect={onUserSelect}
            handler={onClickUserRow}
            disableToolbarSelect={true}
            key="systemUsersData"
          />
        :
          <Table
            data={siteUsers}
            keys={siteUsersKeys}
            staticKeys={true}
            rowsSelected={selectedUsers}
            selectableRows={"multiple"}
            onRowsSelect={onUserSelect}
            handler={onClickUserRow}
            disableToolbarSelect={true}
            key="siteUsersData"
          />
      }

      <Modal
        className={classes.modalXl}
        dialogClassName={classes.modalXlDialog}
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        {modalType === 0 ?
          <AddUserModal
            onAddUser={onAddUser}
            siteId={siteId}
            availableSites={availableSites}
          />
          :
          <ModifyUserModal
            userRow={clickedRow}
            onModifyUser={onAddUser}
            onPasswordReset={onPasswordReset}
            siteId={siteId}
            availableSites={availableSites}
          />
        }
      </Modal>
    </>
  );
}
