import React, {useState} from "react";
import { Typography, Button, TextField} from "@material-ui/core";
import Alert from 'react-bootstrap/Alert';
import axios from "axios";

// styles
import useStyles from "./styles";
import PageTitle from "../../components/PageTitle";
import strings from "../../strings";
import {airUrl} from "../../meta";
import {useLocationState} from "../../context/LocationContext";


export default function Profile() {

  const classes = useStyles();

  const locationState = useLocationState();

  const [email, setEmail] = useState();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [confirmDisabled, setConfirmDisabled] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const sendChangePassword = async() => {
    setSuccessMessage("");
    setErrorMessage("");
    if (password.length === 0 || newPassword.length === 0) {
      setErrorMessage(strings.newPasswordEmpty);
      return;
    }
    if (newPassword !== newPasswordConfirm) {
      setErrorMessage(strings.newPasswordDoNotMatch);
      return;
    }
    let postData = {
      password: password,
      newPassword: newPassword,
    };
    setConfirmDisabled(true);
    axios.post(airUrl + "change_password", postData)
      .then(() => {
        setPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
        setSuccessMessage(strings.profileUpdated);
      })
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage(strings.unexpectedError);
        }
      })
      .finally(() => setConfirmDisabled(false));
  };

  return (
    <>
      <Alert
        variant={errorMessage ? "danger" : "success"}
        show={errorMessage || successMessage}
      >
        {errorMessage ? errorMessage : successMessage}
      </Alert>

      <PageTitle title={strings.profile} />

      <div className={classes.formDividerContainer} />

      <Typography>{ strings.email }</Typography>
      <TextField
        id="email"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        disabled={true}
        value={email || locationState.userInfo.email}
        onChange={e => {
          setEmail(e.target.value)
        }}
        margin="normal"
        placeholder={strings.email}
        fullWidth
      />

      <div className={classes.formDividerContainer} />

      <Typography>{ strings.password }</Typography>
      <TextField
        id="password"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={password}
        onChange={e => setPassword(e.target.value)}
        margin="normal"
        placeholder={strings.password}
        type="password"
        fullWidth
      />

      <TextField
        id="newPassword"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        margin="normal"
        placeholder={strings.newPassword}
        type="password"
        fullWidth
      />

      <TextField
        id="newPasswordConfirm"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={newPasswordConfirm}
        onChange={e => setNewPasswordConfirm(e.target.value)}
        margin="normal"
        placeholder={strings.newPasswordConfirm}
        type="password"
        fullWidth
      />

      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={confirmDisabled}
        className={classes.backButton}
        onClick={sendChangePassword}
      >
        {strings.save}
      </Button>
    </>
  );
}
