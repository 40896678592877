import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Avatar} from "@material-ui/core";

// styles
import '../../../themes/bootstrap.min.css';

import Co2Icon from '../../../icons/co2.png';
import PressureIcon from '../../../icons/pressureIcon.png';

import Co2Graph from "../../../components/Co2Graph";
import PressureDiffGraph from "../../../components/PressureDiffGraph/PressureDiffGraph";

import useStyles from "../styles";
import {useDataState} from "../../../context/DataContext";


export default function LatestModal({ modalInfo, setModalInfo }) {

  const dataState = useDataState();

  const closeModal = function () {
    setModalInfo({
      shown: false,
      hostInfo: {},
      mac: '',
      measure: '',
    });
  };

  let classes = useStyles();

  const dataPanel = function () {
    if (modalInfo.measure === 'co2') {
      return (
        <Co2Graph
          mac={modalInfo.mac}
          height={350}
        />
      );
    }
    if (modalInfo.measure === 'pressureDiff') {
      return (
        <PressureDiffGraph
          mac={modalInfo.mac}
          is_positive={(modalInfo.hostInfo || {is_pos: false}).is_pos}
        />
      );
    }
  }


  return (
    <Modal
      className={classes.modalXl}
      dialogClassName={classes.modalXlDialog}
      show={modalInfo.shown}
      onHide={closeModal}
    >
      <Modal.Header
        className={classes.modalHeader}
        closeButton
      >
        <Modal.Title>
          {modalInfo.mac ? (dataState.hosts[modalInfo.mac] || {}).display_name : undefined}
        </Modal.Title>
        <Avatar
          src={Co2Icon}
          className={modalInfo.measure === 'co2' ? classes.modalButtonSelected : classes.modalButton}
          onClick={() => setModalInfo({...modalInfo, measure: 'co2'})}
        />
        <Avatar
          src={PressureIcon}
          className={modalInfo.measure === 'pressureDiff' ? classes.modalButtonSelected : classes.modalButton}
          onClick={() => setModalInfo({...modalInfo, measure: 'pressureDiff'})}
        />
      </Modal.Header>


      <Modal.Body
        className={classes.modalBody}
      >
        {/* Graph for one device */}
        {dataPanel(modalInfo.mac)}
      </Modal.Body>
    </Modal>
  );
}
