import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import InfoSend from "../../components/InfoSend";

// pages
import Dashboard from "../../pages/dashboard";
import Map from "../../pages/map";
import Report from "../../pages/report";

import IoTMap from "../../pages/iot_map";
import IotDataLatest from "../../pages/iot_data_latest";
import Co2DataHistory from "../../pages/co2_data_history";
import PressureDataHistory from "../../pages/pressure_data_history";
import O2DataHistory from "../../pages/o2_data_history";
import DataAnalysis from "../../pages/data_analysis";

import SensorCalibration from "../../pages/sensor_calibration";
import LocationSettings from "../../pages/location_settings";
import CustomFileRotate from "../../pages/custom_file_rotate";

import Users from "../../pages/users";
import Profile from "../../pages/profile";
import Settings from "../../pages/settings";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useLanguageState } from "../../context/LanguageContext";

import {routes} from "../../routes";

function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const languageState = useLanguageState();

  return (
    <div className={classes.root}>
      <>
        <InfoSend/>
        <Header history={props.history} />
        <Sidebar />
        <div
          key={languageState}
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path={routes.airDashboard} component={Dashboard} />
            <Route path={routes.map} component={Map} />
            <Route path={routes.report} component={Report} />

            <Route path={routes.iotMap} component={IoTMap} />
            <Route path={routes.iotDataLatest} component={IotDataLatest} />
            <Route path={routes.co2DataHistory} component={Co2DataHistory} />
            <Route path={routes.pressureDataHistory} component={PressureDataHistory} />
            <Route path={routes.o2DataHistory} component={O2DataHistory} />

            <Route path={routes.dataAnalysis} component={DataAnalysis} />

            <Route path={routes.locationSettings} component={LocationSettings} />
            <Route path={routes.calibration} component={SensorCalibration} />

            <Route path={routes.users} component={Users} />
            <Route path={routes.profile} component={Profile} />

            <Route path={routes.customFileRotate} component={CustomFileRotate}/>

            <Route path={routes.settings} component={Settings} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
