import React from "react";
import Modal from 'react-bootstrap/Modal';
import {Avatar} from "@material-ui/core";

// styles
import '../../../themes/bootstrap.min.css';

import Co2Icon from "../../../icons/co2.png";
import PressureIcon from "../../../icons/pressureIcon.png";

import PressureDiffGraph from "../../../components/PressureDiffGraph/PressureDiffGraph";
import RoomPressureGraph from "../../../components/RoomPressureGraph";
import Co2Graph from "../../../components/Co2Graph";

import useStyles from "../styles";


export default function MapModal({ modalContent,
                                   modalInfo, setModalInfo,
                                   selectionInfo }) {

  const classes = useStyles();

  const dataPanel = function () {
    if (modalInfo.measure === 'co2') {
      return (
        <Co2Graph
          mac={modalInfo.mac}
          height={650}
        />
      );
    }
    if (modalInfo.measure === 'pressureDiff') {
      return (
        <PressureDiffGraph
          mac={modalInfo.mac}
          is_positive={(modalInfo.hostInfo || {is_pos: false}).is_pos}
          height={650}
        />
      );
    }
  }

  const sensorModal = function () {
    return (
      <>
        <Modal.Header
          className={classes.modalHeader}
          closeButton
        >
          <Modal.Title>
            {modalInfo.hostInfo.display_name}
          </Modal.Title>
          <Avatar
            src={Co2Icon}
            className={modalInfo.measure === 'co2' ? classes.modalButtonSelected : classes.modalButton}
            onClick={() => setModalInfo({...modalInfo, measure: 'co2'})}
          />
          <Avatar
            src={PressureIcon}
            className={modalInfo.measure === 'pressureDiff' ? classes.modalButtonSelected : classes.modalButton}
            onClick={() => setModalInfo({...modalInfo, measure: 'pressureDiff'})}
          />
        </Modal.Header>
        <Modal.Body
          className={classes.modalBody}
        >
          {dataPanel(modalInfo.mac)}
        </Modal.Body>
      </>
    );
  };

  const roomModal = function () {
    return (
      <>
        <Modal.Header
          className={classes.modalHeader}
          closeButton
        >
          <Modal.Title>
            {selectionInfo.siteName + " - " + selectionInfo.siteLocationName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={classes.modalBody}
        >
          <RoomPressureGraph
            roomId={selectionInfo.siteLocationId}
            smallDots={true}
          />
        </Modal.Body>
      </>
    );
  };



  return (
    <Modal
      className={classes.modalXl}
      dialogClassName={classes.modalXlDialog}
      show={modalInfo.shown}
      onHide={() => setModalInfo({...modalInfo, hostInfo: {}, mac: '', shown: false})}
    >
      { modalContent === 0 ? sensorModal() : roomModal() }
    </Modal>
  );
}
