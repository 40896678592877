import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  FormControl, Grid, InputLabel, MenuItem, Input, Checkbox, FormControlLabel, TextField
} from "@material-ui/core";

import SiteSelect from "./components/SiteSelect";
import SelectDropdown from "../../components/SelectDropdown";
import DataStateRefresh, {fetchLatestMeData} from "../../components/DataStateRefresh";
import {fetchHostsData} from "../../components/DataStateRefresh";
import {Button} from "../../components/Wrappers";
import PageTitle from "../../components/PageTitle";
import SensorCalibrationSettings from "./components/SensorCalibrationSettings";

import {useLocationState} from "../../context/LocationContext";
import {useDataDispatch, useDataState} from "../../context/DataContext";

import useStyles from "./styles";
import {iotUrl} from "../../meta";
import strings from "../../strings";
import {device_names, ME_DEVICES} from "../../globals";
import {deviceSort} from "../../globalFunctions";


export default function SensorCalibration() {

  let classes = useStyles();

  const [selectedMac, setSelectedMac] = useState('');
  const [selectedHostData, setSelectedHostData] = useState(undefined);

  const [hostDropdownOpen, setHostDropdownOpen] = useState(false);

  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const locationState = useLocationState();

  const [sendDisabled, setSendDisabled] = useState(false);

  useEffect(() => {
    fetchLatestMeData(dataState, dataDispatch).finally(undefined)
  }, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
      setSelectedHostData(dataState.hosts[selectedMac])
    },[selectedMac]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataState.hosts.length === 0) {
      return
    }
    if (selectedMac !== '' && dataState.hosts[selectedMac] !== undefined) {
      return
    }
    let validMacs = Object.keys(dataState.hosts).filter((mac) => {
      return ME_DEVICES.indexOf(dataState.hosts[mac].host_type) > -1
    });
    if (validMacs.length === 0) {
      return;
    }
    let mac = validMacs[0];
    setSelectedMac(mac);
  }, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps

  const updateHostInfo = async() => {
    let postData = [selectedHostData];
    setSendDisabled(true);
    axios.post(iotUrl + "hosts/info", postData)
      .then(() => fetchHostsData(dataDispatch).then(undefined))
      .catch(function (e) {
        if (e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        } else {
          alert('Failed to update hosts info');
        }
      })
      .finally(() => setSendDisabled(false));
  };

  return (
    <>
      <DataStateRefresh
        hostRefresh={true}
        siteRefresh={true}
        siteLocationRefresh={true}
      />

      <PageTitle title={strings.sensorSettings} />

      {/* Site/Site_Location/MapSelection Menu */}
      <SelectDropdown
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
        className={"form-control " + classes.smallCol}
        labelId="calibration-host-select-label"
        id="calibration-host-select"
        open={hostDropdownOpen}
        onClose={() => setHostDropdownOpen(false)}
        onOpen={() => setHostDropdownOpen(true)}
        value={selectedMac}
        onChange={(e) => {
          setSelectedMac(e.target.value);
        }}
      >
        {
          deviceSort(Object.keys(dataState.hosts), dataState).map((hostname) => (
            ME_DEVICES.indexOf(dataState.hosts[hostname].host_type) > -1 ?
              <MenuItem
                key={ dataState.hosts[hostname].hostname }
                value={ dataState.hosts[hostname].hostname }
              >
                { dataState.hosts[hostname].display_name }
              </MenuItem>
            :
              undefined
          ))
        }
      </SelectDropdown>

      <div style={{height: "20px", width: "100%"}}/>

      {
        (selectedHostData !== undefined) ?
          <>
            {/* First row: read-only values */}
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    shrink={true}
                    htmlFor="mac"
                  >
                    MAC
                  </InputLabel>
                  <Input
                    disabled={true}
                    id="mac"
                    value={selectedHostData.mac_id}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    shrink={true}
                    htmlFor="device-type"
                  >
                    {strings.deviceType}
                  </InputLabel>
                  <Input
                    id="device-type"
                    value={device_names[selectedHostData.host_type]}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <div style={{height: "20px", width: "100%"}}/>

            {/* Second row: Name/Site/Location info */}
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="name"
                  >
                    {strings.device}
                  </InputLabel>
                  <Input
                    id="name"
                    value={selectedHostData.display_name}
                    onChange={(e) => {
                      setSelectedHostData({...selectedHostData, display_name: e.target.value})
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    shrink={true}
                    htmlFor="site-select"
                  >
                    {strings.site}
                  </InputLabel>
                  <SiteSelect
                    allOptions={dataState.sites}
                    selectedSite={selectedHostData.site_id}
                    setSelectedSite={(new_site_id) => {
                      setSelectedHostData({...selectedHostData, site_id: new_site_id})
                    }}
                    dataKey={"site_name"}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    shrink={true}
                    htmlFor="location-select"
                  >
                    {strings.location}
                  </InputLabel>
                  <SiteSelect
                    allOptions={dataState.siteLocations}
                    selectedSite={selectedHostData.site_location_id}
                    setSelectedSite={(new_site_location_id) => {
                      setSelectedHostData({...selectedHostData, site_location_id: new_site_location_id})
                    }}
                    dataKey={"location_name"}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Third row: Display order/timeout */}
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <TextField
                    type="number"
                    label={strings.displayOrder}
                    inputProps={{min: "0", max: "99", step: "1"}}
                    value={selectedHostData.order | ""}
                    onChange={(e) => {
                      setSelectedHostData({...selectedHostData, order: e.target.value})
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <TextField
                    type="number"
                    label={strings.timeoutUnit}
                    inputProps={{min: "60", max: "3600", step: "1"}}
                    value={selectedHostData.timeout}
                    onChange={(e) => {
                      setSelectedHostData({...selectedHostData, timeout: e.target.value})
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Fourth row: editable general device info */}
            {locationState.calibrationShown ?
              <Grid container spacing={2}>
                <Grid item md={3} xs={3}>
                  <FormControl fullWidth={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedHostData.is_ref}
                          onChange={(e) => {
                            setSelectedHostData({...selectedHostData, is_ref: e.target.checked})
                          }}
                          name="is_ref"
                        />
                      }
                      label={strings.referencePressureFlag}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={3}>
                  <FormControl fullWidth={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedHostData.is_pos}
                          disabled={selectedHostData.is_ref}
                          onChange={(e) => {
                            setSelectedHostData({...selectedHostData, is_pos: e.target.checked})
                          }}
                          name="is_pos"
                        />
                      }
                      label={strings.positivePressureFlag}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              :
              undefined
            }

            {/* Fourth row: general sensor info */}
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Button
                  color="success"
                  size="small"
                  className="px-2"
                  variant="contained"
                  disabled={sendDisabled}
                  onClick={updateHostInfo}
                >
                  {strings.save}
                </Button>
              </Grid>
            </Grid>

            <SensorCalibrationSettings mac={selectedMac}/>
          </>
        :
          undefined
      }
    </>
  );
}
