import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tablePaperStyle: {
    height: 'inherit',
    backgroundColor: theme.palette.background.default,
  },
  tableScrollable: {
    height: "750px",
  },
  tableTooltip: {
    maxWidth: '500px',
  },
  tableWidget: {
    backgroundColor: theme.palette.background.default,
    text: theme.palette.text.primary,
    padding: 0,
    overflowX: "auto",
  },
  tableHeading: {
    //textTransform: "uppercase",
    fontWeight: 700,
    cursor: "pointer",
    lineHeight: "1.1rem",
    textAlign: "center !important",
    display: "flex !important",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    border: "0px",
    color: theme.palette.text.primary,
  },
  tableHeadingSortIcon: {
    width: "12px",
    marginLeft: "2px",
    height: "auto",
  },
  tableBody: {
    "& td": {
      fontSize: "0.85rem",
      padding: "3px 6px 3px 6px"
    }
  },
  tableTooltipKey: {
    fontSize: "0.85rem",
    padding: "3px 6px 3px 6px"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.07)",
      cursor: "pointer"
    }
  },
  // Table Search
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.background.default, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.background.default, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    borderBottom: '1px solid grey',
    marginBottom: '10px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  numberInput: {
    padding: "8px",
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 50,
      '&:focus': {
        width: 50,
      },
    },
  },
  tableSlider: {
    width: '30%',
    display: 'inline-flex',
    float: 'right'
  },
}));
