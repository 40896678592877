import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState } from "../context/UserContext";
import {routes, startPages} from "../routes";

import {authUrl, frontendDebug} from "../meta";


axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;
if (localStorage.getItem('access_token')) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('access_token');
}
if (frontendDebug) {
  axios.defaults.crossDomain = true;
}


// Function that will be called to refresh authorization
const refreshAuthLogic = function (failedRequest) {
  delete axios.defaults.headers.common['Authorization'];
  return axios.post(authUrl + 'token/refresh')
    .then(tokenRefreshResponse => {
      let accessToken = tokenRefreshResponse.data.access_token;
      localStorage.setItem('access_token', accessToken);
      axios.defaults.headers.common['Authorization'] = 'Token ' + accessToken;
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + accessToken;
      return Promise.resolve();
    })
    .catch(() => {
      localStorage.removeItem('access_token');
      window.location.reload();
    });
}

// Use interceptor to inject the new token into requests put on hold during the token refresh
axios.interceptors.request.use(function (request) {
  let accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  return request;
});
createAuthRefreshInterceptor(axios, refreshAuthLogic, {statusCodes: [ 401, 403 ]});


export default function App() {
  // global
  const { isAuthenticated, cirrusApp } = useUserState();

  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={startPages[cirrusApp]} />}
        />
        <Route
          exact
          path={routes.app}
          render={() => <Redirect to={startPages[cirrusApp]} />}
        />
        <PrivateRoute path={routes.app} component={Layout} />
        <PublicRoute path={routes.login} component={Login} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: routes.login,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
