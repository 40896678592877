export let ME238 = 200;  // Pressure
export let ME238E = 205;  // Co2+Pressure
export let ME238EP = 206;  // Co2+Pressure+O3
export let ME238J = 208;  // Co2+Pressure+O3
export let ME138 = 209;
export let ME138E = 210;
export let ME138J = 211;
export let ME238_DEVICES = [ME238, ME238E, ME238EP, ME238J, ME138, ME138E, ME138J];
export let SLOW_ME_DEVICES = [ME138, ME138E, ME238E];

export let EVU = 203;
export let EVU5000 = 207;
export let EVU_DEVICES = [EVU, EVU5000];

export let DISPLAY = 301;
export let DISPLAY_AMS = 302;
export let DISPLAY_DEVICES = [DISPLAY, DISPLAY_AMS];
export let DISPLAY_SENSOR_DEVICES = [DISPLAY_AMS];

export let TENT = 400;
export let TENT_DEVICES = [TENT];

export let ME_DEVICES = [...ME238_DEVICES, ...EVU_DEVICES, ...DISPLAY_DEVICES, ...TENT_DEVICES];
export let AMS_DEVICES = [DISPLAY_AMS, ME238J, ME138J]
export let device_names = {};
device_names[ME238] = "ME238";
device_names[ME238E] = "ME238 E";
device_names[ME238EP] = "ME238 E+";
device_names[ME238J] = "ME238 J";
device_names[ME138] = "ME138";
device_names[EVU] = "EVU";
device_names[EVU5000] = "EVU 5000";
device_names[DISPLAY] = "Display";
device_names[DISPLAY_AMS] = "Display V2";
device_names[TENT] = "Tent";

export let ME_KEEP = 10 * 60;
export let SLOW_ME_KEEP = 3 * 60 * 60;

export let ME_DEAD = 60;

export let CO2_WARN = 600;
export let CO2_DANGER = 1000;

export let O2_WARN = 20.5;
export let O2_DANGER = 19.5;

export let PRESSURE_WARN = -2.5;
export let PRESSURE_DANGER = 0;

export let GOOD_COLOR = '#33FF99';
export let WARN_COLOR = '#FFFF00';
export let DANGER_COLOR = '#FF0000';