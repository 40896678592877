import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tableScrollable: {
    height: "750px",
  },
  muiPaper: {
    width: '500px',
  },
  tableHeading: {
    //textTransform: "uppercase",
    fontWeight: 700 + " !important",
    cursor: "pointer",
    lineHeight: "1.1rem !important",
    textAlign: "center !important",
    display: "flex !important",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    border: "0px",
    color: theme.palette.text.black + " !important",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.07)",
      cursor: "pointer"
    }
  },

  lineGraph: {
    backgroundColor: theme.palette.background.extraLight,
  },
  graphHeading: {
    fontWeight: 700,
    textAlign: "right",
    marginRight: "25px",
    marginBottom: "10px",
    fontSize: "16px"
  },
  tableWidget: {
    backgroundColor: theme.palette.background.default,
    text: theme.palette.text.primary,
    padding: 0,
    overflowX: "auto",
  },
  // TabPanel class
  tabPanel: {
    fontSize: "0.8rem"
  },
  // TableTooltip
  tracerouteTooltip: {
    textAlign: 'left',
    color: theme.palette.text.black + " !important",
  },
  customToolTip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.background.extraLight,
    whiteSpace: "nowrap",
    "& > *": {
      margin: "5px 0px",
    }
  }
}));
