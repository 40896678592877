import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import strings from "../../../../strings";

class Radio extends Component {

  radioOptions = [
    ['2h', 'last2h'],
    ['6h', 'last6h'],
    ['12h', 'last12h'],
    ['1d', 'last1d'],
    ['2d', 'last2d'],
    ['1w', 'last1w'],
    ['1m', 'last1m'],
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "2h"
    };
  }

  handleOptionChange = (changeEvent) => {
    this.props.onChange(changeEvent.target.value);
    this.setState({
      selectedOption: changeEvent.target.value
    });
  };

  render() {
    return (
      <div
        style={{margin: '-1rem -1rem -1rem auto'}}>
        <span
          className="btn-group btn-group-toggle"
          data-toggle="buttons">
          {
            this.radioOptions.map((radioOption) =>
              <label
                key={radioOption[0]}
                className={this.state.selectedOption===radioOption[0] ?
                    "btn btn-outline-secondary btn-sm active" :
                    "btn btn-outline-secondary btn-sm"}>
                <Input
                  onChange={this.handleOptionChange}
                  type="radio"
                  name="speedtest_range"
                  value={radioOption[0]}
                  autoComplete="off"
                  checked="checked"/>
                {strings[radioOption[1]] || radioOption[1]}
              </label>
            )
          }
        </span>
      </div>
    )
  }

  // render() {
  //   return (
  //     <FormControl
  //       component="fieldset">
  //       <RadioGroup
  //         style={{display:"inline-block"}}
  //         defaultValue={this.state.selectedOption}
  //         aria-label="date-radio"
  //         name="date-radio">
  //         {
  //           this.radioOptions.map((radioOption) =>
  //             <FormControlLabel
  //               style={{display:"inline-block", width: "auto"}}
  //               value={radioOption[0]}
  //               label={radioOption[1]}
  //               control={<Button/>}
  //             />
  //           )
  //         }
  //       </RadioGroup>
  //     </FormControl>
  //   )
  // }
}

export default Radio;