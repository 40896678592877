import React from "react";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

// styles
import useStyles from "./styles";

// components

// customized hook

import strings from "../../strings";
import {
  minSoftBoundAutofix,
  maxSoftBoundAutofix,
  forceCellDecimal,
} from "../../globalFunctions";
import {useDataState} from "../../context/DataContext";


export default function PressureDiffGraph({ mac,
                                            height=650,
                                            smallDots,
                                            is_positive=false,
                                            withTemperature=true,
                                            withLegend=true,
                                            overwriteData=undefined, }) {
  // global
  let classes = useStyles();

  const dataState = useDataState();

  const posFactor = is_positive ? -1 : 1;

  let referenceLines = [
    [posFactor * dataState.thresholds.pressureDiffWarn, "yellow"],
    [posFactor * dataState.thresholds.pressureDiffDanger, "red"]
  ];
  let graphDomain = is_positive ? [
    minVal => minSoftBoundAutofix(minVal, -2, 10),
    maxVal => maxSoftBoundAutofix(maxVal, 10, 2),
  ] : [
    minVal => minSoftBoundAutofix(minVal, -10, 10),
    maxVal => maxSoftBoundAutofix(maxVal, 2, 2),
  ];

  let theme = useTheme();

  // static values
  const tooltipKeys = {
    temperature: [strings.temperature, 'C', x => forceCellDecimal(x)],
    pressure_diff: [strings.pressureDiff, 'Pa', x => forceCellDecimal(x)],
  };

  // graph threshold line colors
  const thresholdColors = theme.graphs;

  // Custom toolip on Graph
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0]) {
      return (
        <div className={classes.customToolTip}>
          <p className="label">{`${label}`}</p>
          {
            Object.keys(payload[0].payload)
              .filter(key => tooltipKeys[key] !== undefined)
              .map(key => (
                payload[0].payload[key] !== null ?
                  <p
                    key={key}
                    className="label"
                    style={{color: thresholdColors[key]}}>
                    {`${tooltipKeys[key][0]} : ${tooltipKeys[key][2](payload[0].payload[key])}${tooltipKeys[key][1]}`}
                  </p>
                :
                  undefined
              ))
          }
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={height}
    >
      <LineChart
        width={500}
        height={height}
        data={mac ? (overwriteData || dataState.meGraphByHost)[mac] || [] : []}
      >
        <CartesianGrid strokeDasharray="3 3 3 3" />
        <XAxis dataKey="time" />
        <Tooltip content={<CustomTooltip />} key="sensorGraphCustomTooltip"/> :
        <YAxis
          yAxisId="left"
          orientation='left'
          unit="Pa"
          domain={graphDomain}
          tickFormatter={(tick) => tick}
        />
        <YAxis
          yAxisId="right"
          orientation='right'
          unit=""
          domain={[0, 100]}
        />
        {withLegend ?
          <Legend />
          :
          undefined
        }
        {referenceLines.map((referenceLine, idx) => (
          <ReferenceLine
            key={"pressure-ddiff-ref-" + idx}
            yAxisId={"left"}
            y={referenceLine[0]}
            stroke={referenceLine[1]}
            strokeWidth={2}
            label={{
              position: 'insideBottomRight',
              value: referenceLine[0],
              fill: referenceLine[1],
              fontSize: 14
            }}
          />
        ))
        }
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="pressure_diff"
          name={strings.pressureDiff}
          connectNulls={true}
          stroke={thresholdColors.pressure}
          activeDot={{ r: 8 }}
          dot={{r: smallDots ? 1 : 3}}
        />
        {withTemperature ?
          <Line
            yAxisId="right"
            type="monotone"
            name={strings.temperature}
            dataKey="temperature"
            connectNulls={true}
            stroke={thresholdColors.temperature}
            activeDot={{ r: 8 }}
            dot={{r: smallDots ? 1 : 3}}
          />
          :
          undefined
        }
      </LineChart>
    </ResponsiveContainer>
  );
}
