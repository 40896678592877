import React from "react";
import axios from "axios";


// styles

// components

// customized hook

import {
  useDataDispatch,
  updateHosts,
  updateSites,
  updateSiteLocations,
  updateSiteLocationMaps,
  useDataState,
  updateMeLatestByHost,
  updateMeGraphByHost,
  updateThresholds,
  updateUserFiles,
} from "../../context/DataContext";

import {iotUrl, filesUrl} from "../../meta";
import {useInterval, now, addTimeInfo, mimeTypes} from "../../globalFunctions";
import {
  ME_DEVICES,
  ME_KEEP,
  SLOW_ME_DEVICES,
  SLOW_ME_KEEP
} from "../../globals";


export async function fetchHostsData (dataDispatch) {
  const { data } = await axios(iotUrl + `hosts/info`);
  const hostDict = {};
  data.forEach(function (hostData) {
    hostDict[hostData.hostname] = hostData;
  });
  hostDict.__t = now();
  updateHosts(dataDispatch, hostDict);
  return hostDict;
}


export async function fetchSitesInfo (dataDispatch) {
  const { data } = await axios(iotUrl + `sites/info`);
  const sitesDict = {};
  data.forEach(function (siteData) {
    sitesDict[siteData.site_id] = siteData;
  });
  sitesDict.__t = now();
  updateSites(dataDispatch, sitesDict);
  return sitesDict;
}


export async function fetchSiteLocationsInfo (dataDispatch) {
  const { data } = await axios(iotUrl + `sites/locations/info`);
  const siteLocationsDict = {};
  data.forEach(function (siteLocationsData) {
    siteLocationsDict[siteLocationsData.location_id] = siteLocationsData;
  });
  siteLocationsDict.__t = now();
  updateSiteLocations(dataDispatch, siteLocationsDict);
  return siteLocationsDict;
}


export async function fetchSiteLocationsMapInfo (dataDispatch) {
  const { data } = await axios(iotUrl + `sites/locations/map/info`);
  const locationsMapsDict = {};
  data.forEach(function (locationsMapData) {
    if (locationsMapsDict[locationsMapData.location_id] === undefined) {
      locationsMapsDict[locationsMapData.location_id] = [];
    }
    locationsMapsDict[locationsMapData.location_id].push(locationsMapData);
  });
  locationsMapsDict.__t = now();
  updateSiteLocationMaps(dataDispatch, locationsMapsDict);
  return locationsMapsDict;
}


export async function fetchThresholds (dataState, dataDispatch) {
  const { data } = await axios(iotUrl + `thresholds/info`);
  const thresholdsDict = {
    __t: now(),
  };
  const keys = [
    'co2Warn', 'co2Danger',
    'pressureDiffWarn', 'pressureDiffDanger',
    'o2Warn', 'o2Danger'
  ];
  keys.forEach((x) => {
    thresholdsDict[x] = data[x] !== undefined ? data[x] : dataState.thresholds[x];
  })
  updateThresholds(dataDispatch, thresholdsDict);
  return thresholdsDict;
}


export async function fetchClientFileUpload (dataState, dataDispatch) {
  const { data } = await axios(filesUrl + `list`);
  data.forEach(function (rowData) {
    rowData.extension = rowData.user_filename.split('.').pop().toLowerCase();
    rowData.mime = mimeTypes[rowData.extension] || mimeTypes.png;
  });
  updateUserFiles(dataDispatch, {...dataState.userFiles, __t: now(), data: data});
  return data;
}


export async function fetchLatestMeData (dataState, dataDispatch) {
  let host_types = Object.keys(dataState.hosts).map((x) => dataState.hosts[x].host_type);
  if (host_types.filter((x) => ME_DEVICES.indexOf(x) > -1).length === 0) {
    return
  }
  const { data } = await axios(`${iotUrl}data/latest`);
  const meLatestDict = {...dataState.meLatestByHost};
  data.forEach(function (meLatestData) {
    meLatestDict[meLatestData.mac_id] = meLatestData;
  });
  updateMeLatestByHost(dataDispatch, meLatestDict)
}


export async function fetchMeGraphData (dataState, dataDispatch, locationState) {
  let host_types = Object.keys(dataState.hosts).map((x) => dataState.hosts[x].host_type);
  if (host_types.filter((x) => ME_DEVICES.indexOf(x) > -1).length === 0) {
    return
  }
  let url =  `${iotUrl}data/historical`;
  let latestTime = dataState.meGraphByHost.__t;
  if (latestTime !== undefined) {
    url += `?t0=${latestTime+0.001}`;
  }

  const { data } = await axios(url);

  let meGraphByHost = dataState.meGraphByHost;
  Object.keys(data).forEach(function (mac_id) {
    const hostType = (dataState.hosts[mac_id] || {}).host_type;
    const dataKeep = SLOW_ME_DEVICES.indexOf(hostType) > -1 ? SLOW_ME_KEEP : ME_KEEP;
    addTimeInfo(data[mac_id], locationState.timezone);
    let appendData = data[mac_id];
    latestTime = latestTime || 0;
    meGraphByHost[mac_id] = [
      ...(meGraphByHost[mac_id] || []).filter((x) => latestTime-dataKeep < x.t && x.t < latestTime),
      ...(appendData || []).filter((x) => x.t >= latestTime)
    ];
  });
  meGraphByHost.__t = now();
  updateMeGraphByHost(dataDispatch, meGraphByHost);
}


function DataStateRefresh({ refreshInterval=300,
                            hostRefresh=false,
                            siteRefresh=false,
                            siteLocationRefresh=false,
                            siteLocationMapRefresh=false,
                            clientFileUploadRefresh=false,
                            thresholdsRefresh=false,
                          }) {

  // global
  // static values
  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const checkRefresh= function (obj, interval) {
    return obj.__t === undefined || obj.__t + interval < now();
  }

  useInterval(function () {
    if (hostRefresh && checkRefresh(dataState.hosts, refreshInterval)) {
      fetchHostsData(dataDispatch).then(undefined);
    }
    if (siteRefresh && checkRefresh(dataState.sites, refreshInterval)) {
      fetchSitesInfo(dataDispatch).then(undefined);
    }
    if (siteLocationRefresh && checkRefresh(dataState.siteLocations, refreshInterval)) {
      fetchSiteLocationsInfo(dataDispatch).then(undefined);
    }
    if (siteLocationMapRefresh && checkRefresh(dataState.siteLocationMaps, refreshInterval)) {
      fetchSiteLocationsMapInfo(dataDispatch).then(undefined);
    }

    if (clientFileUploadRefresh && checkRefresh(dataState.userFiles, refreshInterval)) {
      fetchClientFileUpload(dataState, dataDispatch).then(undefined);
    }

    if (thresholdsRefresh && checkRefresh(dataState.thresholds, refreshInterval)) {
      fetchThresholds(dataState, dataDispatch).then(undefined);
    }

  }, 60000);

  return <></>;
}
export default DataStateRefresh;