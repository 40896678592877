import React, {useState} from "react";
import {MenuItem} from "@material-ui/core";

import SelectDropdown from "../../../components/SelectDropdown";


export default function SiteSelect({allOptions, selectedSite, setSelectedSite, dataKey}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <SelectDropdown
      labelId="site-select-label"
      id="site-select"
      fullWidth={true}
      open={dropdownOpen}
      onClose={() => setDropdownOpen(false)}
      onOpen={() => setDropdownOpen(true)}
      value={selectedSite}
      onChange={(e) => {
        setSelectedSite(e.target.value);
      }}
    >
      {
        Object.keys(allOptions).sort().map((site_id) => (
          <MenuItem
              key={ site_id }
              value={ site_id }
            >
              { allOptions[site_id][dataKey] }
          </MenuItem>
        ))
      }
    </SelectDropdown>

  );
}
