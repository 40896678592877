import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  modal: {
    backgroundColor: theme.palette.background.default,
    top: "10%",
    left: "15%",
  },
  modalXl: {
    marginTop: "5%",
    marginLeft: "17%",
    height: "90%",
    width: "75%",
    maxWidth: "1600px"
  },
  modalXlDialog: {
    height: "90%",
    width: "100%",
    maxWidth: "100%" // Overwrite the bootstrap default max size
  },
  modalHeader: {
    backgroundColor: theme.palette.background.default,
  },
  modalBody: {
    backgroundColor: theme.palette.background.default,
  },
  formDividerContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
