import React, {useState, useEffect} from "react";
import {Grid} from "@material-ui/core";
import axios from "axios";


// styles

// components

// customized hook

import {
  useDataState,
  useDataDispatch,
  updateMeGraphByHost,
} from "../../context/DataContext";
import {
  useLocationState,
  useLocationDispatch,
  updateLatestDataLayout,
  LATEST_DATA_LAYOUT_PANEL,
  LATEST_DATA_LAYOUT_TABLE
} from "../../context/LocationContext";

import DataStateRefresh, {
  fetchLatestMeData
} from "../../components/DataStateRefresh";
import PageTitle from "../../components/PageTitle";
import ScreenRotation from "../../components/ScreenRotation";
import DataPanel from "./components/DataPanel";
import LatestModal from "./components/LatestModal";
import LatestDataTable from "./components/LatestDataTable";

import {useInterval, getLatestTime, addTimeInfo, deviceSort, now} from "../../globalFunctions";
import {
  EVU_DEVICES,
  ME238_DEVICES,
  TENT_DEVICES,
  DISPLAY_SENSOR_DEVICES,
  ME_KEEP,
  TENT,
  SLOW_ME_DEVICES,
  SLOW_ME_KEEP
} from "../../globals";
import {iotUrl} from "../../meta";
import strings from "../../strings";


function IotDataLatest() {

  // global
  const validDevices = [
    ...ME238_DEVICES,
    ...EVU_DEVICES,
    ...TENT_DEVICES,
    ...DISPLAY_SENSOR_DEVICES
  ];

  // static values
  const dataState = useDataState();
  const dataDispatch = useDataDispatch();
  const locationState = useLocationState();
  const locationDispatch = useLocationDispatch();

  const [modalInfo, setModalInfo] = useState({
    shown: false,
    hostInfo: {},
    mac: '',
    measure: '',
  });

  const [requestPending, setRequestPending] = React.useState(false);
  const [shownMacs, setShownMacs] = useState([]);


  const fetchMeGraphData = async function (mac) {
    let url = `${iotUrl}data/historical?host=${mac}`;
    const latestTime = getLatestTime(dataState.meGraphByHost[mac]);
    if (latestTime !== undefined) {
      url += `&t0=${latestTime+0.001}`;
    }

    setRequestPending(true);
    const { data } = await axios(url);
    addTimeInfo(data[mac], locationState.timezone);

    let meGraphByHost = dataState.meGraphByHost;
    if (meGraphByHost[mac] === undefined) {
      meGraphByHost[mac] = data[mac];
    } else {
      meGraphByHost[mac] = [
        ...meGraphByHost[mac],
        ...data[mac]
      ];
    }
    const hostType = (dataState.hosts[mac] || {}).host_type;
    const dataKeep = SLOW_ME_DEVICES.indexOf(hostType) > -1 ? SLOW_ME_KEEP : ME_KEEP;
    meGraphByHost[mac] = meGraphByHost[mac].filter((x) => now() < x.t + dataKeep);
    updateMeGraphByHost(dataDispatch, meGraphByHost)
  };

  const fetchGraphData = async function (macOverwrite) {
    let mac = macOverwrite || modalInfo.mac;
    if (mac === '' || requestPending) {
      return;
    }

    fetchMeGraphData(mac).then(undefined);
  };

  const filterShownMacs = function () {
    let hosts = dataState.hosts;
    let macs = Object.keys(hosts).filter((mac) => !mac.startsWith('__'));
    macs = macs
      .filter((mac) => validDevices.indexOf(hosts[mac].host_type) > -1)
      .filter((mac) => !hosts[mac].is_ref || hosts[mac].host_type === TENT)  // for O2, add tents
      .filter((mac) => hosts[mac].site_location_id !== undefined || hosts[mac].site_location_id !== null)
    macs = deviceSort(macs, dataState);
    setShownMacs(macs);
  }

  const changeLayout = function () {
    if (locationState.latestDataLayout !== LATEST_DATA_LAYOUT_PANEL) {
      updateLatestDataLayout(locationDispatch, LATEST_DATA_LAYOUT_PANEL);
    } else {
      updateLatestDataLayout(locationDispatch, LATEST_DATA_LAYOUT_TABLE);
    }
  };


  //state hooks
  useEffect(() => {
    fetchLatestMeData(dataState, dataDispatch).finally(undefined)
  }, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(filterShownMacs, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(async () => {
    fetchLatestMeData(dataState, dataDispatch).finally(undefined)
  }, locationState.refresher * 1000);

  //state hooks
  useInterval(async () => {
    fetchGraphData().finally(setRequestPending(false));
  }, 30000);


  const dataPanel = function(mac) {
    let mdSize = shownMacs.length === 1 ? 12 : 6;
    let hostData = dataState.hosts[mac];
    if (hostData === undefined) return;
    let hostType = hostData.host_type;
    return (
      <Grid item md={mdSize} xs={12}>
        <DataPanel
          mac={mac}
          setModalInfo={setModalInfo}
          fetchGraphData={fetchGraphData}
          setRequestPending={setRequestPending}
          hostType={hostType}
        />
      </Grid>
    );
  }

  return (
    <>
      <ScreenRotation/>
      <DataStateRefresh
        hostRefresh={true}
        siteRefresh={true}
        siteLocationRefresh={true}
        thresholdsRefresh={true}
      />

      <PageTitle
        title={strings.iotDataLatest}
        button={
          locationState.latestDataLayout !== LATEST_DATA_LAYOUT_TABLE ?
            strings.tableFormat :
            strings.panelFormat
        }
        onButtonClick={changeLayout}
      />

      <Grid container spacing={1}>
        {
          locationState.latestDataLayout !== LATEST_DATA_LAYOUT_TABLE ?
            shownMacs.map(dataPanel)
          :
            <LatestDataTable
              setModalInfo={setModalInfo}
              fetchGraphData={fetchGraphData}
              setRequestPending={setRequestPending}
            />
        }
      </Grid>

      <LatestModal
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
      />

    </>
  );
}

export default IotDataLatest;