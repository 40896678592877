import React from "react";
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { Language as LanguageIcon } from "@material-ui/icons";

// components
import SelectDropdown from "../SelectDropdown";

// context
import {updateLanguage, useLanguageDispatch, useLanguageState} from "../../context/LanguageContext";

import strings from "../../strings";


export default function LanguageSelect() {

  // global
  const languageState = useLanguageState();
  const languageDispatch = useLanguageDispatch();
  const [languageDropdownOpen, setLanguageDropdownOpen] = React.useState(false);

  const handleLanguageDropdownClose = () => {
    setLanguageDropdownOpen(false);
  };

  const handleLanguageDropdownOpen = () => {
    setLanguageDropdownOpen(true);
  };

  return (
    <FormControl style={{width: '100%'}}>
      <InputLabel id="layout-language-open-select-label">
        <LanguageIcon/>
        {strings.language}
      </InputLabel>
      <SelectDropdown
        labelId="layout-language-open-select-label"
        id="layout-language-open-select"
        open={languageDropdownOpen}
        onClose={handleLanguageDropdownClose}
        onOpen={handleLanguageDropdownOpen}
        value={languageState.language}
        onChange={(e) => updateLanguage(languageDispatch, e.target.value)}
      >
        <MenuItem value="en"><em>English</em></MenuItem>
        <MenuItem value="jp"><em>日本語</em></MenuItem>
      </SelectDropdown>
    </FormControl>
  )
}
