import React from "react";

import useStyles from "../../styles";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Switch,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Button from 'react-bootstrap/Button';
import strings from "../../../../strings";


export default function ModalThresholds({ thresholdData, siteId, refreshThresholdData, saveThresholds }) {

  let classes = useStyles();

  let thresholdBounds = {
    rssi: [strings.rssi, -100, -30],
    download: [strings.download, 0, 100],
    upload: [strings.upload, 0, 100],
    tcp_received: [strings.tcpReceived, 0, 100],
    tcp_sent: [strings.tcpSent, 0, 100],
    udp_results: [strings.udpResults, 0, 100],
    udp_loss: [strings.udpLoss, 0, 100],
  };

  return (
    <>
      {/* Graph for one device */}
      <Table
        key={thresholdData}
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeading}>{strings.mapMeasure}</TableCell>
            <TableCell className={classes.tableHeading}>{strings.mapThresholds}</TableCell>
            <TableCell className={classes.tableHeading}>{strings.mapHidden}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {thresholdData
            .filter(threshold => threshold.site_id === siteId && thresholdBounds[threshold.name] !== undefined)
            .map(threshold => (
              <TableRow key={threshold.name}>
                <TableCell>
                  {thresholdBounds[threshold.name][0]}
                </TableCell>
                <TableCell>
                  <InputBase
                    classes={{
                      root: classes.inputRoot,
                      input: classes.numberInput,
                    }}
                    inputProps={{
                      type: 'number',
                      min: thresholdBounds[threshold.name][1],
                      max: thresholdBounds[threshold.name][2],
                      step: 1,
                      defaultValue: threshold.value,
                    }}
                    onChange={(e) => threshold.value=parseInt(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    classes={{
                      track: classes.toggleSwitchTrack
                    }}
                    checked={threshold.hide_flag === 1}
                    onChange={(e) => {
                      threshold.hide_flag = e.target.checked ? 1 : 0;
                      // Below line is to force re-render (above update does not trigger rerender)
                      refreshThresholdData(JSON.parse(JSON.stringify(thresholdData)));
                    }}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <Button
        variant={"success"}
        onClick={() => saveThresholds(siteId)}
      >
        {strings.mapSave}
      </Button>
    </>
  );
}
