import React, { useState, useEffect } from "react";
import {Avatar, Drawer, IconButton, List} from "@material-ui/core";
import {
  Home as HomeIcon,
  PermScanWifi as MapIcon,
  ArrowBack as ArrowBackIcon,
  FileCopy as FileCopyIcon,
  Room as RoomIcon,
  PersonAdd as PersonAddIcon,
  Toys as ToysIcon,
  PhonelinkSetup as PhonelinkSetupIcon,
  Settings as SettingsIcon,
  FastRewind as FastRewindIcon,
  EditLocation as EditLocationIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import {useUserState} from "../../context/UserContext";

import PressureIcon from '../../icons/pressureIcon.png';
import Co2Icon from '../../icons/co2.png';
import O2Icon from '../../icons/o2.png';
import {routes} from "../../routes";


const structure = [
  {
    id: 0,
    link: routes.airDashboard,
    icon: <HomeIcon />,
    app: 'air',
  },
  {
    id: 1,
    link: routes.map,
    icon: <MapIcon />,
    app: 'air',
  },
  // {
  //   id: 2,
  //   link: routes.report,
  //   icon: <FileCopyIcon />,
  // },
  {
    id: 4,
    link: routes.iotDataLatest,
    icon: <ToysIcon />,
    app: 'iot',
  },
  {
    id: 5,
    link: routes.co2DataHistory,
    icon: <Avatar src={Co2Icon} style={{height: 24, width: 30}}/>,
    app: 'iot',
  },
  {
    id: 6,
    link: routes.pressureDataHistory,
    icon: <Avatar src={PressureIcon} style={{height: 24, width: 24}}/>,
    app: 'iot',
  },
  {
    id: 7,
    link: routes.o2DataHistory,
    icon: <Avatar src={O2Icon} style={{height: 24, width: 30}}/>,
    app: 'iot',
  },
  {
    id: 8,
    link: routes.iotMap,
    icon: <RoomIcon />,
    app: 'iot',
  },
  {
    id: 9,
    link: routes.users,
    icon: <PersonAddIcon />,
    securityLevel: 100,
  },
  {
    id: 10,
    link: routes.calibration,
    icon: <PhonelinkSetupIcon />,
    securityLevel: 200,
  },
  {
    id: 11,
    link: routes.locationSettings,
    icon: <EditLocationIcon />,
    securityLevel: 200,
  },
  {
    id: 12,
    link: routes.settingsGeneral,
    icon: <SettingsIcon />,
    securityLevel: 200,
  },
  {
    id: 13,
    link: routes.report,
    icon: <FileCopyIcon />,
  },
  {
    id: 14,
    link: routes.dataAnalysis,
    icon: <FastRewindIcon />,
  },
];


function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userState = useUserState();

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <>
      <Drawer
        variant={isPermanent ? "permanent" : "temporary"}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isSidebarOpened,
            [classes.drawerClose]: !isSidebarOpened,
          }),
        }}
        open={isSidebarOpened}
      >
        <div className={classes.toolbar} />
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          </IconButton>
        </div>
        <List className={classes.sidebarList}>
          {structure.map(link => (
            (link.app === undefined
              || link.app === userState.cirrusApp
              || (Array.isArray(link.app) && link.app.indexOf(userState.cirrusApp) > -1)) ?
              <SidebarLink
                key={link.id}
                closeOnClick={true}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
              :
              undefined
          ))}
        </List>
      </Drawer>
    </>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
