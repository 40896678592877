import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// components

// context
import {useUserState} from "../../context/UserContext";

import {useInterval} from "../../globalFunctions";


// overrideRotate: if defined (function) and function return True: do not apply rotation!
function ScreenRotation({ location, history, overrideRotate }) {

  // global
  const userState = useUserState();

  // local
  const [lastRotationCheck, setLastRotationCheck] = useState(Date.now());

  function maybeRotateScreen () {
    if (lastRotationCheck + userState.rotationTimer * 1000 > Date.now()) {
      return
    }
    setLastRotationCheck(Date.now());
    if (userState.rotationFlag) {
      let currentAppScreens = userState.rotationEnabledUrls[userState.cirrusApp] || [];
      let currentScreenIndex = currentAppScreens.indexOf(location.pathname);
      if (overrideRotate && overrideRotate()) {
        return
      }
      if (currentScreenIndex > -1 && currentAppScreens.length > 1) {
        history.push(currentAppScreens[(currentScreenIndex + 1) % currentAppScreens.length]);
      }
    }
  }

  useInterval(maybeRotateScreen, 1000);

  return <></>;

}

export default withRouter(ScreenRotation);
