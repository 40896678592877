import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Input
} from "@material-ui/core";

import SelectDropdown from "../../components/SelectDropdown";
import DataStateRefresh, {
  fetchSiteLocationsInfo,
  fetchHostsData,
  fetchSitesInfo
} from "../../components/DataStateRefresh";
import {Button} from "../../components/Wrappers";
import PageTitle from "../../components/PageTitle";

import {useDataDispatch, useDataState} from "../../context/DataContext";

import useStyles from "./styles";
import {iotUrl} from "../../meta";
import strings from "../../strings";
import {keys} from "../../globalFunctions";
import MapRegistration from "./components/MapRegistration";


export default function LocationSettings() {

  let classes = useStyles();

  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState('');

  const [newSiteName, setNewSiteName] = useState('');
  const [newLocationName, setNewLocationName] = useState('');

  const [siteMenuOpen, setSiteMenuOpen] = useState(false);
  const [locationMenuOpen, setLocationMenuOpen] = useState(false);

  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const [sendSiteDisabled, setSendSiteDisabled] = useState(false);
  const [sendLocationDisabled, setSendLocationDisabled] = useState(false);
  const [resetPositionDisabled, setResetPositionDisabled] = useState(false);

  useEffect(function() {
    if (keys(dataState.sites).length === 0) {
      return
    }
    if (selectedSiteId !== '' && dataState.sites[selectedSiteId] !== undefined) {
      return
    }
    setSelectedSiteId(keys(dataState.sites)[0]);
  }, [dataState.sites]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    if (selectedSiteId === '' || dataState.sites[selectedSiteId] === undefined) {
      return
    }
    setNewSiteName(dataState.sites[selectedSiteId].site_name);
  }, [dataState.sites, selectedSiteId]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    if (keys(dataState.siteLocations).length === 0) {
      return
    }
    if (selectedLocationId !== '' && dataState.siteLocations[selectedLocationId] !== undefined) {
      return
    }
    setSelectedLocationId(keys(dataState.siteLocations)[0]);
  }, [dataState.siteLocations]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    if (selectedLocationId === '' || dataState.siteLocations[selectedLocationId] === undefined) {
      return
    }
    setNewLocationName(dataState.siteLocations[selectedLocationId].location_name);
  }, [dataState.siteLocations, selectedLocationId]);  // eslint-disable-line react-hooks/exhaustive-deps


  const updateSiteInfo = async() => {
    let postData = {
      siteId: selectedSiteId,
      siteName: newSiteName,
    };
    setSendSiteDisabled(true);
    axios.post(iotUrl + "sites/info", postData)
      .then(() => fetchSitesInfo(dataDispatch).then(undefined))
      .catch(function (e) {
        if (e.response && e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        } else {
          alert('Failed to update sites info');
        }
      })
      .finally(() => setSendSiteDisabled(false));
  };

  const updateLocationInfo = async() => {
    let postData = {
      siteId: selectedSiteId,
      locationId: selectedLocationId,
    };
    setSendLocationDisabled(true);
    axios.post(iotUrl + "sites/locations/info", postData)
      .then(() => fetchSiteLocationsInfo(dataDispatch).then(undefined))
      .catch(function (e) {
        if (e.response && e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        } else {
          alert('Failed to update locations info');
        }
      })
      .finally(() => setSendLocationDisabled(false));
  };

  const resetLocationDevicePositions = async() => {
    let postData = {
      siteId: selectedSiteId,
      locationId: selectedLocationId,
    };
    setResetPositionDisabled(true);
    axios.post(iotUrl + "sites/locations/placement/reset", postData)
      .then(function () {
        fetchHostsData(dataDispatch).then(undefined)
      })
      .catch(function (e) {
        if (e.response && e.response.data && e.response.data.error) {
          alert(e.response.data.error);
        } else {
          alert('Failed to reset device positions');
        }
      })
      .finally(() => setResetPositionDisabled(false));
  };

  return (
    <>
      <DataStateRefresh
        siteRefresh={true}
        siteLocationRefresh={true}
        siteLocationMapRefresh={true}
      />

      <PageTitle title={strings.locationSettings} />

      {/* Site Menu */}
      <SelectDropdown
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
        className={"form-control " + classes.smallCol}
        labelId="site-menu-select-label"
        id="site-menu-select"
        open={siteMenuOpen}
        onClose={() => setSiteMenuOpen(false)}
        onOpen={() => setSiteMenuOpen(true)}
        value={selectedSiteId}
        onChange={(e) => {
          setSelectedSiteId(e.target.value);
        }}
      >
        {
          keys(dataState.sites).map((site_id) => (
            <MenuItem
              key={site_id}
              value={site_id}
            >
              { dataState.sites[site_id].site_name }
            </MenuItem>
          ))
        }
      </SelectDropdown>

      {
        (selectedSiteId !== "") ?
          <>
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="name"
                  >
                    {strings.site}
                  </InputLabel>
                  <Input
                    id="name"
                    value={newSiteName}
                    onChange={(e) => {
                      setSendSiteDisabled()
                      setNewSiteName(e.target.value)
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} xs={3}>
                <Button
                  color="success"
                  size="small"
                  className="mt-3 px-2"
                  variant="contained"
                  disabled={sendSiteDisabled}
                  onClick={updateSiteInfo}
                >
                  {strings.save}
                </Button>
              </Grid>

            </Grid>

          </>
          :
          <></>
      }

      <br/>

      {/* Location Menu */}
      <SelectDropdown
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
        className={"form-control " + classes.smallCol}
        labelId="location-menu-select-label"
        id="location-menu-select"
        open={locationMenuOpen}
        onClose={() => setLocationMenuOpen(false)}
        onOpen={() => setLocationMenuOpen(true)}
        value={selectedLocationId}
        onChange={(e) => {
          setSelectedLocationId(e.target.value);
        }}
      >
        {
          keys(dataState.siteLocations).map((location_id) => (
            <MenuItem
              key={location_id}
              value={location_id}
            >
              { dataState.siteLocations[location_id].location_name }
            </MenuItem>
          ))
        }
      </SelectDropdown>

      {
        (selectedSiteId !== "") ?
          <>
            <Grid container spacing={2}>
              <Grid item md={3} xs={3}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="name"
                  >
                    {strings.location}
                  </InputLabel>
                  <Input
                    id="name"
                    value={newLocationName}
                    onChange={(e) => {
                      setNewLocationName(e.target.value)
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} xs={3}>
                <Button
                  color="success"
                  size="small"
                  className="mt-3 px-2"
                  variant="contained"
                  disabled={sendLocationDisabled}
                  onClick={updateLocationInfo}
                >
                  {strings.save}
                </Button>
              </Grid>

              <Grid item md={3} xs={3}>
                <Button
                  color="warning"
                  size="small"
                  className="mt-3 px-2"
                  variant="contained"
                  disabled={resetPositionDisabled}
                  onClick={resetLocationDevicePositions}
                >
                  {strings.resetDevicePositions}
                </Button>
              </Grid>

            </Grid>

            <hr/>
            <hr/>

            { /* Map registration*/}
            <MapRegistration
              siteLocationId={selectedLocationId}/>
          </>
          :
          <></>
      }

    </>
  );
}
