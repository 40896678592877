import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en:{
    // Sidebar
    airDashboard: "Air Dashboard",
    map: "Map",
    report: "Report",
    iotDashboard: "Latest Data (Table)",
    iotMap: "IoT Map",
    meOverview: "ME - Overview",
    meMain: "ME - Table",
    meGraph: "ME - Graph",
    settings: "Settings",
    sensorSettings: "Sensor Settings",
    locationSettings: "Location Settings",
    customFileRotate: "Other files Display",
    dataAnalysis: "Data Analysis",

    // Header
    language: "Language",
    refreshInterval: "Refresh Interval",
    darkTheme: "Dark Mode",
    macVendorSwitch: "Vendor",
    firstBlockSelect: "First block",
    profile: "Profile",
    tasks: "Tasks",
    messages: "Messages",
    signOut: "Sign Out",

    // Table
    search: "Search...",

    // Login
    cirrusDashboard: "Cirrus Dashboard",
    login: "Login",
    loginTarget: "Log Into",
    loginFailedMsg: "Something is wrong with your login or password",
    password: "Password",
    username: "Username",

    // Dashboard
    dashboard: "Dashboard",
    hop: "Hop",
    finders: "Finders",
    focus: "Focus",
    flows: "Flows",
    export: "Export",
    networkPerformance: "Network performance",
    applicationTests: "Application tests",
    automaticUpdate: "Automatic Update",
    graphData: "Graph Data",
    sensorData: "Sensor Data",
    roomData: "Room Data",
    bssi: "BSSI",
    accessPoints: "Access Points",
    channelAnalysis: "Channel Analysis",
    alerts: "Alerts",

    hostname: "Name",
    time: "Time",
    device: "Device",
    deviceType: "Device Type",
    site: "Site",
    alertDescription: "Description",
    channel: "Channel",
    mac: "MAC",
    totUsers: "#Users",
    accessPoint: "Access Point",
    vendor: "Vendor",
    ssid: "SSID",
    appName: "Name",
    testType: "Test Type",
    url: "URL",
    result: "Result",

    tcpSentUnit: "TCP Sent (Mbps)",
    tcpReceivedUnit: "TCP Received (Mbps)",
    udpResultsUnit: "UDP Results (Mbps)",
    udpLossUnit: "UDP Loss (%)",
    uploadUnit: "Upload (Mbps)",
    downloadUnit: "Download (Mbps)",
    pingUnit: "Ping (ms)",
    rssiUnit: "RSSI (dB)",
    retryUnit: "Retry (%)",

    tcpSent: "TCP Sent",
    tcpReceived: "TCP Received",
    udpResults: "UDP Results",
    udpLoss: "UDP Loss",
    upload: "Upload",
    download: "Download",
    ping: "Ping",
    rssi: "RSSI",
    retry: "Retry",
    udpTotStreams: "UDP #Streams",

    ap_rssi: "RSSI",
    ap_mac: "MAC",

    // Map
    mapCancel: "Cancel",
    mapYes: "Yes",
    mapSettings: "Settings",
    mapLastSeen: "Seen",
    mapSave: "Save",
    last1h: "Last 1 hour",
    last2h: "Last 2 hours",
    last6h: "Last 6 hours",
    last12h: "Last 12 hours",
    last1d: "Last day",
    last2d: "Last 2 days",
    last1w: "Last week",
    last1m: "Last month",
    mapMeasure: "Measure",
    mapThresholds: "Threshold",
    mapHidden: "Hidden",

    roomGraph: "Whole graph",

    // IoT Dashboard
    dashboardRoomSensorTitle1: "Isolation room",
    dashboardRoomSensorTitle2: "Air monitoring system",
    roomSensors: "Room Sensors",
    filterSensors: "Filters",
    location: "Location",

    building: "Building",
    room: "Room",
    temperatureUnit: "Temperature(C)",
    humidityUnit: "Humidity(%)",
    pressureUnit: "Pressure(Pa)",
    pressureDiffUnit: "Pressure diff(Pa)",
    refPressureUnit: "Reference Pressure(Pa)",
    inPressureUnit: "IN Pressure(hPa)",
    outPressureUnit: "OUT Pressure(hPa)",

    temperature: "Temperature",
    humidity: "Humidity",
    pressure: "Pressure",
    pressureDiff: "Pressure diff",
    pressureDiffNegative: "Pressure diff (negative)",
    refPressure: "Reference pressure",
    pressure1: "HEPA filter in pressure",
    pressure2: "HEPA filter out pressure",
    differentialUnit: "Diff(hPa)",
    voltageUnit: "Voltage(V)",

    status: "Status",
    statusPressureOk: "OK",
    statusPressureDanger: "Confirmation required",

    dashboardEvuTitle: "Air change monitoring",
    airChange: "Air change",

    tableFormat: "Table view",
    panelFormat: "Panel view",

    all: "All",

    // Report
    reportTitle: "Report",
    reportGenerationText: "Generate report for",
    reportStart: "Start",
    startDate: "Start date",
    endDate: "End date",


    // Users
    userSettingsText: "Manage users for site",
    usernameCharLimits: "Must be between 3-16 alphanumerical characters",
    permissions: 'Permissions',
    users: 'Users',
    removeFromSite: 'Remove from site',
    delete: 'Delete',
    add: 'Add',
    newUserSettings: 'New user settings',
    email: 'Email',
    none: 'None',
    regularUser: 'Regular user',
    adminUser: 'Admin',
    superadminUser: 'Super Admin',
    name: 'Name',
    modUserSettings: 'Modify user settings',
    confirm: 'Confirm',
    passwordReset: 'Reset Password',
    securityLevel: 'System permissions',
    wholeSystem: 'Whole system',
    isMember: 'Member?',

    // Profile
    changePassword: 'Change Password',
    newPassword: 'New Password',
    newPasswordConfirm: 'Confirm new password',
    newPasswordDoNotMatch: 'New passwords do not match',
    newPasswordEmpty: 'Password field is empty',
    send: 'Send',
    profileUpdated: 'Successfully updated profile.',
    testMail: 'Send test mail',

    // Settings
    general: "General",
    wifi: "WiFi",
    auth: "Authentication",
    dataScreen: "Data Screens",
    dataRefresh: "Data Refresh",
    registerWifi: "Registered WiFis",
    timezone: "Timezone",
    screenRotation: "Screen Rotation",
    interval: "Interval",
    enabled: "Enabled",
    disabled: "Disabled",
    evuMain: "EVU Main",
    co2DataHistory: "CO2 Data",
    pressureDataHistory: "Pressure Data",
    o2DataHistory: "O2 Data",
    iotDataLatest: "Latest Data",
    co2Graph: "CO2 Graph",
    co2: "CO2",
    airflow: "Airflow",
    airFlowGraph: "Airflow Graph",
    register: 'Register',
    update: 'Update',
    deviceName: 'Device Name',
    loginUsername: 'Login Username',
    loginPassword: 'Login Password',
    totalDevices: 'Total devices',
    save: 'Save',
    thresholds: "Thresholds",
    measure: "Measure",
    warningYellow: "Warning (yellow)",
    dangerRed: "Danger (red)",

    graphWidth: "Graph Width",

    // ME Overview
    doorStatus: "Door status",
    statusDoorClose: "Open",
    statusDoorOpen: "Close",

    // EVU Title
    fanPower: "Fan Power",
    fanMaintenance: "Fan Maintenance",
    data: "Data",

    // EVU Power
    menu: "Menu",
    auto: "Auto",
    low: "Low",
    high: "High",
    manualOverride: "Manual Override",

    // EVU Maintenance
    filterRunTime: "Filter Run Time",
    fanRunTime: "Fan Run Time",
    reset: "Reset",
    confirmResetTitle: "Confirm filter run time reset",
    confirmResetText: "This action cannot be reverted. Do not reset this value unless you are replacing the EVU filter.",
    cancel: "Cancel",

    // EVU Main
    airChangesPerHour: "Air changes per hour",
    airFlowRate: "Air flow rate",
    roomAirChangeTime: "Room Air Change Time",
    minutes: "min",
    times: "times",
    evuPressure: "Pressure",

    // Device Settings
    referencePressureDevice: "Reference pressure device: ",
    pressureDiffReset: "Reset pressure differential",
    resetSuccessfull: "Reset successfull",
    referencePressureFlag: "Static pressure?",
    positivePressureFlag: "Positive pressure?",
    displayOrder: "Display Order",
    timeoutUnit: "Timeout (s)",

    // File Upload
    fileUpload: "File upload",
    toDisplayScreen: "To Display Screen",
    fileUploadError: "An error occured when uploading file.",
    filename: "Filename",
    uploader: "Uploader",
    shown: "Shown",
    yes: "Yes",
    no: "No",
    fileUploadMaxFileReached: "Maximum amount of files reached.",
    fileUploadDragDropMessage: "Drop file here (PNG/JPG, under 10MB.",
    fileUploadFilesizeError: "File must be less than 10MB.",
    fileUploadExtensionError: "File extension must .jpg or .png.",

    fileRotateNone: "No file selected for file display（Settings screen）",

    resetDevicePositions: "Reset device positions",

    // Tent
    tent: "Tent",
    o2: "Oxygen",
    tentDataHistorical: "Past Data",

    // General
    unexpectedError: 'Unexpected error',
    shutdown: 'Shutdown',
    period: "Period",

    // Explanations (Info buttons)
    meGraphExplanation: "Pressure difference is computed with regards to isolation room pressure level",

  },
  jp: {
    // Sidebar
    airDashboard: "Airダッシュボード",
    map: "地図",
    report: "報告書作成",
    iotDashboard: "現在の状況（表形式）",
    iotMap: "設置デバイス",
    meOverview: "ME - 一覧",
    meMain: "ME - 明細表",
    meGraph: "ME - 図形",
    settings: "システム設定",
    sensorSettings: "デバイス設定",
    locationSettings: "施設設定",
    customFileRotate: "その他ファイル表示",
    dataAnalysis: "データ分析",

    // Header
    language: "言語",
    refreshInterval: "更新頻度",
    darkTheme: "ダークモード",
    macVendorSwitch: "ヴェンダー",
    firstBlockSelect: "左上ブロック",
    profile: "アカウント設定",
    tasks: "タスク",
    messages: "メッセージ",
    signOut: "サインアウト",

    // Table
    search: "検索...",

    // Login
    cirrusDashboard: "Cirrusダッシュボード",
    login: "ログイン",
    loginTarget: "サイト",
    loginFailedMsg: "ユーザ名かパスワードは正しくありません。",
    password: "パスワード",
    username: "ユーザー名",

    // Dashboard
    dashboard: "ダッシュボード",
    hop: "ホップ",
    finders: "ファインダー",
    focus: "フォーカス",
    flows: "フロー",
    export: "保存",
    networkPerformance: "ネットワークパーフォーマンス",
    applicationTests: "アプリテスト",
    automaticUpdate: "自動更新",
    graphData: "図形データ",
    sensorData: "センサー単位",
    roomData: "部屋単位",
    bssi: "BSSI",
    accessPoints: "アクセスポイント",
    channelAnalysis: "チャンネル内訳",
    alerts: "アラート通知",

    hostname: "ホスト名",
    time: "日時",
    device: "デバイス名",
    deviceType: "デバイス種類",
    site: "施設",
    alertDescription: "記載",
    channel: "チャンネル",
    mac: "MAC",
    totUsers: "ユーザー数",
    accessPoint: "アクセスポイント",
    vendor: "ヴェンダー",
    ssid: "SSID",
    appName: "テスト名",
    testType: "テスト",
    url: "URL",
    result: "結果",

    tcpSentUnit: "TCP送(Mbps)",
    tcpReceivedUnit: "TCP受(Mbps)",
    udpResultsUnit: "UDP結果(Mbps)",
    udpLossUnit: "UDP損(%)",
    uploadUnit: "アップロード (Mbps)",
    downloadUnit: "ダウンロード (Mbps)",
    pingUnit: "Ping (ms)",
    rssiUnit: "RSSI (dB)",
    retryUnit: "リトライ(%)",

    tcpSent: "TCP送",
    tcpReceived: "TCP受",
    udpResults: "UDP結果",
    udpLoss: "UDP損",
    upload: "アップロード",
    download: "ダウンロード",
    ping: "Ping",
    rssi: "RSSI",
    retry: "リトライ",
    udpTotStreams: "UDPストリーム数",

    ap_rssi: "RSSI",
    ap_mac: "MAC",

    // Map
    mapCancel: "キャンセル",
    mapYes: "はい",
    mapSettings: "設定",
    mapLastSeen: "最新データ",
    mapSave: "保存",
    last1h: "過去１時間",
    last2h: "過去２時間",
    last6h: "過去６時間",
    last12h: "過去１２時間",
    last1d: "過去１日",
    last2d: "過去２日",
    last1w: "過去１週間",
    last1m: "過去１ヶ月",
    mapMeasure: "メッジャー",
    mapThresholds: "閾値",
    mapHidden: "隠す",

    // IoT Dashboard
    dashboardRoomSensorTitle1: "感染室",
    dashboardRoomSensorTitle2: "空調管理システム",
    roomSensors: "部屋センサー",
    filterSensors: "フィルター",
    location: "設置場所",

    building: "ビル",
    room: "設置場所",
    temperatureUnit: "気温(度)",
    humidityUnit: "湿度(%)",
    pressureUnit: "気圧(パスカルPa)",
    pressureDiffUnit: "気圧差(パスカル)",
    refPressureUnit: "ベース空気圧(Pa)",
    inPressureUnit: "空気圧IN(hPa)",
    outPressureUnit: "空気圧OUT(hPa)",

    temperature: "気温",
    humidity: "湿度",
    pressure: "気圧",
    refPressure: "ベース空気圧",
    pressureDiff: "気圧差",
    pressureDiffNegative: "気圧差（陰圧）",
    pressure1: "HEPAフィルター入気圧",
    pressure2: "HEPAフィルター出気圧",
    differentialUnit: "気圧差(hPa)",
    voltageUnit: "電力(V)",

    status: "状況",
    statusPressureOk: "良好",
    statusPressureDanger: "確認必須",

    roomGraph: "データ表示",

    tableFormat: "表形式",
    panelFormat: "パネル形式",

    all: "すべて",

    // Report
    reportTitle: "報告書",
    reportGenerationText: "報告書作成対象",
    reportStart: "開始",
    startDate: "期間開始日",
    endDate: "期間終了日",

    // Users
    userSettingsText: "選択してください",
    usernameCharLimits: "3～16文字の英数字で入力してください。",
    permissions: '権限',
    users: 'ユーザー',
    removeFromSite: 'サイトから削除',
    delete: '削除',
    add: '追加',
    newUserSettings: '新規ユーザー設定',
    email: 'メールアドレス',
    none: 'なし',
    regularUser: '一般ユーザー',
    adminUser: '管理者',
    superadminUser: '特別管理者',
    name: '名前',
    modUserSettings: 'ユーザー変更設定',
    confirm: '確認',
    passwordReset: 'パスワードリセット',
    securityLevel: '権限',
    wholeSystem: 'システム全体',
    isMember: '施設に所属',


    // Profile
    changePassword: 'パスワード変更',
    newPassword: '新しいパスワード',
    newPasswordConfirm: '新しいパスワード再記入',
    newPasswordDoNotMatch: '新しいパスワードと再記入は同じではありません。',
    newPasswordEmpty: 'パスワードフィールドを記入してください。',
    send: '送信',
    profileUpdated: 'プロファイル変更しました。',
    testMail: 'テストメール送信',

    // Settings
    general: "一般",
    wifi: "WiFi",
    auth: "認証",
    dataScreen: "データ画面",
    dataRefresh: "データ更新",
    registerWifi: "登録済みWiFi",
    timezone: "タイムゾーン",
    screenRotation: "画面切替",
    interval: "間隔",
    enabled: "有効",
    disabled: "無効",
    evuMain: "EVUメイン",
    iotDataLatest: "現在の状況",
    co2DataHistory: "CO2データ",
    pressureDataHistory: "気圧データ",
    o2DataHistory: "O2データ",
    co2Graph: "CO2図",
    co2: "CO2",
    airflow: "空流",
    airFlowGraph: "空流図",

    register: '登録',
    update: '更新',
    deviceName: '機名',
    loginUsername: 'ログインユーザー',
    loginPassword: 'ログインパスワード',
    totalDevices: '機械の数',
    save: '保存',

    thresholds: "境界値設定",
    measure: "計測項目",
    warningYellow: "警告（黄）",
    dangerRed: "危険（赤）",

    graphWidth: "図の幅",

    // EVU Home
    fanPower: "換気量",
    fanMaintenance: "換気扇保守",
    data: "データ",

    // EVU Power
    menu: "メニュー",
    auto: "自動",
    low: "弱",
    high: "強",
    manualOverride: "手動モード",

    // EVU Maintenance
    filterRunTime: "フィルター使用時間",
    fanRunTime: "換気扇実行時間",
    reset: "初期化",
    confirmResetTitle: "フィルタ使用時間初期化確認",
    confirmResetText: "初期化の前に戻すことができません。EVUのフィルターを置き換える時以外は初期化しないでください。",
    cancel: "キャンセル",

    // EVU Main
    airChangesPerHour: "室内全体の空気の入替回数",
    airFlowRate: "空気流量",
    roomAirChangeTime: "室内全体の空気入換にかかる時間",
    minutes: "分",
    times: "回",
    evuPressure: "圧力",
    dashboardEvuTitle: "空気の動きモニター",
    airChange: "空気入替回数",

    // Overview
    doorStatus: "ドア状況",
    statusDoorClose: "開",
    statusDoorOpen: "閉",

    // Device Settings
    referencePressureDevice: "ベース気圧デバイス：",
    pressureDiffReset: "気圧差初期化",
    resetSuccessfull: "初期化成功",
    referencePressureFlag: "ベース気圧？",
    positivePressureFlag: "陽圧？",
    displayOrder: "表示順位",
    timeoutUnit: "タイムアウト（秒）",

    // File Upload
    fileUpload: "ファイルアップロード",
    toDisplayScreen: "表示画面へ",
    fileUploadError: "エラーが発生しました。",
    filename: "ファイル名",
    uploader: "アップロード者",
    shown: "表示",
    yes: "はい",
    no: "いいえ",
    fileUploadMaxFileReached: "新しいファイルをアップロードする前にファイルを削除してください。",
    fileUploadDragDropMessage: "ファイルをドロップしてください（PNG/JPG, 10MB以内）",
    fileUploadFilesizeError: "ファイルを10MB以内にしてください。",
    fileUploadExtensionError: "ファイル拡張子を.pngあるいは.jpgにしてください。",

    fileRotateNone: "表示対象ファイルを選択してください（設定画面）",

    resetDevicePositions: "デバイス位置初期化",

    // Tent
    tent: "テント",
    o2: "酸素",
    tentDataHistorical: "過去データ",

    // General
    unexpectedError: '想定外エラー',
    shutdown: 'シャットダウン',
    period: "期間",

    // Explanations (Info buttons)
    meGraphExplanation: "気圧差分は感染室よりの差分計算されています。",

  },
});
