import React from "react";
import {
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import axios from "axios";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import clsx from "clsx";

// styles
import useStyles from "../styles";

// components

import {authUrl} from "../../../meta";

// customized hook


import strings from "../../../strings";
import {
  updateTheme,
  updateTimezone,
  useLocationDispatch,
  useLocationState,
} from "../../../context/LocationContext";
import LanguageSelect from "../../../components/LanguageSelect";


export default function GeneralSettings() {
  // global
  let classes = useStyles();

  // static values
  const locationState = useLocationState();
  const locationDispatch = useLocationDispatch();

  return (
    <>
      <div className={classes.profileMenuUser}>
        <FormControlLabel
          control={
            <Switch
              classes={{
                track: classes.toggleSwitchTrack
              }}
              checked={locationState.theme}
              onChange={(e, value) => updateTheme(locationDispatch, value)}
              value="themeSetting"
              color="primary"
            />
          }
          label={strings.darkTheme}
        />
      </div>

      <div className={classes.profileMenuUser}>
        <LanguageSelect/>
      </div>

      <div className={classes.profileMenuUser}>
        <FormControlLabel
          className={clsx(classes.margin)}
          style={{width: "fit-content", marginLeft: 0}}
          control={
            <TimezonePicker
              absolute={true}
              value={locationState.timezone}
              onChange={timezone => {
                let oldTimezone = locationState.timezone;
                axios.post(authUrl + "user/info", {timezone: timezone})
                  .catch(function () {
                    updateTimezone(locationDispatch, oldTimezone);
                  });
                updateTimezone(locationDispatch, timezone);
              }}
              placeholder={strings.timezone}
              style={{maxHeight: '200px', overflowY: 'scroll', marginLeft: '5px'}}
            />
          }
          label={strings.timezone}
          labelPlacement={"start"}
        />
      </div>
    </>
  );
}
