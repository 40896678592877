import React, {useEffect, useState} from "react";
import {
  FormControl,
  InputLabel,
  Chip,
  MenuItem,
  Input, Button,
} from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";

// styles
import useStyles from "../styles";

// components
import SelectDropdown from "../../../components/SelectDropdown";

// customized hook
import {useDataState} from "../../../context/DataContext";
import {useLocationState} from "../../../context/LocationContext";

import {addTimeInfo, deviceSort, mimeTypes, now} from "../../../globalFunctions";
import strings from "../../../strings";
import {iotUrl} from "../../../meta";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function GraphSettingsMenu( { setData } ) {
  // global

  const classes = useStyles();

  const [selectedMacs, setSelectedMacs] = useState([]);
  const [devices, setDevices] = useState({});

  const [requestPending, setRequestPending] = useState(false);

  const [selectedMeasure, setSelectedMeasure] = useState("co2");

  const [selectedScope, setSelectedScope] = useState("last1h");

  const measures = [
    ["co2", strings.co2],
    ["pressure_diff", strings.pressureDiffNegative],
    ["o2", strings.o2]
  ];

  const scopes = {
    last1h: [strings.last1h, () => [now()-60*60, now()]],
    last2h: [strings.last2h, () => [now()-2*60*60, now()]],
    last6h: [strings.last6h, () => [now()-6*60*60, now()]],
    last12h: [strings.last12h, () => [now()-12*60*60, now()]],
    last1d: [strings.last1d, () => [now()-24*60*60, now()]],
    last2d: [strings.last2d, () => [now()-2*24*60*60, now()]],
    last1w: [strings.last1w, () => [now()-7*24*60*60, now()]],
    last1m: [strings.last1m, () => [now()-30*24*60*60, now()]],
  };


  // static values
  const dataState = useDataState();
  const locationState = useLocationState();

  // state hooks

  // state hooks

  // Interval hook

  const filterShownMacs = function () {
    const hosts = dataState.hosts;
    const _devices = {}

    let validMacs = Object.keys(hosts).filter((mac) => !mac.startsWith('__'));
    validMacs.forEach((mac) => {
      _devices[mac] = hosts[mac].display_name;
    });
    setDevices(_devices);
  }

  useEffect(filterShownMacs, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps


  const sendRequestData = function () {

    const scopeResult = scopes[selectedScope][1]();
    const postData = {
      macs: selectedMacs,
      measure: selectedMeasure,
      t0: scopeResult[0],
      t1: scopeResult[1],
    };

    setRequestPending(true);
    axios.post(iotUrl + "data/custom", postData)
      .then(function (response) {
        Object.keys(response.data).forEach(function (mac) {
          addTimeInfo(response.data[mac], locationState.timezone);
        })
        response.data.__measure = postData.measure;
        setData(response.data);
      })
      .catch(function () {})
      .finally(() => setRequestPending(false));
  }

  const sendDownloadData = function () {

    const scopeResult = scopes[selectedScope][1]();
    const postData = {
      macs: selectedMacs,
      measure: selectedMeasure,
      t0: scopeResult[0],
      t1: scopeResult[1],
    };

    setRequestPending(true);
    axios.post(
      iotUrl + "data/dump",
      postData,
      {responseType: 'blob'}
    )
      .then(function (response) {
        let fileName = response.headers["content-disposition"].split("filename=")[1];
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
          window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: mimeTypes.zip}),
            fileName);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: mimeTypes.zip}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
          document.body.appendChild(link);
          link.click();
        }      })
      .catch(function () {})
      .finally(() => setRequestPending(false));
  }

  const onMacSelect = function (newMacs) {
    if (selectedMacs.length === Object.keys(devices).length && newMacs.indexOf("all") > -1) {
      setSelectedMacs([]);
    }
    else if (newMacs.indexOf("all") > -1) {
      setSelectedMacs(deviceSort(Object.keys(devices), dataState));
    } else {
      setSelectedMacs(newMacs);
    }
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="measure-select-label">{strings.measure}</InputLabel>
        <SelectDropdown
          labelId="measure-select-label"
          id="measure-select"
          value={selectedMeasure}
          onChange={(e) => setSelectedMeasure(e.target.value)}
          MenuProps={MenuProps}
        >
          {measures.map((measureInfo) => (
            <MenuItem key={measureInfo[0]} value={measureInfo[0]}>
              {measureInfo[1]}
            </MenuItem>
          ))}
        </SelectDropdown>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="scope-select-label">{strings.period}</InputLabel>
        <SelectDropdown
          labelId="scope-select-label"
          id="scope-select"
          value={selectedScope}
          onChange={(e) => setSelectedScope(e.target.value)}
          MenuProps={MenuProps}
        >
          {Object.keys(scopes).map((scopeKey) => (
            <MenuItem key={scopeKey} value={scopeKey}>
              {scopes[scopeKey][0]}
            </MenuItem>
          ))}
        </SelectDropdown>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">{strings.device}</InputLabel>
        <SelectDropdown
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={selectedMacs}
          onChange={(e) => onMacSelect(e.target.value)}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((mac) => (
                <Chip key={mac} label={devices[mac]} className={classes.chip} style={{height: undefined}}/>
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem key="data-analysis-mac-select-all" value="all">
            {strings.all}
          </MenuItem>
          {deviceSort(Object.keys(devices), dataState).map((mac) => (
            <MenuItem key={mac} value={mac}>
              {devices[mac]}
            </MenuItem>
          ))}
        </SelectDropdown>
      </FormControl>

      <FormControl className={clsx(classes.formSubmit)}>
        <Button
          className={classes.submitButton}
          color="primary"
          variant="contained"
          onClick={sendDownloadData}
          disabled={requestPending || selectedMacs.length === 0}
        >
          {strings.download}
        </Button>
      </FormControl>

      <FormControl className={clsx(classes.formSubmit)}>
        <Button
          className={classes.submitButton}
          color="primary"
          variant="contained"
          onClick={sendRequestData}
          disabled={requestPending || selectedMacs.length === 0}
        >
          {"OK"}
        </Button>
      </FormControl>


      {/*<form className={classes.container} noValidate>*/}
      {/*  <TextField*/}
      {/*    id="datetime-local"*/}
      {/*    label="Next appointment"*/}
      {/*    type="datetime-local"*/}
      {/*    defaultValue="2017-05-24T10:30"*/}
      {/*    className={classes.textField}*/}
      {/*    InputLabelProps={{*/}
      {/*      shrink: true,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</form>*/}

    </>
  );
}

export default GraphSettingsMenu;