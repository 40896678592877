import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";


// styles

// components

// customized hook

import HumidityIcon from '../../../icons/humidityIcon.png';
import Co2Icon from '../../../icons/co2.png'
import O2Icon from '../../../icons/o2.png'
import TemperatureIcon from '../../../icons/temperature.png'
import PressureIcon from '../../../icons/pressureIcon.png'

import strings from "../../../strings";
import DatumCard from "../../../components/DatumCard";
import {useDataState} from "../../../context/DataContext";
import {
  forceCellDecimal,
  getCo2Color,
  getO2Color,
  convertTime,
  getPressureDiffColor,
  getLastSeenColor,
  getDeviceTimeout,
} from "../../../globalFunctions";
import {useLocationState} from "../../../context/LocationContext";
import {EVU_DEVICES, TENT_DEVICES} from "../../../globals";
import AirflowIcon from "../../../icons/airflow.png";


function DataPanel({mac, setModalInfo, setRequestPending, fetchGraphData, hostType}) {

  // global
  // static values
  const dataState = useDataState();
  const locationState = useLocationState();

  const [latestData, setLatestData] = useState({});
  const [hasRef, setHasRef] = useState()

  useEffect(function () {
    const hosts = dataState.hosts;
    const currentHost = hosts[mac];
    let hasRef_ = Object.values(hosts)
      .filter((host) => typeof host !== 'number')
      .filter((host) => host.site_location_id === currentHost.site_location_id)
      .filter((host) => host.is_ref)
      .length > 0;
    setHasRef(hasRef_);

    setLatestData(dataState.meLatestByHost[mac]);
  }, [dataState.meLatestByHost, mac]);  // eslint-disable-line react-hooks/exhaustive-deps


  const openModal = function (measure) {
    const hostInfo = dataState.hosts[mac] || {};
    setModalInfo({
      hostInfo: hostInfo,
      shown: true,
      mac: mac,
      measure: measure,
    });
    fetchGraphData(mac).then(setRequestPending(false));
  };


  const co2Card = function () {
    return (
      <Grid item md={6} xs={6}>
        <DatumCard
          onClick={() => openModal("co2")}
          noBackground={true}
          iconUrl={Co2Icon}
          title={strings.co2}
          color={() => getCo2Color(latestData.co2, dataState.thresholds)}
          value={latestData.co2 !== undefined ? forceCellDecimal(latestData.co2) + "ppm" : ""}
        />
      </Grid>
    );
  }

  const o2Card = function () {
    return (
      <Grid item md={6} xs={6}>
        <DatumCard
          onClick={() => openModal("o2")}
          noBackground={true}
          iconUrl={O2Icon}
          title={strings.o2}
          color={() => getO2Color(latestData.o2, dataState.thresholds)}
          value={latestData.o2 !== undefined ? forceCellDecimal(latestData.o2) + "%" : ""}
        />
      </Grid>
    );
  }

  const airflowCard = function () {
    return (
      <Grid item md={6} xs={6}>
        <DatumCard
          noBackground={true}
          iconUrl={AirflowIcon}
          title={strings.airflow}
          value={latestData.airflow !== undefined ? forceCellDecimal(latestData.airflow) + "m3/h" : ""}
        />
      </Grid>
    );
  }

  const temperatureCard = function () {
    return (
      <Grid item md={6} xs={6}>
        <DatumCard
          onClick={() => openModal("pressureDiff")}
          noBackground={true}
          iconUrl={TemperatureIcon}
          title={strings.temperature}
          value={latestData.temperature !== undefined ? forceCellDecimal(latestData.temperature) + "C" : ""}
        />
      </Grid>
    );
  }

  const humidityCard = function () {
    return (
      <Grid item md={6} xs={6}>
        <DatumCard
          noBackground={true}
          iconUrl={HumidityIcon}
          title={strings.humidity}
          value={latestData.humidity !== undefined ? forceCellDecimal(latestData.humidity) + "%" : ""}
        />
      </Grid>
    );
  }

  const pressureCard = function () {
    let posPressureFlag = (dataState.hosts[mac] || {}).is_pos;
    let colorFactor = posPressureFlag ? -1 : 1;

    return (
      <Grid item md={6} xs={6}>
        <DatumCard
          onClick={() => openModal("pressureDiff")}
          noBackground={true}
          iconUrl={PressureIcon}
          color={() => getPressureDiffColor(colorFactor * latestData.pressure_diff, dataState.thresholds)}
          title={strings.pressureDiff}
          value={latestData.pressure_diff !== undefined ? forceCellDecimal(latestData.pressure_diff) + "Pa" : ""}
        />
      </Grid>
    );
  }

  // combinations
  const mePanel = function () {
    return (
      <>
        <Grid container spacing={1}>
          {co2Card()} {pressureCard()}
        </Grid>
        <div style={{height: '1%'}}/>
        <Grid container spacing={1}>
          {temperatureCard()} {humidityCard()}
        </Grid>
      </>
    )
  };

  const evuNoPressurePanel = function () {
    return (
      <>
        <Grid container spacing={1}>
          {co2Card()} {humidityCard()}
        </Grid>
        <div style={{height: '1%'}}/>
        <Grid container spacing={1}>
          {temperatureCard()} {airflowCard()}
        </Grid>
      </>
    )
  };

  const evuPressurePanel = function () {
    return (
      <>
        <Grid container spacing={1}>
          {co2Card()} {pressureCard()}
        </Grid>
        <div style={{height: '1%'}}/>
        <Grid container spacing={1}>
          {temperatureCard()} {airflowCard()}
        </Grid>
      </>
    )
  };

  const tentPanel = function () {
    return (
      <>
        <Grid container spacing={1}>
          {co2Card()} {o2Card()}
        </Grid>
        <div style={{height: '1%'}}/>
        <Grid container spacing={1}>
          {temperatureCard()} {}
        </Grid>
      </>
    )
  };

  const deviceDependentPanel = function () {
    if (EVU_DEVICES.indexOf(hostType) > -1) {
      if (hasRef) {
        return evuPressurePanel();
      }
      return evuNoPressurePanel();
    }
    if (TENT_DEVICES.indexOf(hostType) > -1) {
      return tentPanel();
    }
    return mePanel();
  };

  return latestData ? (
      <div
        style={{
          backgroundColor: "#4c7285",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginTop: "1%",
        }}
      >

        <div style={{height: '5%'}}/>

        <Grid container spacing={1}>
          <Grid item md={6} xs={6}>
            <Typography variant={"h5"}>
              <span>
                {dataState.hosts[mac].display_name}
                {latestData.door_open && <span style={{color: "red"}}> (DOOR OPEN)</span>}
              </span>
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <span
              style={{
                float: "right",
                color: getLastSeenColor(latestData.t, getDeviceTimeout(dataState, mac))
              }}
            >
              {convertTime(latestData.t, locationState.timezone)}
            </span>
          </Grid>
        </Grid>

        {/* device-type-dependent type */}
        { deviceDependentPanel() }
      </div>
    )
    :
    (<></>);
}

export default DataPanel;