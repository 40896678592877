import React, {useEffect, useState} from "react";
import axios from "axios";
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';

// styles
import "../../themes/bootstrap.min.css"

import useStyles from "./styles";
import {airUrl} from "../../meta";
import {formatDate} from "../../globalFunctions";
import Radio from './components/Radio/Radio';
import useInterval from "../dashboard/useInterval";
import ModalLineGraph from "./components/ModalLineGraph/ModalLineGraph";
import ModalThresholds from "./components/ModalThresholds/ModalThresholds";
import strings from "../../strings";
import MapDevice from "../../components/MapDevice";
import SelectionMenu from "../iot_map/components/SelectionMenu";


export default function Map() {

  let classes = useStyles();

  const [saveLocationShown, setSaveLocationShown] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState(0); //0: device graph, 1: site thresholds

  const [hostData, setHostData] = useState([]);
  const [hostStatusData, setHostStatusData] = useState([]);
  const [thresholdData, setThresholdData] = useState([]);

  const [selectionInfo, setSelectionInfo] = useState({
    siteId: undefined,
    siteName: undefined,
    siteLocationId: undefined,
    siteLocationName: undefined
  })
  const [currentMapUrl, setCurrentMapUrl] = useState("abc.png");

  const [locationHosts, setLocationHosts] = useState([]);

  const [clickedHostInfo, setClickedHostInfo] = useState({});

  const [modalRadioOption, setModalRadioOption] = useState('2h');
  const [modalGraphData, setModalGraphData] = useState([]);

  useEffect(() => {
    const fetchHostStatusData = async() => {
      const { data } = await axios(`${airUrl}get_hosts_statuses`);
      setHostStatusData(data);
    };
    fetchHostStatusData().then(undefined);
  }, []);


  const handleDeviceTagClick = (clickedHostInfo_) => {
    setClickedHostInfo(clickedHostInfo_);
    setModalContent(0);
    setModalShow(true);
    fetchModalGraphData(undefined, clickedHostInfo_);
  };

  const handleDeviceTagDragEnd = () => {
    setSaveLocationShown(true);
  };

  const onRadioButtonChange = (newTimeRange) => {
    setModalRadioOption(newTimeRange);
    fetchModalGraphData(newTimeRange);
  };

  const refreshThresholdData = (newThresholdData) => {
    setThresholdData(newThresholdData);
  };

  const fetchModalGraphData = (newTimeRange, clickedHostInfo_) => {
    let hostInfo = clickedHostInfo_ || clickedHostInfo;
    if (hostInfo.host_type !== 0 && hostInfo.host_type !== 1) {
      return;
    }
    axios.post(airUrl + (hostInfo.host_type === 0 ? "get_host_iperf" : "get_host_speedtests"), {
      'hostname': hostInfo.hostname,
      'range': newTimeRange || modalRadioOption,
    }).then((result) => setModalGraphData(result.data));
  };

  // Custom toolip on Graph
  const saveLocations = () => {
    axios.post(airUrl + "save_hosts_location", hostData)
      .finally(() => setSaveLocationShown(false));
  };

  // Custom toolip on Graph
  const saveThresholds = (siteId) => {
    let postData = {
      site_id: siteId,
      changes: thresholdData.filter((threshold) => threshold.site_id === siteId)
    };
    axios.post(airUrl + "update_thresholds", postData)
      .finally(() => setModalShow(false));
  };

  // Interval hook
  useInterval(() => {
    modalShow && fetchModalGraphData();
  }, 60 * 1000);


  return (
    <>
      {/* Save Location message on top of screen */}
      <div
        className={"alert alert-success"}
        style={{'display': saveLocationShown ? 'block' : 'none'}}
      >
        <span>Save new locations?</span>
        <input
          type="button"
          className={"btn btn-outline-secondary btn-sm float-right"}
          onClick={() => setSaveLocationShown(false)}
          value={strings.mapCancel}
        />
        <input
          type="button"
          className={"btn btn-outline-success btn-sm float-right"}
          onClick={saveLocations}
          value={strings.mapYes}
        />
      </div>

      {/* Site/Site_Location/MapSelection Menu */}
      <>
        <SelectionMenu
          hostData={hostData}
          setHostData={setHostData}
          targetHostTypes={[0, 1]}
          setSelectionInfo={setSelectionInfo}
          setCurrentMapUrl={setCurrentMapUrl}
          setLocationHosts={setLocationHosts}
        />

        {/* Map with Svg */}
        <div className={classes.fullpage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 600 320"
            id="mapSvg"
          >
            <image
              x="0"
              y="0"
              width="600"
              height="320"
              key={currentMapUrl}
              xlinkHref={currentMapUrl}>
            </image>
            {
              locationHosts.map((locationHost, i) =>
                <MapDevice
                  key={locationHost.hostname}
                  onClick={handleDeviceTagClick}
                  onDragEnd={handleDeviceTagDragEnd}
                  deviceIndex={i}
                  hostInfo={locationHost}
                  hostStatus={hostStatusData[locationHost.hostname]}
                  shape={locationHost.host_type === 1 ? '●' : '▲'}
                />
              )
            }
          </svg>

          {/* Modal with graph for one host */}
          <Modal
            className={classes.modalXl}
            dialogClassName={classes.modalXlDialog}
            show={modalShow}
            onHide={() => setModalShow(false)}
          >
            {modalContent === 0 ?
              <>
                <Modal.Header
                  className={classes.modalHeader}
                  closeButton
                >
                  <Modal.Title>
                    {clickedHostInfo.hostname} - {clickedHostInfo.display_name}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Header
                  style={{display: 'inline-block'}}
                  className={classes.modalHeader}
                >
                  <Radio
                    onChange={onRadioButtonChange}
                  />
                </Modal.Header>
                <Modal.Body
                  className={classes.modalBody}
                >

                  {/* Graph for one device */}
                  <ModalLineGraph
                    thresholdData={thresholdData}
                    clickedHostInfo={clickedHostInfo}
                    siteId={selectionInfo.siteId}
                    modalGraphData={modalGraphData}
                  />
                </Modal.Body>
              </>
              :
              <>
                <Modal.Header
                  className={classes.modalHeader}
                  closeButton
                >
                  <Modal.Title>
                    Thresholds - {selectionInfo.siteName}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={classes.modalBody}
                >
                  {/* Thresholds settings modal */}
                  <ModalThresholds
                    thresholdData={thresholdData}
                    siteId={selectionInfo.siteName}
                    refreshThresholdData={refreshThresholdData}
                    saveThresholds={saveThresholds}
                  />
                </Modal.Body>
              </>
            }
          </Modal>

          {/* Puts tooltip for all hosts on map */}
          {
            locationHosts.map((locationHost) =>
              <ReactTooltip
                key={locationHost.hostname}
                id={locationHost.hostname}
                effect="solid"
                border={true}
                data-html={true}
                place="bottom">
                <div>{locationHost.hostname}</div>
                <div>{locationHost.display_name}</div>
                {
                  (hostStatusData[locationHost.hostname] !== undefined && hostStatusData[locationHost.hostname].time !== undefined) ?
                    <div>{strings.mapLastSeen}: {formatDate(new Date(hostStatusData[locationHost.hostname].time * 1000))}</div> :
                    undefined
                }
              </ReactTooltip>
            )
          }
        </div>
      </>
    </>
  );
}
