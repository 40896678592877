import React, {useEffect, useState} from "react";
import axios from "axios";

import {now, useInterval} from "../globalFunctions";
import {appVersion} from "../version";
import {useLocationState} from "../context/LocationContext";
import {authUrl} from "../meta";

// components

// pages

// context
const sendIntervalMs = 6 * 60 * 60 * 1000; // 6 hours
const retryIntervalMs = 5 * 1000;

export default function InfoSend() {

  const [t0] = useState(now());
  const [lastSent, setLastSent] = useState({});

  const locationState = useLocationState();

  const sendData = function () {
    if (!locationState.userInfo.username) return;
    if (locationState.userInfo.username !== lastSent.username
      || now()>lastSent.t+sendIntervalMs/1000) {

      const data = {
        t: Math.round(now()),
        uptime: Math.round(now()-t0),
        username: locationState.userInfo.username,
        version: appVersion,
        browser: navigator.userAgent,
        url: window.location.href,
        platform: navigator.platform,
        language: navigator.language,
      }
      setLastSent(data);

      axios
        .post(`${authUrl}heartbeat`, data)
    }

  };

  useInterval(sendData, retryIntervalMs);
  useEffect(sendData, [locationState.userInfo.username]);

  return (
    <></>
  );

}
