import React, {useEffect, useState} from "react";
import axios from "axios";
import ReactTooltip from 'react-tooltip';
import {Input} from "@material-ui/core";

// styles
import '../../themes/bootstrap.min.css';
import useStyles from "./styles";

import {iotUrl} from "../../meta";
import {
  convertTime,
} from "../../globalFunctions";
import strings from "../../strings";
import useInterval from "../dashboard/useInterval";

import MapModal from "./components/MapModal";
import SelectionMenu from "./components/SelectionMenu";
import {useLocationState} from "../../context/LocationContext";
import MapDevice from "../../components/MapDevice/MapDevice";
import DataStateRefresh, {
  fetchLatestMeData,
  fetchMeGraphData
} from "../../components/DataStateRefresh";

import {
  useDataDispatch,
  useDataState
} from "../../context/DataContext";
import {ME_DEVICES} from "../../globals";
import ScreenRotation from "../../components/ScreenRotation";



export default function IoTMap() {

  const classes = useStyles();

  const refreshInterval = 10000;

  const locationState = useLocationState();
  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const [selectionInfo, setSelectionInfo] = useState({
    siteId: undefined,
    siteName: undefined,
    siteLocationId: undefined,
    siteLocationName: undefined
  })

  const [saveLocationShown, setSaveLocationShown] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    shown: false,
    hostInfo: {},
    mac: '',
    measure: 'pressureDiff',
  });
  const [modalContent, setModalContent] = useState(0); //0: sensor graph, 1: room graph

  const [locationHosts, setLocationHosts] = useState([]);

  const [currentMapUrl, setCurrentMapUrl] = useState("abc.png");

  const [meRequestPending, setMeRequestPending] = useState(false);

  const handleDeviceTagClick = function (clickedMac) {
    setModalContent(0);
    const hostInfo = dataState.hosts[clickedMac] || {};
    setModalInfo({
      ...modalInfo,
      hostInfo: hostInfo,
      mac: clickedMac,
      shown: true
    });
  };
  const handleDeviceTagDragEnd = () => setSaveLocationShown(true);

  const meFetch = function () {
    if (meRequestPending) {
      return;
    }
    setMeRequestPending(true);
    fetchMeGraphData(dataState, dataDispatch, locationState)
      .finally(() => setMeRequestPending(false));
  };
  useInterval(meFetch, refreshInterval);
  useEffect(meFetch, [dataState.hosts]);


  const saveLocations = () => {
    axios.post(iotUrl + "hosts/info", locationHosts)
      .finally(() => setSaveLocationShown(false));
  };


  function isRelevantHost(hostInfo) {
    return ME_DEVICES.indexOf(hostInfo.host_type) > -1;
  }


  useEffect(() => {
    fetchLatestMeData(dataState, dataDispatch).finally(undefined)
  }, [dataState.hosts]);  // eslint-disable-line react-hooks/exhaustive-deps


  useInterval(async () => {
    fetchLatestMeData(dataState, dataDispatch).finally(undefined)
  }, locationState.refresher * 1000);


  useEffect(() => {
    let locationId = selectionInfo.siteLocationId;
    if (selectionInfo.siteId === undefined || locationId === undefined) {
      return
    }
    let relevantHosts = Object.keys(dataState.hosts);
    relevantHosts = relevantHosts
      .filter((hostname) => dataState.hosts[hostname].site_location_id === locationId)
      .filter((hostname) => isRelevantHost(dataState.hosts[hostname]));
    setLocationHosts(relevantHosts.map((hostname) => dataState.hosts[hostname]));
  }, [selectionInfo]);  // eslint-disable-line react-hooks/exhaustive-deps


  // Interval hook
  const getLatestData = function (hostInfo) {
    return dataState.meLatestByHost[hostInfo.mac_id] || {}
  }


  return (
    <>
      <ScreenRotation/>
      <DataStateRefresh
        hostRefresh={true}
        siteRefresh={true}
        siteLocationRefresh={true}
        siteLocationMapRefresh={true}
        thresholdsRefresh={true}
      />

      {/* Save Location message on top of screen */}
      <div
        className={"alert alert-success"}
        style={{'display': saveLocationShown ? 'block' : 'none'}}
      >
        <span>Save new locations?</span>
        <input
          type="button"
          className={"btn btn-outline-secondary btn-sm float-right"}
          onClick={() => setSaveLocationShown(false)}
          value={strings.mapCancel}
        />
        <input
          type="button"
          className={"btn btn-outline-success btn-sm float-right"}
          onClick={saveLocations}
          value={strings.mapYes}
        />
      </div>

      {/* Site/Site_Location/MapSelection Menu */}
      <>
        <SelectionMenu
          setCurrentMapUrl={setCurrentMapUrl}
          selectionInfo={selectionInfo}
          setSelectionInfo={setSelectionInfo}
          additionalButton={
            <Input
              type="button"
              className="ml-2 mt-3"
              disabled={selectionInfo.siteLocationId === undefined}
              onClick={() => {
                setModalContent(1);
                setModalInfo({...modalInfo, shown: true});
              }}
              value={strings.roomGraph}
            />
          }
        />

        {/* Map with Svg */}
        <div className={classes.fullpage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 600 320"
            id="mapSvg"
          >
            <image
              x="0"
              y="0"
              width="600"
              height="320"
              key={currentMapUrl}
              xlinkHref={currentMapUrl}>
            </image>
            {
              locationHosts.map((locationHost, i) =>
                <MapDevice
                  key={locationHost.mac_id}
                  onClick={handleDeviceTagClick}
                  onDragEnd={handleDeviceTagDragEnd}
                  deviceIndex={i}
                  hosts={locationHosts}
                  hostInfo={locationHost}
                  hostData={dataState.meLatestByHost[locationHost.mac_id]}
                  dataState={dataState}
                />
              )
            }
          </svg>

          {/* Modal with graph for one host */}
          <MapModal
            modalContent={modalContent}
            modalInfo={modalInfo}
            setModalInfo={setModalInfo}
            selectionInfo={selectionInfo}
          />

          {/* Puts tooltip for all hosts on map */}
          {
            locationHosts.map((locationHost) =>
              <ReactTooltip
                key={locationHost.mac_id}
                id={locationHost.mac_id}
                effect="solid"
                border={true}
                data-html={true}
                place="bottom">
                <div>{locationHost.display_name}</div>
                <div key={"lastSeen" + locationHost.displayName}>
                  {strings.mapLastSeen}: {convertTime(getLatestData(locationHost).t, locationState.timezone) || ""}
                </div>
                {ME_DEVICES.indexOf(locationHost.host_type) > -1 ?
                  <>
                    <div key={"co2" + locationHost.displayName}>
                      {strings.co2}: {getLatestData(locationHost).co2 || ""} ppm
                    </div>
                    <div key={"press" + locationHost.displayName}>
                      {strings.pressureDiff}: {getLatestData(locationHost).pressure_diff || ""} Pa
                    </div>
                  </>
                  :
                  undefined
                }
              </ReactTooltip>
            )
          }
        </div>
      </>
    </>
  );
}
