import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  muiPaper: {
    width: '500px',
  },
  tableScrollable: {
    height: "750px",
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  graphHeading: {
    fontWeight: 700,
    textAlign: "right",
    marginRight: "25px",
    marginBottom: "10px",
    fontSize: "16px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '80%',
  },
  shutdownButton: {
    width: "200px",
    textTransform: "unset",
    backgroundColor: "#FF9999",
    padding: "5px 20px 5px 20px",
    color: "#FFFFFF",
    stroke: "#FFFFFF",
  },

  modal: {
    backgroundColor: theme.palette.background.default,
    top: "10%",
    left: "15%",
  },
  modalXl: {
    marginTop: "5%",
    marginLeft: "17%",
    height: "90%",
    width: "75%",
    maxWidth: "1600px"
  },
  modalXlDialog: {
    height: "90%",
    width: "100%",
    maxWidth: "100%" // Overwrite the bootstrap default max size
  },
  modalHeader: {
    backgroundColor: theme.palette.background.default,
  },
  modalBody: {
    backgroundColor: theme.palette.background.default,
  },
}));
