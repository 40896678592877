import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  avatar: {
    height: 46,
    width: 46
  },
  contentRoot: {
    padding: 8,
    '&:last-child': {
      paddingBottom: 12,
    }
  }
}));

const DatumCard = ({ noBackground, className, title, value, color, icon, iconUrl, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      style={{
        backgroundColor: noBackground ? "#00000000" : undefined,
        border: "none"
      }}
      {...rest}
    >
      <CardContent className={classes.contentRoot}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid item md={1} xs={1}/>
          <Grid item md={3} xs={3}>
            {
              iconUrl ?
                <Avatar src={iconUrl} className={classes.avatar}/>
                :
                icon ?
                  <Avatar className={classes.avatar}>
                    {icon}
                  </Avatar>
                  :
                  undefined
            }
          </Grid>
          <Grid item md={8} xs={8} justify={"center"}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              <div style={{ color: color ? color() : undefined}}>
                {value}
              </div>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

DatumCard.propTypes = {
  className: PropTypes.string
};

export default DatumCard;