import React from "react";
import {
  ResponsiveContainer,
  ReferenceLine,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip, ScatterChart, Scatter,
} from "recharts";

// styles
import useStyles from "./styles";

// components

// customized hook


import {
  forceCellDecimal,
  getCo2Color,
  maxSoftBoundAutofix,
  minSoftBoundAutofix
} from "../../globalFunctions";
import strings from "./../../strings";
import {useDataState} from "../../context/DataContext";


export default function Co2Graph({ mac,
                                   height,
                                   overwriteData=undefined, }) {
  // global
  let classes = useStyles();

  const dataState = useDataState();

  // static values
  let referenceLines = [
    [dataState.thresholds.co2Warn, "yellow"],
    [dataState.thresholds.co2Danger, "red"]
  ];
  let graphDomain = [
    minVal => minSoftBoundAutofix(minVal, 0, 100),
    maxVal => maxSoftBoundAutofix(maxVal, dataState.thresholds.co2Danger+200, 100),
  ];

  // Custom toolip on Graph
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0]) {
      return (
        <div className={classes.customToolTip}>
          <p className="label">
            {payload[0].payload.time}
          </p>
          <p
            key={`tooltip-${label}`}
            className="label">
            {`${strings.co2} : ${forceCellDecimal(payload[0].payload.co2)}`}
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomizedDot = (props) => {
    const { cx, cy, node } = props;
    const color = getCo2Color(node.y, dataState.thresholds);
    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        stroke={color}
        strokeWidth={1}
        fill={color}
      />
    );
  };


  // Individual Widgets
  return (
    <ResponsiveContainer height={height}>
      <ScatterChart
        width={500}
        height={height}
        data={mac ? (overwriteData || dataState.meGraphByHost)[mac] || [] : []}
      >
        <defs>
          <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="red" />
            <stop offset="100%" stopColor="green" />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3 3 3" />
        <XAxis dataKey="time" />
        <YAxis
          yAxisId="left"
          width={70}
          unit={"ppm"}
          domain={graphDomain}
          tickFormatter={(tick) => tick}
        />
        <Tooltip content={<CustomTooltip />} key="Co2GraphCustomTooltip"/>
        {referenceLines.map((referenceLine, idx) => (
            <ReferenceLine
              key={"co2-ref-" + idx}
              yAxisId={"left"}
              y={referenceLine[0]}
              stroke={referenceLine[1]}
              strokeWidth={1}
              label={{
                position: 'insideBottomRight',
                value: referenceLine[0],
                fill: referenceLine[1],
                fontSize: 14
              }}
            />
          ))
        }
        {mac !== '' ?
          (
            <Scatter
              key="co2"
              yAxisId="left"
              type="monotone"
              dataKey="co2"
              name={"co2 - " + (dataState.hosts[mac] || {}).display_name}
              connectNulls={true}
              shape={CustomizedDot}
              activeDot={{ r: 8 }}
            />
          )
          :
          undefined
        }
      </ScatterChart>
    </ResponsiveContainer>
  );
}
