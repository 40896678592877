import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {Button, Grid} from "@material-ui/core";

// styles
import DragAndDrop from "../../../components/DragAndDrop";
import Table from "../../../components/Table";
import DataStateRefresh, {fetchClientFileUpload} from "../../../components/DataStateRefresh";
import FileModal from "./FileUploadModal";

import {useDataState, useDataDispatch} from "../../../context/DataContext";

import strings from "../../../strings";
import {routes} from "../../../routes";
import {filesUrl} from "../../../meta";


const MAX_UPLOAD_FILES = 20;
const MAX_UPLOAD_FILESIZE = 10*1000*1000;


function FileUpload({ history }) {

  // static values
  const uploadFileKeys = [
    {name: 'user_filename', label: strings.filename},
    {name: 'upload_user', label: strings.uploader},
    {name: 'upload_time', label: strings.time},
  ];

  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const [modalShown, setModalShown] = useState(false);
  const [shownFile, setShownFile] = useState({shown: false});

  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState(undefined);

  useEffect(() => {
    setFiles(dataState.userFiles.data);
  }, [dataState.userFiles]);

  const onFileSelected = async (rowData) => {
    setModalShown(true);
    setShownFile(rowData);
  };

  const fileCheck = function (file) {
    if (file.size > MAX_UPLOAD_FILESIZE) {
      alert(strings.fileUploadFilesizeError)
      return false;
    }
    let fileExtension = file.name.split('.').pop().toLowerCase();
    if (['png', 'jpg', 'jpeg'].indexOf(fileExtension) === -1) {
      alert(strings.fileUploadExtensionError);
      return false;
    }
    return true;
  };

  const postNewFile = async () => {
    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("file_type", "rotate_doc");
    try {
      await axios.post(filesUrl + "file", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setNewFile(undefined);
      fetchClientFileUpload(dataState, dataDispatch).then(undefined);
    } catch (err) {
      alert(strings.fileUploadError)
    }
  }

  return (
    <>
      <DataStateRefresh
        clientFileUploadRefresh={true}
      />
      <FileModal
        shownFile={shownFile}
        modalShown={modalShown}
        setModalShown={setModalShown}
      />

      <Grid container spacing={2}>
        {
          files.length < MAX_UPLOAD_FILES ?
            <>
              <Grid item md={6} xs={6}>
                <DragAndDrop handleDrop={setNewFile} fileCheck={fileCheck}>
                  <div style={{height: 50}}>
                    {newFile ?
                      <div >{newFile.name}</div> :
                      <div>{strings.fileUploadDragDropMessage}</div>
                    }
                  </div>
                </DragAndDrop>
              </Grid>
              <Grid item md={3} xs={3}>
                <Button
                  color="primary"
                  size="small"
                  className="px-2"
                  variant="contained"
                  onClick={postNewFile}
                >
                  {strings.fileUpload}
                </Button>
              </Grid>
            </>
          :
          <Grid item md={9} xs={9}>
            {strings.fileUploadMaxFileReached}
          </Grid>
        }
        <Grid item md={3} xs={3}>
          <Button
            color="primary"
            size="small"
            className="px-2"
            variant="contained"
            onClick={() => history.push(routes.customFileRotate)}
          >
            {strings.toDisplayScreen}
          </Button>
        </Grid>
      </Grid>

      <Table
        data={files}
        keys={uploadFileKeys}
        handler={onFileSelected}
        compact={true}
      />

    </>
  );
}

export default withRouter(FileUpload)