import React from "react";
import axios from "axios";

import {routes, startPages, screenRotationUrls, screenRotationDefaultUrls, AIR, IOT} from "../routes";
import {authUrl} from "../meta";
import {appVersion} from "../version";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "SET_APP":
      return { ...state, cirrusApp: action.app };

    case "ROTATION_FLAG_SETTING":
      return { ...state, rotationFlag: action.rotationFlag};
    case "ROTATION_TIMER_SETTING":
      return { ...state, rotationTimer: action.rotationTimer};
    case "ROTATION_ENABLED_SETTING":
      return { ...state, rotationEnabledUrls: action.rotationEnabledUrls};

    case "SERVICE_ACCOUNT_SETTING":
      return { ...state, serviceAccount: action.serviceAccount};

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const savedCirrusApp = localStorage.getItem("cirrusApp");

  const savedRotationFlag = localStorage.getItem("rotationFlag");
  const savedRotationTimer = localStorage.getItem("rotationTimer");
  const savedRotationEnabledUrls = localStorage.getItem("rotationEnabledUrls");

  const savedServiceAccount = localStorage.getItem("serviceAccount");


  const enabledUrls = (savedRotationEnabledUrls ? JSON.parse(savedRotationEnabledUrls) : screenRotationDefaultUrls);
  enabledUrls[AIR] = enabledUrls[AIR].filter((x) => screenRotationUrls[AIR].indexOf(x) > -1);
  enabledUrls[IOT] = enabledUrls[IOT].filter((x) => screenRotationUrls[IOT].indexOf(x) > -1);

  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("access_token"),
    cirrusApp: savedCirrusApp ? savedCirrusApp : AIR,
    //
    rotationFlag: (savedRotationFlag ? savedRotationFlag === "1" : true),
    rotationTimer: (savedRotationTimer ? parseInt(savedRotationTimer) : 30),
    rotationEnabledUrls: enabledUrls,
    //
    serviceAccount: (savedServiceAccount ? JSON.parse(savedServiceAccount) : undefined),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  setCirrusApp,
  updateRotationTimer,
  updateRotationFlag,
  updateRotationEnabledUrls,
  autoSignIn,
  updateServiceAccount,
};

// ###########################################################

async function autoSignIn(dispatch, history, setIsLoading, setError) {
  if (window.PasswordCredential || window.FederatedCredential) {
    // Actual Credential Management API call to get credential object
    const cred = await navigator.credentials.get({
      password: true,
      mediation: 'silent'
    });
    // If credential object is available
    if (cred) {
      console.log('auto sign-in performed');

      // If `password` prop doesn't exist, this is Chrome < 60
      if (cred.password === undefined) {
        return Promise.resolve();
      } else {
        // Change form `id` name to `email`
        loginUser(dispatch, cred.id, cred.password, history, setIsLoading, setError);
        return Promise.resolve();
      }
    } else {
      console.log('auto sign-in not performed');

      // Resolve if credential object is not available
      return Promise.resolve();
    }
  } else {
    // Resolve if Credential Management API is not available
    return Promise.resolve();
  }
}

function storeOnLoginSuccess(username, password) {

  // Sign-In with our own server
  if (window.PasswordCredential) {
    // Construct `FormData` object from actual `form`
    const cred = new window.PasswordCredential({id: username, password: password});

    // Store credential information before posting
    navigator.credentials.store(cred);
  }
}


function loginUser(dispatch, login, password, history, setIsLoading, setError) {

  setError(false);
  setIsLoading(true);
  delete axios.defaults.headers.common['Authorization'];

  axios.post(authUrl + "login", {
    appVersion: appVersion,
    username: login,
    password
  })
  .then(function (response) {
    storeOnLoginSuccess(login, password)

    let accessToken = response.data.access_token;
    localStorage.setItem('access_token', accessToken);
    axios.defaults.headers.common['Authorization'] = 'Token ' + accessToken;

    dispatch({ type: "LOGIN_SUCCESS" });
    setError(false);
    setIsLoading(false);
    history.push(startPages[localStorage.getItem("cirrusApp") || IOT]);
  })
  .catch(function () {
    setError(true);
    setIsLoading(false);
    localStorage.removeItem("access_token");
    delete axios.defaults.headers.common['Authorization'];
    if (navigator.credentials && navigator.credentials.preventSilentAccess) {
      navigator.credentials.preventSilentAccess(); // stops auto-login in Chrome >60
    }
  });
}

function signOut(dispatch, history, callback) {
  axios.get(authUrl + "logout")
  .then( () => {
    if (navigator.credentials && navigator.credentials.preventSilentAccess) {
      navigator.credentials.preventSilentAccess(); // stops auto-login in Chrome >60
    }
    localStorage.removeItem("access_token");
    delete axios.defaults.headers.common['Authorization'];
    if (callback) {
      callback();
    }
    dispatch({ type: "SIGN_OUT_SUCCESS" });
    history.push(routes.login);
  });
}

// ###########################################################
function setCirrusApp(dispatch, app) {
  localStorage.setItem("cirrusApp", app);

  dispatch({
    type: "SET_APP",
    app
  });
}

function updateRotationFlag(dispatch, rotationFlag) {
  localStorage.setItem("rotationFlag", rotationFlag ? "1" : "0");

  dispatch({
    type: "ROTATION_FLAG_SETTING",
    rotationFlag
  });
}

function updateRotationTimer(dispatch, rotationTimer) {
  localStorage.setItem("rotationTimer", rotationTimer);

  dispatch({
    type: "ROTATION_TIMER_SETTING",
    rotationTimer
  });
}

function updateRotationEnabledUrls(dispatch, rotationEnabledUrls) {
  localStorage.setItem("rotationEnabledUrls", JSON.stringify(rotationEnabledUrls));

  dispatch({
    type: "ROTATION_ENABLED_SETTING",
    rotationEnabledUrls
  });
}


function updateServiceAccount(dispatch, serviceAccount) {

  if (serviceAccount) {
    localStorage.setItem("serviceAccount", JSON.stringify(serviceAccount));
  } else {
    localStorage.removeItem("serviceAccount");
  }

  dispatch({
    type: "SERVICE_ACCOUNT_SETTING",
    serviceAccount
  });
}
