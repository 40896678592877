import React, { useState } from "react";
import MUIDataTable from "mui-datatables";

import useStyles from "./styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";

import {convertHexToRgba} from "../../globalFunctions";


export function intervalFilterDict(measureName) {

  function intervalFilter(value, filters) {
    if (filters[0] && filters[1]) {
      return value < filters[0] || value > filters[1];
    } else if (filters[0]) {
      return value < filters[0];
    } else if (filters[1]) {
      return value > filters[1];
    }
    return false;
  }

  function intervalFilterTag(measureName) {
    return v => {
      if (v[0] && v[1]) {
        return `${measureName}: ${v[0]}~${v[1]}`;
      } else if (v[0]) {
        return `${measureName}: ${v[0]}~`;
      } else if (v[1]) {
        return `${measureName}: ~${v[1]}`;
      }
      return false;
    }
  }

  function intervalFilterForm(measure) {
    return (filterList, onChange, index, column) => (
      <div>
        <FormLabel>{measure}</FormLabel>
        <FormGroup row>
          <Input
            label='min'
            placeholder={'Min'}
            value={filterList[index][0] || ''}
            onChange={event => {
              filterList[index][0] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%', marginRight: '5%' }}
          />
          <Input
            label='max'
            placeholder={'Max'}
            value={filterList[index][1] || ''}
            onChange={event => {
              filterList[index][1] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%' }}
          />
        </FormGroup>
      </div>
    )
  }

  function intervalFilterListUpate(filterList, filterPos, index) {
    if (filterPos === 0) {
      filterList[index].splice(filterPos, 1, '');
    } else if (filterPos === 1) {
      filterList[index].splice(filterPos, 1);
    } else if (filterPos === -1) {
      filterList[index] = [];
    }
    return filterList;
  }

  return {
    filterType: 'custom',
    customFilterListOptions: {
      render: intervalFilterTag(measureName),
      update: intervalFilterListUpate,
    },
    filterOptions: {
      names: [],
      logic: intervalFilter,
      display: intervalFilterForm(measureName),
    },
  }
}


export default function TableComponent({ title, data, keys, handler, rowTooltip,
                                         selectableRows, rowsSelected, onRowsSelect,
                                         disableToolbarSelect, compact=false, options }) {
  const classes = useStyles();

  const [columnsState, setColumnsState] = useState({
    searchText: '',
    filterList: [],
    columns: []
  });

  const [hoveredRow, setHoveredRow] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [shown, setShown] = useState(false);

  const handleChange = (action, tableState) => {
    //console.log("CURRENT STATE: " + JSON.stringify(tableState));

    if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
      setColumnsState({
        searchText: tableState.searchText,
        filterList: tableState.filterList, //An array of filters for all columns
        columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
      });
    }
  };

  const getColumns = () => {
    //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
    let columns = [
      ...keys
    ];
    //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
    for(let i = 0; i < columns.length; i++) {
      if ( columns[i].options === undefined ) {
        columns[i].options = {}
      }
      //Assign the filter list to persist
      columns[i].options.filterList = columnsState.filterList[i];
      if(columnsState.columns[i] !== undefined) {
        //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
        if(columnsState.columns[i].hasOwnProperty('display'))
          columns[i].options.display = columnsState.columns[i].display;
        //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
        if(columnsState.columns[i].hasOwnProperty('sortDirection')) {
          //The sortDirection prop only permits sortDirection for one column at a time
          if(columnsState.columns[i].sortDirection !== 'none')
            columns[i].options.sortDirection = columnsState.columns[i].sortDirection;
        }
      }
    }

    return columns;
  };

  const getSearchText = () => {
    return columnsState.searchText;
  };

  const combinedOptions = {
    pagination: false,
    responsive: "scrollMaxHeight",
    selectableRows: selectableRows ? selectableRows : "none",
    print: false,
    disableToolbarSelect: disableToolbarSelect,
    ...(options || {}),
  };

  return (
    <>
      <Tooltip
        classes={{tooltip: classes.tableTooltip}}
        placement="right"
        title={
          rowTooltip && rowTooltip(hoveredRow) ?
            rowTooltip(hoveredRow)
            :
            ''
        }
        PopperProps={{anchorEl: anchorEl}}
        open={shown}
      >
        <div>
        <MUIDataTable className={compact ? {} : classes.tableScrollable }
          title={title}
          data={data}
          columns={getColumns()}
          options={{
            ...{
              searchText: getSearchText(),
              onTableChange: (action, tableState) => handleChange(action, tableState),
              onRowClick: handler ? (rowData, indexInfo) => handler(data[indexInfo.dataIndex]) : undefined,
              onRowsSelect: function (rowSelected, allRowsSelected) {
                let selectedIndices = allRowsSelected.map(x => x.dataIndex);
                onRowsSelect(rowSelected.dataIndex, selectedIndices)
              },
              rowsSelected: rowsSelected,
              setRowProps: function (row, dataIndex) {
                return {
                  style: {backgroundColor: convertHexToRgba(data[dataIndex].color, 0.5)},
                  onMouseEnter: function (event) {
                    if (rowTooltip && rowTooltip(data[dataIndex])) {
                      setHoveredRow(data[dataIndex]);
                      setShown(true);
                      setAnchorEl(event.target.parentNode);
                    }
                  },
                  onMouseLeave: () => setShown(false)
                };
              }
            },
            ...combinedOptions}
          }
        />
        </div>
      </Tooltip>
    </>
  );
}
