import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  muiPaper: {
    width: '500px',
  },
  graphHeading: {
    fontWeight: 700,
    textAlign: "right",
    marginRight: "25px",
    marginBottom: "10px",
    fontSize: "16px"
  },
  // TabPanel class
  tabPanel: {
    fontSize: "0.8rem"
  },
  // TableTooltip
  tracerouteTooltip: {
    textAlign: 'left',
    color: theme.palette.text.black + " !important",
  },

  customToolTip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.background.extraLight,
    whiteSpace: "nowrap",
    "& > *": {
      margin: "5px 0px",
    }
  },
  // Makes slider where the value selected shows up on bottom of slider
  invertedSliderLabel: {
    '& span': {
      transform: "translate(0px, 200%) rotate(135deg)",
    },
    '& span span': {
      transform: "translate(0px, 0) rotate(-135deg)",
    }
  },
  minWidthLabel: {
    minWidth: "50px",
    marginLeft: "9px",
  },
  modal: {
    backgroundColor: theme.palette.background.default,
    top: "10%",
    left: "15%",
  },
  modalXl: {
    marginTop: "5%",
    marginLeft: "17%",
    height: "90%",
    width: "75%",
    maxWidth: "1600px"
  },
  modalXlDialog: {
    height: "90%",
    width: "100%",
    maxWidth: "100%" // Overwrite the bootstrap default max size
  },
  modalHeader: {
    backgroundColor: theme.palette.background.default,
  },
  modalBody: {
    backgroundColor: theme.palette.background.default,
  },
  modalButton: {
    marginLeft: 10,
  },
  modalButtonSelected: {
    marginLeft: 10,
    border: '2px solid #30b58f',
  },
}));
