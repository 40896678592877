import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  Grid,
} from "@material-ui/core";

import DragAndDrop from "../../../components/DragAndDrop";

import {
  fetchSiteLocationsMapInfo,
} from "../../../components/DataStateRefresh";
import {Button} from "../../../components/Wrappers";

import {useDataDispatch, useDataState} from "../../../context/DataContext";

import {filesUrl} from "../../../meta";
import strings from "../../../strings";


const MAX_UPLOAD_FILESIZE = 10*1000*1000;


export default function MapRegistration({siteLocationId}) {

  const dataState = useDataState();
  const dataDispatch = useDataDispatch();

  const [file, setFile] = useState({});
  const [newFile, setNewFile] = useState(undefined);

  useEffect(() => {
    if (!dataState.siteLocationMaps[siteLocationId]) return;
    setFile(dataState.siteLocationMaps[siteLocationId][0]);
  }, [dataState.siteLocationMaps, siteLocationId]);

  useEffect(() => {
    console.log(file)
  }, [file]);

  const fileCheck = function (file) {
    if (file.size > MAX_UPLOAD_FILESIZE) {
      alert(strings.fileUploadFilesizeError)
      return false;
    }
    let fileExtension = file.name.split('.').pop().toLowerCase();
    if (['png', 'jpg', 'jpeg'].indexOf(fileExtension) === -1) {
      alert(strings.fileUploadExtensionError);
      return false;
    }
    return true;
  };

  const postNewFile = async () => {
    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("file_type", "map");
    formData.append("location_id", siteLocationId);
    try {
      await axios.post(filesUrl + "map", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setNewFile(undefined);
      fetchSiteLocationsMapInfo(dataDispatch).then(undefined);
    } catch (err) {
      alert(strings.fileUploadError)
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={6}>
          <DragAndDrop handleDrop={setNewFile} fileCheck={fileCheck}>
            <div style={{height: 50}}>
              {newFile ?
                <div >{newFile.name}</div> :
                <div>{strings.fileUploadDragDropMessage}</div>
              }
            </div>
          </DragAndDrop>
        </Grid>

        <Grid item md={3} xs={3}>
          <Button
            color="success"
            size="small"
            className="mt-3 px-2"
            variant="contained"
            onClick={postNewFile}
          >
            {strings.register}
          </Button>
        </Grid>
        {
          file.map_path && (
            <Grid item md={12} xs={12}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                viewBox="0 0 600 320"
                id="mapSvg"
              >
                <image
                  x="0"
                  y="0"
                  width="600"
                  height="320"
                  key={file.map_path}
                  xlinkHref={file.map_path}>
                </image>
              </svg>
            </Grid>
          )
        }
      </Grid>
    </>
  );
}
