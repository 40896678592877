import React, { useEffect, useState } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

// styles
import useStyles from "../styles";

import {useUserDispatch, setCirrusApp, useUserState} from "../../../context/UserContext";

import SelectDropdown from "../../../components/SelectDropdown";

// context
import strings from "../../../strings";
import {IOT, AIR} from "../../../routes";


function AppSelect() {
  let classes = useStyles();

  // global
  const userDispatch = useUserDispatch();
  const userState = useUserState();

  // local
  const [selectVisible, setSelectVisible] = useState(false);

  const [loginTypeDropdownOpen, setLoginTypeDropdownOpen] = React.useState(false);

  useEffect(() => {
    let cirrusApp = userState.cirrusApp;
    if (window.location.hostname === "medical.cirrusconsulting.jp") {
      if (cirrusApp !== IOT) {
        setCirrusApp(userDispatch, IOT);
      }
      setSelectVisible(false);
    } else if (window.location.hostname === "air.cirrusconsulting.jp") {
      if (cirrusApp !== AIR) {
        setCirrusApp(userDispatch, AIR);
      }
      setSelectVisible(false);
    } else {
      setSelectVisible(true);
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return selectVisible ?
    (
      <FormControl className={classes.formControl}>
        <InputLabel id="login-type-select-label">
          {strings.loginTarget}
        </InputLabel>
        <SelectDropdown
          labelId="login-type-select-label"
          id="login-type-select"
          open={loginTypeDropdownOpen}
          onClose={() => setLoginTypeDropdownOpen(false)}
          onOpen={() => setLoginTypeDropdownOpen(true)}
          value={userState.cirrusApp}
          onChange={(e) => {
            setCirrusApp(userDispatch, e.target.value);
            setLoginTypeDropdownOpen(false);
          }}
        >
          <MenuItem
            key="air"
            value="air"
          >
            Cirrus Air
          </MenuItem>
          <MenuItem

            key="iot"
            value="iot"
          >
            Cirrus Medical IoT
          </MenuItem>
        </SelectDropdown>
      </FormControl>
    )
    :
    null;
}

export default AppSelect;
