import React  from "react";
import {
  FormControlLabel,
  Switch,
  Slider, Grid,
} from "@material-ui/core";
import clsx from "clsx";

// styles
import useStyles from "../styles";

// components
import {screenRotationUrls, getRouteName} from "../../../routes";

// customized hook
import {
  useUserState,
  useUserDispatch,
  updateRotationFlag,
  updateRotationTimer,
  updateRotationEnabledUrls
} from "../../../context/UserContext";

import strings from "../../../strings";

export default function RotationSettings() {
  // global
  let classes = useStyles();

  // static values
  const userState = useUserState();
  const userDispatch = useUserDispatch();

  function onSiteCheck(checked, screenRoute) {
    let rotationEnabledUrls = {...userState.rotationEnabledUrls};
    let currentList = rotationEnabledUrls[userState.cirrusApp];
    currentList = checked ?
      [screenRoute, ...currentList] :
      currentList.filter(item => item !== screenRoute);
    rotationEnabledUrls[userState.cirrusApp] = currentList
    updateRotationEnabledUrls(userDispatch, rotationEnabledUrls);
  }


  return (
    <>
      <Grid container spacing={2}>
        {/* Room Sensor Widget */}
        <Grid item md={4} xs={4}>
          <FormControlLabel
            className={clsx(classes.margin, classes.textField)}
            control={
              <Switch
                classes={{
                  track: classes.toggleSwitchTrack
                }}
                checked={userState.rotationFlag}
                onChange={(e, value) => updateRotationFlag(userDispatch, value)}
                value="themeSetting"
                color="primary"
              />
            }
            label={strings.screenRotation}
          />
        </Grid>
        <Grid item md={8} xs={8}>
          <FormControlLabel
            className={clsx(classes.margin, classes.textField)}
            control={
              <Slider
                id="refresh-interval-open-select"
                disabled={!userState.rotationFlag}
                defaultValue={10}
                getAriaValueText={(val) => `${val}s`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={60}
                value={parseInt(userState.rotationTimer)}
                onChange={(e, value) => updateRotationTimer(userDispatch, value)}
              />
            }
            label={strings.interval}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {
          screenRotationUrls[userState.cirrusApp].map((route) => (
            <>
              <Grid item md={2} xs={2}>
                <FormControlLabel
                  className={clsx(classes.margin, classes.textField)}
                  control={
                    <Switch
                      classes={{
                        track: classes.toggleSwitchTrack
                      }}
                      disabled={!userState.rotationFlag}
                      checked={userState.rotationEnabledUrls[userState.cirrusApp].indexOf(route) > -1}
                      onChange={(e, value) => onSiteCheck(value, route)}
                      value="themeSetting"
                      color="primary"
                    />
                  }
                  label="On"
                />
              </Grid>
              <Grid item md={10} xs={10}>
                {getRouteName(route)}
              </Grid>
            </>
          ))
        }
      </Grid>
    </>
  );
}
