import React, {useState} from "react";
import {
  MenuItem,
  FormGroup,
  InputLabel,
} from "@material-ui/core";

// styles

// components
import SelectDropdown from "../../../components/SelectDropdown";

import strings from "../../../strings";


export default function SecurityLevelMenu({ adminSecurityLevel,
                                            modSecurityLevel, setModSecurityLevel,
                                            disabled }) {

  const [selectOpen, setSelectOpen] = useState(false);

  const permissionValues = [
    [50, strings.regularUser],
    [100, strings.adminUser],
    [200, strings.superadminUser],
  ];

  // global
  return (
    <FormGroup>
      <InputLabel id="new-user-permissions-open-select-label">
        {strings.permissions}
      </InputLabel>
      <SelectDropdown
        disabled={disabled || adminSecurityLevel < modSecurityLevel}
        style={{display: 'inline-block', width: '33%'}}
        labelId="mod-user-permissions-open-select-label"
        id="mod-user-permissions-site-open-select"
        open={selectOpen}
        onClose={() => {
          setSelectOpen(false);
        }}
        onOpen={() => {
          setSelectOpen(true);
        }}
        value={modSecurityLevel}
        onChange={(e) => {
          const permissionValue = parseInt(e.target.value);
          setModSecurityLevel(permissionValue);
          setSelectOpen(false);
        }}
      >
        {
          permissionValues.map((d) => (
            (adminSecurityLevel >= d[0] || modSecurityLevel === d[0])?
              <MenuItem value={d[0]}>{ d[1] }</MenuItem>
              :
              undefined
          ))
        }
      </SelectDropdown>
    </FormGroup>
  );
}
