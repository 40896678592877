import React, { useState } from "react";
import { Box, FormControlLabel, Grid, Switch, Tab, Tabs, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import * as moment from "moment";
import {
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

import axios from "axios";
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";


// styles
import useStyles from "./styles";

import { maxSoftBoundAutofix } from "../../globalFunctions";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import Table, {intervalFilterDict} from "../../components/Table";
import { airUrl } from "../../meta";
import strings from "../../strings";

// customized hook
import useInterval from "./useInterval";

import { useLocationState } from "../../context/LocationContext";
import { useLayoutState } from "../../context/LayoutContext";
import {
  addTimeInfo,
  forceCellDecimal
} from "../../globalFunctions";

// Graph Windows tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Dashboard() {
  // global
  let classes = useStyles();
  let theme = useTheme();
  let locationState = useLocationState();
  let layoutState = useLayoutState();

  // static values
  const analyzersDataKeys = [
    {name: 'name', label: strings.hostname},
    {name: 'time', label: strings.time},
    {name: 'tcp_sent', label: strings.tcpSentUnit,
      options: {customBodyRender: forceCellDecimal}},
    {name: 'tcp_received', label: strings.tcpReceivedUnit,
      options: {customBodyRender: forceCellDecimal}},
    {name: 'udp_results', label: strings.udpResultsUnit,
      options: {customBodyRender: forceCellDecimal}},
    {name: 'udp_loss', label: strings.udpLossUnit,
      options: {customBodyRender: forceCellDecimal}}
  ];

  const headUnitsDataKeys = [
    {name: 'name', label: strings.hostname},
    {name: 'time', label: strings.time},
    {name: 'upload', label: strings.uploadUnit,
      options: {customBodyRender: forceCellDecimal}},
    {name: 'download', label: strings.downloadUnit,
      options: {customBodyRender: forceCellDecimal}},
    {name: 'ping', label: strings.pingUnit}
  ];

  const troublesDataKeys = [
    {name: 'device', label: strings.device},
    {name: 'time', label: strings.time},
    {name: 'site', label: strings.site},
    {name: 'description', label: strings.alertDescription}
  ];

  const channelAnalysisKeys = [
    {name: 'channel', label: strings.channel},
    {name: 'access_point', label: strings.accessPoint},
    {name: 'mac', label: strings.mac},
    {name: 'rssi', label: strings.rssiUnit,
      options: intervalFilterDict(strings.rssiUnit)}
  ];

  const apKeys = [
    {name: 'access_point', label: strings.accessPoint},
    {name: 'mac', label: strings.mac},
    {name: 'tot_users', label: strings.totUsers},
    {name: 'retry', label: strings.retryUnit,
      options: {customBodyRender: forceCellDecimal}}
  ];

  const apVendorKeys = [
    {name: 'access_point', label: strings.accessPoint},
    {name: 'vendor', label: strings.vendor},
    {name: 'tot_users', label: strings.totUsers},
    {name: 'retry', label: strings.retryUnit,
      options: {customBodyRender: forceCellDecimal}}
  ];

  const focusApKeys = [
    {name: 'access_point', label: strings.accessPoint},
    {name: 'mac', label: strings.mac},
    {name: 'time', label: strings.time},
    {name: 'tot_users', label: strings.totUsers},
    {name: 'retry', label: strings.retryUnit,
      options: {customBodyRender: forceCellDecimal}}
  ];

  const focusApVendorKeys = [
    {name: 'access_point', label: strings.accessPoint},
    {name: 'vendor', label: strings.vendor},
    {name: 'time', label: strings.time},
    {name: 'tot_users', label: strings.totUsers},
    {name: 'retry', label: strings.retryUnit,
    }
  ];

  const focusBssiKeys = [
    {name: 'time', label: strings.time},
    {name: 'essid', label: strings.ssid},
    {name: 'mac', label: strings.mac},
    {name: 'channel', label: strings.channel},
    {name: 'rssi', label: strings.rssiUnit,
      options: intervalFilterDict(strings.rssiUnit)}
  ];

  const focusBssiVendorKeys = [
    {name: 'time', label: strings.time},
    {name: 'essid', label: strings.ssid},
    {name: 'vendor', label: strings.vendor},
    {name: 'channel', label: strings.channel},
    {name: 'rssi', label: strings.rssiUnit,
      options: intervalFilterDict(strings.rssiUnit)}
  ];

  const focusPingKeys = [
    {name: 'time', label: strings.time},
    {name: 'app_name', label: strings.appName},
    {name: 'app_type_str', label: strings.testType},
    {name: 'url', label: strings.url},
    {name: 'result', label: strings.result},
  ];

  const tracerouteKeys = [
    {name: 'idx', label: strings.hop},
    {name: 'ip', label: 'IP'},
    {name: 'asn', label: 'ASN'},
    {name: 'rtt', label: 'RTT(ms)'}
  ];


  // graph threshold line colors
  const thresholdColors = theme.graphs;

  // state hooks
  const [analyzersData, setAnalyzersData] = useState([]);
  const [graphData, setGraphData] = useState({ data: [], thresholds: {} });
  const [accessPointsData, setAccessPointsData] = useState([]);
  const [headUnitsData, setHeadUnitsData] = useState([]);
  const [troublesData, setTroublesData] = useState([]);
  const [channelAnalysisData, setChannelAnalysisData] = useState([]);
  const [focusBssiData, setFocusBssiData] = useState([]);
  const [focusApData, setFocusApData] = useState([]);
  const [focusPingData, setFocusPingData] = useState([]);
  const [machineKey, setMachineKey] = useState("wifi-analyzer-0001");

  // Active tab order
  const [analyzerTabValue, setAnalyzerTabValue] = React.useState(0);
  const [headUnitTabValue, setHeadUnitTabValue] = React.useState(0);

  // Some switch settings
  const [autoGraphUpdated, setAutoGraphUpdated] = React.useState(true);

  const hanldeAutoGraphUpdatedChange = (event) => {
    setAutoGraphUpdated(event.target.checked);
  };

  // Tab selection handler
  const handleAnalyzerTabChange = (event, newValue) => {
    setAnalyzerTabValue(newValue);
  };

  const handleHeadUnitTabChange = (event, newValue) => {
    setHeadUnitTabValue(newValue);
  };

  const fetchGraphData = async (name) => {

    const { data } = await axios(airUrl + `dashboard_graph_new?machine=${name}`);
    addTimeInfo(data.data, locationState.timezone);
    const internalData = data.data.map(item => {
      item.time = moment(item.time).format('HH:mm:ss');
      return item;
    });

    internalData.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));

    setGraphData({
      data: internalData,
      thresholds: data.thresholds
    });
  };

  const fetchFocusPingData = async (name) => {
    const result = await axios(airUrl + `dashboard_focus_ping?machine=${name}`);
    setFocusPingData(result.data);
  };

  const fetchFocusBssiData = async (name) => {
    if(isShowingHeadUnits())
      return;
    const result = await axios(airUrl + `dashboard_focus_bssi?machine=${name}`);
    setFocusBssiData(result.data);
  };

  const fetchFocusApData = async (name) => {
    if(isShowingHeadUnits())
      return;
    const result = await axios(airUrl + `dashboard_focus_ap?machine=${name}`);
    setFocusApData(result.data);
  };

  // handler for clicking event on row of analyzer table
  const onAnalyzerClickHandler = async (rowData) => {
    // FIRST, let's switch graph data!
    setMachineKey(rowData.name);
    fetchGraphData(rowData.name).then(undefined);
    fetchFocusPingData(rowData.name).then(undefined);
    fetchFocusApData(rowData.name).then(undefined);
    fetchFocusBssiData(rowData.name).then(undefined);
  };

  const PingRowTracerouteTooltip = (row) => {
    if (row === undefined || row.details === null) {
      return undefined;
    }
    if (row.details.error !== undefined) {
      // Error information
      return (<Typography>{row.details.error}</Typography>)
    }

    // Traceroute detailed data
    if (row.details.hops === undefined) {
      return undefined
    }

    row.details.hops.forEach((hop, i) => {
      let p = hop.probes[0];
      if (p !== undefined) {
        p.idx = i;
        p.ip = p.ip || '***';
        p.asn = p.asn || '***';
        p.rtt = p.rtt || '***';
      }
    });
    return (
      <MUIDataTable
        className={classes.tableScrollable}
        data={row.details.hops.map(hop => hop.probes[0] || {})}
        columns={tracerouteKeys}
        options={{
          pagination: false,
          selectableRows: "none",
          print: false,
          filter: false,
          search: false,
          download: false,
          viewColumns: false,
        }}
      />
    )
  };

  // Custom toolip on Graph
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0]) {
      return (
        <div className={classes.customToolTip}>
          <p className="label">{`${label}`}</p>
          {
            payload.map(e => (<p key={e.name} className="label" style={{ color: e.stroke}}>
                  {`${e.name} : ${Math.round( e.value * 10 ) / 10}`}</p>
            ))
          }
          <hr/>
          {
            Object.keys(payload[0].payload).filter(key => key.indexOf("ap_") === 0)
            .map(key => (<p key={key} className="label">{`${strings[key] || key} : ${payload[0].payload[key]}`}</p>))
          }
        </div>
      );
    }
  
    return null;
  };

  // Determine if showing `analyzer` or `head_unit` info currently
  const isShowingHeadUnits = () => {
    // return graphData.data.length > 0 && Object.keys(graphData.data[0]).filter(key => key === 'download').length > 0;
    return !machineKey.startsWith("wifi-a");
  };

  // TabPanel option
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  // Interval hook
  useInterval(() => {
    const fetchAnalyzersData = async () => {
      const result = await axios(airUrl + "dashboard_analyzers" + (locationState.currentLocation.id ? `?location=${locationState.currentLocation.id}` : ""));

      setAnalyzersData(result.data.map(elm => ({ ...elm, udp_results: Math.round(elm.udp_results * 10) / 10})));
    };

    const fetchAccessPointsData = async () => {
      // eslint-disable-next-line
      const result = await axios(airUrl + "dashboard_access_points" + `?machine=${machineKey}` + (locationState.currentLocation.id ? `&location=${locationState.currentLocation.id}` : ""));
      setAccessPointsData(result.data);
    };
  
    const fetchHeadUnitsData = async () => {
      const result = await axios(airUrl + "dashboard_head_units" + (locationState.currentLocation.id ? `?location=${locationState.currentLocation.id}` : ""));

      setHeadUnitsData(result.data.map(elm => ({ ...elm, upload: Math.round(elm.upload * 10) / 10})));
    };
  
    const fetchTroublesData = async () => {
      const result = await axios(airUrl + "dashboard_troubles" + (locationState.currentLocation.id ? `?location=${locationState.currentLocation.id}` : ""));
      setTroublesData(result.data);
    };
  
    const fetchChannelAnalysisData = async () => {
      const result = await axios(airUrl + `dashboard_channel_analysis?machine=${machineKey}` );
      setChannelAnalysisData(result.data);
    };

    const fetchDashboardData = () => {
      fetchAnalyzersData().then(undefined);
      autoGraphUpdated && fetchGraphData(machineKey);
      fetchAccessPointsData().then(undefined);
      fetchHeadUnitsData().then(undefined);
      fetchTroublesData().then(undefined);
      fetchChannelAnalysisData().then(undefined);

      // analyzerTabValue === 1
      fetchFocusBssiData(machineKey).then(undefined);
      // analyzerTabValue === 2
      fetchFocusApData(machineKey).then(undefined);
      // headUnitTabValue === 1
      fetchFocusPingData(machineKey).then(undefined);
    };

    fetchDashboardData();
  }, locationState.refresher * 1000);

  // Individual Widgets
  const renderAnalyzerWidget = () => {
    return (
      <Widget
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
        header={
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {strings.finders}
            </Typography>
          </React.Fragment>
        }
      >
        <Table data={analyzersData} handler={onAnalyzerClickHandler} keys={analyzersDataKeys} key="analyzersData" />
      </Widget>
    );
  };

  const renderGraphWidget = () => {
    return (
      <Widget
        noBodyPadding
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
        header={
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {strings.focus}
            </Typography>
            <div className={classes.graphHeading}>
              {machineKey}
            </div>
          </React.Fragment>
        }
      >
      {
        isShowingHeadUnits() ?
        [
          <Tabs value={headUnitTabValue} onChange={handleHeadUnitTabChange} aria-label="simple tabs example">
            <Tab label={strings.networkPerformance} {...a11yProps(0)} />
            <Tab label={strings.applicationTests} {...a11yProps(1)} />
          </Tabs>,
          <TabPanel className={classes.tabPanel} value={headUnitTabValue} index={0}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoGraphUpdated}
                  onChange={hanldeAutoGraphUpdatedChange}
                  value="autoGraphUpdated"
                  color="secondary"
                />
              }
              label="Automatic Update"
            />
            <ResponsiveContainer width="100%" height={650}>
              <LineChart
                width={500}
                height={650}
                className={classes.lineGraph}
                data={graphData.data}
              >
                <CartesianGrid strokeDasharray="3 3 3 3" />
                <XAxis dataKey="time" />
                <YAxis
                  yAxisId="left"
                  unit=" Mbps"
                  domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 50, 20)]} />
                <YAxis
                  yAxisId="right"
                  orientation='right'
                  unit=" ms" domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]} />
                {
                  graphData.thresholds && (
                    ['download', 'upload', 'ping']
                      .map(key => graphData.thresholds[key] && (
                        <ReferenceLine
                          y={graphData.thresholds[key]}
                          yAxisId={key === 'ping' ? "right" : "left"}
                          stroke={thresholdColors[key]}
                          strokeDasharray="3 3"
                          strokeWidth={3}/>
                      ))
                  )
                }
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name={strings.download}
                  dataKey="download"
                  connectNulls={true}
                  stroke={thresholdColors.download}
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name={strings.upload}
                  dataKey="upload"
                  connectNulls={true}
                  stroke={thresholdColors.upload}
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  name={strings.ping}
                  dataKey="ping"
                  connectNulls={true}
                  stroke={thresholdColors.ping}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </TabPanel>,
          <TabPanel value={headUnitTabValue} index={1}>
            <Table
              data={focusPingData}
              keys={focusPingKeys}
              rowTooltip={PingRowTracerouteTooltip}
              key="focusPingData" />
          </TabPanel>
        ]
        :
        [
          <Tabs value={analyzerTabValue} onChange={handleAnalyzerTabChange} aria-label="simple tabs example">
            <Tab label={strings.graphData} {...a11yProps(0)} />
            <Tab label={strings.bssi} {...a11yProps(1)} />
            <Tab label={strings.accessPoints} {...a11yProps(2)} />
            <Tab label={strings.applicationTests} {...a11yProps(3)} />
          </Tabs>,
          <TabPanel className={classes.tabPanel} value={analyzerTabValue} index={0}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoGraphUpdated}
                  onChange={hanldeAutoGraphUpdatedChange}
                  value="autoGraphUpdated"
                  color="secondary"
                />
              }
              label={strings.automaticUpdate}
            />
            <ResponsiveContainer width="100%" height={650}>
              <LineChart
                width={500}
                height={650}
                className={classes.lineGraph}
                data={graphData.data}
              >
                <CartesianGrid strokeDasharray="3 3 3 3" />
                <XAxis dataKey="time" />
                <YAxis
                  yAxisId="left"
                  unit=" Mbps"
                  domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]} />
                <YAxis
                  yAxisId="right"
                  orientation='right'
                  unit={" %"}
                  domain={[0, maxVal => maxSoftBoundAutofix(maxVal, 100, 20)]} />
                {
                  graphData.thresholds && (
                    ['tcp_sent', 'tcp_received', 'udp_results', 'udp_loss']
                      .map(key => graphData.thresholds[key] && (
                        <ReferenceLine
                          y={graphData.thresholds[key]}
                          yAxisId={(key === 'loss' || key === 'udp_loss') ? 'right' : 'left'}
                          stroke={thresholdColors[key]}
                          strokeDasharray="3 3"
                          strokeWidth={3}/>
                      ))
                  )
                }
                <Tooltip content={<CustomTooltip />} key="analyzerGraphCustomTooltip"/> :
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name={strings.tcpSent}
                  dataKey="tcp_sent"
                  connectNulls={true}
                  stroke={thresholdColors.tcp_sent}
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name={strings.tcpReceived}
                  dataKey="tcp_received"
                  connectNulls={true}
                  stroke={thresholdColors.tcp_received}
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  name={strings.udpResults}
                  dataKey="udp_results"
                  connectNulls={true}
                  stroke={thresholdColors.udp_results}
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  name={strings.udpLoss}
                  dataKey="udp_loss"
                  connectNulls={true}
                  stroke={thresholdColors.udp_loss}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </TabPanel>,
          <TabPanel value={analyzerTabValue} index={1}>
            <Table
              data={focusBssiData}
              keys={locationState.isVendor ? focusBssiVendorKeys : focusBssiKeys}
              key="focusBssiData"
              sliderInfo={{key: 'rssi', minValue: -100, maxValue: -20, step: 1, name: 'RSSI'}}
            />
          </TabPanel>,
          <TabPanel value={analyzerTabValue} index={2}>
            <Table
              data={focusApData}
              keys={locationState.isVendor ? focusApVendorKeys : focusApKeys}
              key="focusApData" />
          </TabPanel>,
          <TabPanel value={analyzerTabValue} index={3}>
            <Table
              data={focusPingData}
              keys={focusPingKeys}
              rowTooltip={PingRowTracerouteTooltip}
              key="focusPingData" />
          </TabPanel>
        ]
      }
    </Widget>
    );
  };

  const renderAccessPointsWidget = () => {
    return (
      <Widget
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
        header={
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {strings.accessPoints}
            </Typography>
            <div className={classes.graphHeading}>{machineKey}</div>
          </React.Fragment>
        }
      >
        <Table
          data={accessPointsData}
          keys={locationState.isVendor ? apVendorKeys : apKeys}
          key="accessPointsData"
        />
      </Widget>
    );
  };

  const renderHeadUnitWidget = () => {
    return (
      <Widget
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
        header={
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {strings.flows}
            </Typography>
          </React.Fragment>
        }
      >
        <Table data={headUnitsData} keys={headUnitsDataKeys} handler={onAnalyzerClickHandler} key="headUnitsData" />
      </Widget>
    );
  };

  const renderTroublesWidget = () => {
    return (
      <Widget
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
        header={
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {strings.alerts}
            </Typography>
          </React.Fragment>
        }
      >
        <Table data={troublesData} keys={troublesDataKeys} key="troublesData" />
      </Widget>
    );
  };

  const renderChannelAnalysisWidget = () => {
    return (
      <Widget
        upperTitle
        bodyClass={classes.tableWidget}
        disableWidgetMenu
        header={
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {strings.channelAnalysis}
            </Typography>
            <div className={classes.graphHeading}>{machineKey}</div>
          </React.Fragment>
        }
      >
        <Table data={channelAnalysisData} keys={channelAnalysisKeys} key="channelAnalysisData" />
      </Widget>
    );
  };

  return (
    <>
      <PageTitle title={strings.dashboard} />
      <Grid container spacing={2}>
        {/* Analyzers Widget */}
        <Grid item md={6} xs={12}>
          {
            layoutState.firstBlock === "analyzers" && renderAnalyzerWidget()
          }
          {
            layoutState.firstBlock === "head_units" && renderHeadUnitWidget()
          }
          {
            layoutState.firstBlock === "access_points" && renderAccessPointsWidget()
          }
        </Grid>

        {/* Graph Widget */}
        <Grid item md={6} xs={12}>
          {
            renderGraphWidget()
          }
        </Grid>

        {/* Head Unit Widget */}
        <Grid item md={6} xs={12}>
          {
            layoutState.firstBlock === "analyzers" ?
             renderHeadUnitWidget() : renderAnalyzerWidget()
          }
        </Grid>

        {/* Access Points Widget */}
        <Grid item md={6} xs={12}>
          {
            layoutState.firstBlock === "access_points" ?
             renderHeadUnitWidget() : renderAccessPointsWidget()
          }
        </Grid>

        {/* Troubles Widget */}
        <Grid item md={6} xs={12}>
          {
            renderTroublesWidget()
          }
        </Grid>

        {/* Channel Analysis Widget */}
        <Grid item md={6} xs={12}>
          {
            renderChannelAnalysisWidget()
          }
        </Grid>
      </Grid>
    </>
  );
}
