import strings from "./strings";

export let AIR = 'air';
export let IOT = 'iot';

export let routes = {
  app: "/app",
  login: "/login",

  airDashboard: "/app/dashboard",
  map: "/app/map",
  report: "/app/report",

  iotMap: "/app/iot/map",
  iotDataLatest: "/app/iot/data/latest",
  co2DataHistory: "/app/iot/data/co2/history",
  pressureDataHistory: "/app/iot/data/pressure/history",
  o2DataHistory: "/app/iot/data/o2/history",

  dataAnalysis: "/app/iot/data/analysis",

  calibration: "/app/calibration",
  locationSettings: "/app/location/settings",

  users: "/app/users",
  profile: "/app/profile",

  fileUpload: "/app/file/upload",
  customFileRotate: "/app/file/rotation",

  settingsGeneral: "/app/settings/general",
  settingsRotation: "/app/settings/rotation",
  settingsThresholds: "/app/settings/thresholds",
  settingsDataRefresh: "/app/settings/data_refresh",
  settingsServiceAccount: "/app/settings/service_account",
};


export function getRouteName(route) {
  const routeNames = {};
  routeNames[routes.login] = strings.login;

  routeNames[routes.airDashboard] = strings.airDashboard;
  routeNames[routes.map] = strings.map;
  routeNames[routes.report] = strings.report;

  routeNames[routes.iotMap] = strings.iotMap;
  routeNames[routes.iotDataLatest] = strings.iotDataLatest;
  routeNames[routes.co2DataHistory] = strings.co2DataHistory;
  routeNames[routes.pressureDataHistory] = strings.pressureDataHistory;
  routeNames[routes.o2DataHistory] = strings.o2DataHistory;

  routeNames[routes.dataAnalysis] = strings.dataAnalysis;

  routeNames[routes.calibration] = strings.sensorSettings;
  routeNames[routes.locationSettings] = strings.locationSettings;

  routeNames[routes.users] = strings.users;
  routeNames[routes.profile] = strings.profile;

  routeNames[routes.customFileRotate] = strings.customFileRotate;

  routeNames[routes.settingsGeneral] = strings.settings;

  return routeNames[route] || "Unnamed route";
}




export let startPages = {}
startPages[AIR] = routes.airDashboard;
startPages[IOT] = routes.iotDataLatest;


export let screenRotationUrls = {};
screenRotationUrls[AIR] = [];
screenRotationUrls[IOT] = [
  routes.iotDataLatest,
  routes.co2DataHistory,
  routes.pressureDataHistory,
  routes.iotMap,
  routes.customFileRotate,
];

export let screenRotationDefaultUrls = {};
screenRotationDefaultUrls[AIR] = [];
screenRotationDefaultUrls[IOT] = [routes.iotDataLatest];

export let reversedRoutes = Object.assign({}, ...Object.entries(routes).map(([a,b]) => ({ [b]: a })));