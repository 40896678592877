import React from "react";

import strings from "../strings";

var LanguageStateContext = React.createContext();
var LanguageDispatchContext = React.createContext();

function languageReducer(state, action) {
  switch (action.type) {
    case "LANGUAGE_SETTING":
      return { ...state, language: action.language};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LanguageProvider({ children }) {
  const savedLanguage = localStorage.getItem("language");
  strings.setLanguage(savedLanguage || 'en');

  var [state, dispatch] = React.useReducer(languageReducer, {
    language: (savedLanguage ? savedLanguage : 'en')
  });
  return (
    <LanguageStateContext.Provider value={state}>
      <LanguageDispatchContext.Provider value={dispatch}>
        {children}
      </LanguageDispatchContext.Provider>
    </LanguageStateContext.Provider>
  );
}

function useLanguageState() {
  var context = React.useContext(LanguageStateContext);
  if (context === undefined) {
    throw new Error("useLanguageState must be used within a LanguageProvider");
  }
  return context;
}

function useLanguageDispatch() {
  var context = React.useContext(LanguageDispatchContext);
  if (context === undefined) {
    throw new Error("useLanguageDispatch must be used within a LanguageProvider");
  }
  return context;
}

export {
  LanguageProvider,
  useLanguageState,
  useLanguageDispatch,
  updateLanguage,
};

// ###########################################################
function updateLanguage(dispatch, language) {
  localStorage.setItem("language", language);
  strings.setLanguage(language);
  dispatch({
    type: "LANGUAGE_SETTING",
    language
  });
}
