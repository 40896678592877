import React, { Component } from 'react';

class DragAndDrop extends Component {
  state = {
    drag: false
  };
  dropRef = React.createRef();

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true});
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({drag: false});
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({drag: false});
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log(e.dataTransfer.files[0])
      if (this.props.fileCheck(e.dataTransfer.files[0])) {
        this.props.handleDrop(e.dataTransfer.files[0]);
        // .clearData() creates bug in Firefox
        //e.dataTransfer.clearData();
        this.dragCounter = 0;
      }
    }
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          display: 'inline-block',
          border: 'dashed grey 3px',
          position: 'relative'
        }}
        ref={this.dropRef}
      >
        {
          this.state.drag &&
          <div
            style={{
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999
            }}
          />
        }
        {this.props.children}
      </div>
    )
  }
}
export default DragAndDrop;